<template>
	<div class="main">
		<Header />
		<div id="viewInformationPage">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav />
				</div>
				<div class="viewInfo">
					<div>
						<div>
							<p class="examineTitle" style="margin-bottom: 7px;">{{data.financialForecast.year}}年预测</p>
							<p class="unit">单位：万元</p>
							<ul class="dataValuationInfo">
								<li>
									<span>项目</span>
									<span>{{data.financialForecast.year}}年预测</span>
								</li>
								<li>
									<span>公司营业收入</span>
									<span>
										{{ this.method.formateNum(data.financialForecast.income, 2) }}
									</span>
								</li>
								<li>
									<span>公司净资产</span>
									<span class="showInput">
										{{ this.method.formateNum(data.financialForecast.netAsset, 2) }}
									</span>
								</li>
								<li>
									<span>公司利润总额</span>
									<span class="showInput">
										{{ this.method.formateNum(data.financialForecast.totalProfit, 2) }}
									</span>
								</li>
								<li>
									<span>公司净利润</span>
									<span class="showInput">
										{{ this.method.formateNum(data.financialForecast.netProfit, 2) }}
									</span>
								</li>
								<li>
									<span>公司息税折旧摊销前利润</span>
									<span class="showInput">
										{{ this.method.formateNum(data.financialForecast.amortizeProfit, 2) }}
									</span>
								</li>
							</ul>
						</div>

						<div class="examineBookValue">
							<p class="examineTitle">账面净值</p>
							<ul>
							<!-- 	<li>
									<span>资产总计</span>
									<span>{{this.method.formateNum(data.financialBookValue.totalAssets, 2)}}万元</span>
								</li> -->
								<li>
									<span>无形资产净值</span>
									<span>{{this.method.formateNum(data.financialBookValue.netIntangibleAssets, 2)}}万元</span>
								</li>
							</ul>
							<ul v-for="(item, a) in JSON.parse(data.financialBookValue.portfolio)">
								<li>
									<span>数据资源名称</span>
									<span>{{item.name}}</span>
								</li>
								<li>
									<span>账面净值</span>
									<span>{{method.formateNum(item.bookValue, 2)}}万元</span>
								</li>
							</ul>
						</div>

						<div style="padding-bottom: 30px;">
							<p class="examineTitle" style="margin-bottom: 7px;">收入预测表</p>
							<p class="unit">单位：万元</p>
							<ul class="dataValuationInfo">
								<li>
									<span>项目/年份</span>
									<span>收入</span>
								</li>
								<li v-for="(item,index) in JSON.parse(data.financialIncome.income)">
									<span>{{Number(data.financialForecast.year)+index}}年</span>
									<span>{{method.formateNum(item.value, 2)}}</span>
								</li>
							</ul>

						</div>

					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '../../common/header.vue'
	import examineTitle from '../../common/fillin/examineTitle.vue'
	import examineNav from '../../common/fillin/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				data: {
					financialForecast: {
						year: ''
					},
					financialBookValue: {
						totalAssets: '',
						netIntangibleAssets: '',
						portfolio: '[]'
					},
					financialIncome: {
						income: '[]'
					}
				}
			}
		},
		mounted() {
			this.getFinancialDataValue()
		},
		methods: {
			getFinancialDataValue() {
				this.$post(this.domain.valuationProject + 'auditInfoShow/getFinancialDataValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {

						this.data = res.data.content
					})
			}

		}
	}
</script>

<style scoped lang="less">
	.dataValuationInfo,
	.unit,
	.examineBookValue ul {
		width: 80%;
	}

	.unit {
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: right;
		margin: 0 auto 16px;
	}

	.examineBookValue {
		padding: 30px 0;
		border-top: 1px dashed #fff;
		border-bottom: 1px dashed #fff;
		margin: 35px auto;
	}

	.examineBookValue ul {
		margin: 0 auto 15px;
		display: flex;
		justify-content: space-between;

		li {
			width: 45%;
			display: flex;
			justify-content: space-between;

			span {
				width: 45%;
				font-size: 14px;
				color: #FFFFFF;
			}
			
			>span:first-child{
				width: 49%;
			}
			
		}
	}

	.examineBookValue ul:last-child {
		margin-bottom: 0;
	}
	
	@media screen and (min-width:1400px){
		.dataValuationInfo,
		.unit,
		.examineBookValue ul {
			width: 70%;
		}
	}
</style>