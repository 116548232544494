<template>
	<div class="main">
		<Header />
		<div id="formWidth">
			<div class="fillinTitle">财务指标</div>
			<fillinNav />
			<financeNav />
			<div class="fillinSubtitle">账面净值</div>
			<div class="fillinMain">
				<div class="fillinContent">
					<ul class="bookValue">
						<!-- <li>
							<label for="totalAssets"><span class="reRed">*</span>资产总计</label>
							<div class="baseRight empty">
								<input type="number" id="totalAssets" autocomplete="off" v-model="data.totalAssets"
									@blur="displayThousandths($event,data.totalAssets,'totalAssets',1)" @focus="focus($event)"
									@change="change" placeholder="请输入">
								<div class="showInput" @click="inputFocus($event)">
									{{this.method.formateNum(data.totalAssets,2)}}
								</div>
								<span class="measure">万元</span>
							</div>
						</li> -->

						<li style="margin-bottom: 5px;">
							<label for="netIntangibleAssets"><span class="reRed">*</span>无形资产净值</label>
							<div class="baseRight empty">
								<input type="number" id="netIntangibleAssets" autocomplete="off" v-model="data.netIntangibleAssets"
									@blur="displayThousandths($event,data.netIntangibleAssets,'netIntangibleAssets',1)"
									@focus="focus($event)" @change="change" @wheel="method.disableWheel($event)" placeholder="请输入">
								<div class="showInput" @click="inputFocus($event)">
									{{this.method.formateNum(data.netIntangibleAssets,2)}}
								</div>
								<span class="measure">万元</span>
							</div>
						</li>
						<li>
							<p class="tips" style="color: red;margin-left: 211px;">注：不包含土地使用权等自然资源使用权</p>
						</li>
					</ul>
					<p class="tips">温馨提示：此处填写的数据资源可以为独立单项，可以为多个资产组，需注意与形成收入口径相一致，对收入无贡献的数据资源不在此范围内。</p>
					<ul class="bookValue" v-for="(item,index) in data.portfolio">
						<img v-if="index!=0" class="close" src="../../assets/img/finance/close.png" alt="关闭按钮"
							@click="del(index)" />
						<li>
							<label :for="'name'+index"><span class="reRed">*</span>数据资源名称</label>
							<div class="baseRight">
								<input type="text" :id="'name'+index" autocomplete="off" v-model="item.name" @blur="blur($event)"
									@focus="focus($event)" @change="change" placeholder="请输入">
							</div>
						</li>
						<li>
							<label :for="'bookValue'+index"><span class="reRed">*</span>账面净值</label>
							<div class="baseRight">
								<input type="number" :id="'bookValue'+index" autocomplete="off" v-model="item.bookValue"
									@blur="displayThousandths($event,item.bookValue,'bookValue',0,index)" @focus="focus($event)"
									@change="change" @wheel="method.disableWheel($event)" placeholder="请输入">
								<div class="showInput" @click="inputFocus($event)">
									{{method.formateNum(item.bookValue,2)}}
								</div>
								<span class="measure">万元</span>
							</div>
						</li>
					</ul>

					<div class="add" @click="addData">+ &nbsp;&nbsp;&nbsp; 添加一项数据资源</div>
					<div class="saveInfo" @click="save">下一步</div>
				</div>
			</div>

		</div>

		<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content=""
			@cacheTrueMask="clickCacheTrueMaskJump()" @cacheMask="clickCacheMaskJump()"></dialogBar>
	</div>
</template>

<script>
	import Header from '../../common/header.vue'
	import fillinNav from '../../common/fillin/fillinNav.vue'
	import financeNav from '../../common/fillin/financeNav.vue'
	import dialogBar from './popue.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		components: {
			Header,
			fillinNav,
			dialogBar,
			financeNav
		},
		data() {
			return {
				isSave: false,
				myAlert: false,
				popButonType: 'notEmpty',
				markedWords: '',
				data: {
					year: '',
					totalAssets: '', //资产总计
					netIntangibleAssets: '', //无形资产净值
					portfolio: [{
						name: '',
						bookValue: ''
					}]
				}

			}
		},
		mounted() {
			this.init()
		},
		methods: {
			...mapMutations(['pageModify']),
			change() {
				this.pageModify('true');
			},
			blur: function(e) {
				this.isSave = false
				e.currentTarget.style.borderColor = "#19ECFF";
				e.currentTarget.setAttribute("placeholder", "请输入")
				if (this.method.filterSensitiveWords(e.target, this.data)) {
					e.currentTarget.style.borderColor = "red";
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '您输入的内容存在敏感词';
				} else {
					e.currentTarget.style.borderColor = "#19ECFF";
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0')) {
					this.method.caching("financialBookValue", JSON.stringify(this.data), this.$route.query.type, '', this)
				}

			},
			focus(e) {
				e.currentTarget.style.borderColor = "#fff";
				e.currentTarget.setAttribute("placeholder", "")
			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.style.fontSize = '18px';
				e.currentTarget.previousSibling.focus();
			},
			displayThousandths: function(e, val, key, isDataResource, i) { //是否是数据资源列  1：不是   0:是
				this.isSave = false
				e.currentTarget.style.borderColor = "#19ECFF";
				e.currentTarget.setAttribute("placeholder", "请输入")
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (val <= 2147483647 && val >= -2147483648) {
					console.log('符合')
					if (isDataResource == 1 && this.data[key] == 0) {
						this.data[key] = ''
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '数值不为0';
						return false;
					} else if (isDataResource == 0 && this.data.portfolio[i][key] == 0) {
						this.data.portfolio[i][key] = ''
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '数值不为0';
						return false;
					}

				} else {
					console.log('走l')
					if (isDataResource == 1) {
						this.data[key] = ''
					} else {
						this.data.portfolio[i][key] = ''
					}
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请输入合理数值';
					return false;
				}
				e.currentTarget.nextSibling.style.display = 'block';
				e.currentTarget.style.fontSize = '0';

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0')) {
					this.method.caching("financialBookValue", JSON.stringify(this.data), this.$route.query.type, '', this)
				}
			},
			addData() {
				this.data.portfolio.push({
					name: '',
					bookValue: ''
				})
			},
			del(i) {
				this.data.portfolio.splice(i, 1)
			},
			init() {
				this.$post(this.domain.valuationProject + 'dataFinance/getFinancialBookValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						// //0：是缓存数据，1：不是缓存数据
						if (res.data.content.isCache == '0' && !sessionStorage.isHistoryCache) {
							this.myAlert = true;
							this.popButonType = 'cacheType';
							this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';
							this.data.year = res.data.content.year;
						} else {
							this.data.year = res.data.content.year;
							if (res.data.content.id) {
								this.data.totalAssets = res.data.content.totalAssets
								this.data.netIntangibleAssets = res.data.content.netIntangibleAssets
								this.data.portfolio = JSON.parse(res.data.content.portfolio)
								if (this.data.netIntangibleAssets) $('.showInput').css('display', 'block')
							}
						}
					})
			},
			save() {
				// 数据资源账面净值合计小于等于无形资产账面净值，无形资产账面净值小于资产总计


				// if (!this.data.totalAssets) {
				// 	this.myAlert = true;
				// 	this.popButonType = 'notEmpty-info';
				// 	this.markedWords = '请输入资产总计';
				// 	return false;
				// }

				if (!this.data.netIntangibleAssets) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入无形资产净值';
					return false;
				}

				var total = 0

				for (var i = 0; i < this.data.portfolio.length; i++) {
					total += Number(this.data.portfolio[i].bookValue)
					if (!this.data.portfolio[i].name && this.data.portfolio[i].bookValue) {
						this.myAlert = true;
						this.popButonType = 'notEmpty-info';
						this.markedWords = '请输入数据资源名称';
						return false
					} else if (this.data.portfolio[i].name && !this.data.portfolio[i].bookValue) {
						this.myAlert = true;
						this.popButonType = 'notEmpty-info';
						this.markedWords = '请输入账面净值';
						return false
					} else if (!this.data.portfolio[i].name && !this.data.portfolio[i].bookValue) {
						this.myAlert = true;
						this.popButonType = 'notEmpty-info';
						this.markedWords = '请输入数据资源名称';
						return false
					}
				}

				if (total < 0 && this.data.netIntangibleAssets < 0) {
					if (Math.abs(total) < Math.abs(this.data.netIntangibleAssets)) {
						this.myAlert = true;
						this.popButonType = 'notEmpty-info';
						this.markedWords = '账面净值合计小于等于无形资产净值';
						return false
					}
				} else if (total>this.data.netIntangibleAssets){
						this.myAlert = true;
						this.popButonType = 'notEmpty-info';
						this.markedWords = '账面净值合计小于等于无形资产净值';
						return false
				}



					// if (Number(this.data.netIntangibleAssets) > Number(this.data.totalAssets)) {
					// 	this.myAlert = true;
					// 	this.popButonType = 'notEmpty-info';
					// 	this.markedWords = '无形资产净值数据有误';
					// 	return false
					// }


					if (!this.isSave) {
						this.isSave = true
						this.$post(this.domain.valuationProject + 'dataFinance/saveFinancialBookValue', qs.stringify({
								reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
								financialBookValue: JSON.stringify(this.data)
							}))
							.then((res) => {
								this.$router.push({
									path: '/incomeForecast',
									query: {
										type: this.$route.query.type,
										reportId: res.data.content.valuationReportId
									}
								})
							})
					}


			},


			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.init();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.$route.query.type, '', this, 'financialBookValue')
			},
		}
	}
</script>

<style scoped src="../../assets/css/data.css"></style>
<style scoped lang="less">
	.reRed {
		margin-right: 4px;
	}

	.tips {
		font-size: 14px;
		line-height: 24px;
		font-weight: 400;
		color: #29FFFC;
	}

	.add {
		width: 100%;
		height: 46px;
		line-height: 46px;
		border: 1px solid #29FFFC;
		border-radius: 6px;
		font-size: 16px;
		color: #29FFFC;
		text-align: center;
		cursor: pointer;
	}

	.add:hover {
		color: #00b4ff;
		border-color: #00b4ff;
	}

	.bookValue:not(:first-child) {
		box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
		margin: 20px auto;

		label {
			text-align: right;
		}
	}

	.bookValue:nth-of-type(n+3)::before {
		content: '';
		display: block;
		width: 100%;
		border: 1px #fff dashed;
		position: absolute;
		top: -25px;
		left: 0;
	}

	.bookValue:nth-of-type(n+3) {
		margin-top: 50px !important;
	}

	.bookValue {
		// background: #142FC1;
		padding: 25px 28px 25px 10px;
		position: relative;

		>img {
			width: 26px;
			height: 26px;
			position: absolute;
			top: -10px;
			right: -10px;
		}

		>li {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 17px;

			.baseRight {
				width: 380px;
				height: 40px;
				position: relative;
				margin-left: 66px;
			}

			label {
				width: 145px;
				font-size: 18px;
				color: #fff;
			}

			input {
				width: 100%;
				height: 40px;
				border: 2px solid #19ECFF;
				font-size: 18px;
				color: #fff;
				background: transparent;
				padding: 0 50px 0 19px;
				box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
			}

			input:focus {
				border-color: #fff;
			}

			.showInput {
				width: 100%;
				height: 40px;
				line-height: 40px;
				background: transparent;
				// box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
				border: 2px solid #19ECFF;
				padding: 0 19px;
				color: #fff;
				font-size: 18px;
				position: absolute;
				top: 0;
				left: 0;
				display: none;
			}

			.measure {
				width: 60px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				position: absolute;
				top: 0;
				right: 0;
				color: #fff;
				font-size: 18px;
			}

		}

		>li:last-child {
			margin-bottom: 0;
		}
	}

	@media screen and (max-width:1500px) and (max-height:700px) {

		.bookValue>li label,
		.bookValue>li input,
		.bookValue>li .showInput,
		.bookValue>li .measure {
			font-size: 20px;
		}

		.add {
			font-size: 18px;
		}

		.tips {
			line-height: 26px;
			font-size: 16px;
		}
	}
</style>