<template>
	<div class="main">
		<Header />
		<div id="formWidth">
			<div class="fillinTitle">财务指标</div>
			<fillinNav />
			<financeNav />
			<div class="fillinSubtitle">{{data.year}}年预测</div>
			<div class="fillinMain">
				<div class="fillinContent">
					<p class="unit">单位：万元</p>
					<div class="">
						<ul>
							<li>
								<span>项目</span>
								<span>{{data.year}}年预测</span>
							</li>
							<li>
								<span>公司营业收入</span>
								<span>
									<input type="number" placeholder="请输入" v-model="data.income" @change="change"
										@blur="blur($event,'income')" @focus="focus($event)" @wheel="method.disableWheel($event)">
									<span class="showInput" @click="inputFocus($event)">
										{{ this.method.formateNum(data.income, 2) }}
									</span>
								</span>
							</li>
							<li>
								<span>公司净资产</span>
								<span>
									<input type="number" id="" placeholder="请输入" v-model="data.netAsset" @change="change"
										@blur="blur($event,'netAsset')" @focus="focus($event)" @wheel="method.disableWheel($event)">
									<span class="showInput" @click="inputFocus($event)">
										{{ this.method.formateNum(data.netAsset, 2) }}
									</span>
								</span>
							</li>
							<li>
								<span>公司利润总额</span>
								<span>
									<input type="number" id="" placeholder="请输入" v-model="data.totalProfit" @change="change"
										@blur="blur($event,'totalProfit')" @focus="focus($event)" @wheel="method.disableWheel($event)">
									<span class="showInput" @click="inputFocus($event)">
										{{ this.method.formateNum(data.totalProfit, 2) }}
									</span>
								</span>
							</li>
							<li>
								<span>公司净利润</span>
								<span>
									<input type="number" id="" placeholder="请输入" v-model="data.netProfit" @change="change"
										@blur="blur($event,'netProfit')" @focus="focus($event)" @wheel="method.disableWheel($event)">
									<span class="showInput" @click="inputFocus($event)">
										{{ this.method.formateNum(data.netProfit, 2) }}
									</span>
								</span>
							</li>
							<li>
								<span>
									<i style="font-weight: bold;">公司息税折旧摊销前利润</i>
									<div class="desc-container" style="display: inline-block;">
										<span style="width: 15px;height: 15px;cursor: pointer;"></span>
										<div class="desc-main" style="">
											<p>息税折旧摊销前利润=净利润+所得税+折旧及摊销+财务利息费用</p>
											<span style="position: absolute;top: 1px;left: 0;"></span>
										</div>
									</div>
								</span>
								<span><input type="number" id="" placeholder="请输入" v-model="data.amortizeProfit" @change="change"
										@blur="blur($event,'amortizeProfit')" @focus="focus($event)" @wheel="method.disableWheel($event)">
									<span class="showInput" @click="inputFocus($event)">
										{{ this.method.formateNum(data.amortizeProfit, 2) }}
									</span>
								</span>
							</li>
						</ul>
					</div>

					<div class="saveInfo" @click="save">下一步</div>
				</div>
			</div>

		</div>

		<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content=""
			@cacheTrueMask="clickCacheTrueMaskJump()" @cacheMask="clickCacheMaskJump()"></dialogBar>

	</div>
</template>

<script>
	import Header from '../../common/header.vue'
	import fillinNav from '../../common/fillin/fillinNav.vue'
	import financeNav from '../../common/fillin/financeNav.vue'
	import dialogBar from './popue.vue'
	import qs from 'qs'
	import axios from 'axios'

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		components: {
			Header,
			fillinNav,
			dialogBar,
			financeNav
		},
		data() {
			return {
				isSave: false,
				myAlert: false,
				popButonType: 'notEmpty',
				markedWords: '请输入合理数值',
				data: {
					year: '2024',
					income: '', // 营业收入预测
					netAsset: '', // 净资产预测
					totalProfit: '', // 利润总额预测
					netProfit: '', // 净利润预测
					amortizeProfit: '', // 折旧摊销预测
				}

			}
		},
		mounted() {
			this.init()

		},
		methods: {
			...mapMutations(['pageModify']),
			change() {
				this.pageModify('true');
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.data.isCache == '0')) {
					this.method.caching("financialForecast", JSON.stringify(this.data), this.$route.query.type, '', this)
				}
			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")

			},
			blur(e, key) {
				this.isSave = false
				e.currentTarget.setAttribute("placeholder", "请输入")

				if (this.data[key] <= 2147483647 && this.data[key] >= -2147483648) {
					// console.log('符合')
					if (key == 'income' && this.data[key] <= 0) {
						this.data[key] = "";
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '数值必须大于0';
						return false;
					} else if (this.data[key] == 0) {
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '数值不该为0';
						return false;
					}

				} else {
					this.data[key] = "";
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请输入合理数值';
					return false;
				}

				if (this.data.dataIncome != -1 && this.data.dataIncome > this.data.income) {
					this.data[key] = "";
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '公司营业收入要大于' + this.data.year + '年的收入';
					return false;
				}

				// if (this.data.totalAssets != -1 && this.data.netAsset > this.data.totalAssets) {
				// 	this.data[key] = "";
				// 	this.myAlert = true;
				// 	this.popButonType = 'notEmpty';
				// 	this.markedWords = '公司净资产要小于资产总计';
				// 	return false;
				// }

				e.currentTarget.nextSibling.style.display = 'block';

			},
			init() {
				this.$post(this.domain.valuationProject + 'dataFinance/getFinancialForecast', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						// //0：是缓存数据，1：不是缓存数据
						if (res.data.content.isCache == '0' && !sessionStorage.isHistoryCache) {
							this.myAlert = true;
							this.popButonType = 'cacheType';
							this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';
							this.data.year = res.data.content.year;
						} else {
							this.data = res.data.content
							if (this.data.income) $('.showInput').css('display', 'block')
						}
					})
			},
			save() {

				if (!this.data.income) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司营业收入预测';
					return false;
				}

				if (this.data.dataIncome != -1 && this.data.dataIncome > this.data.income) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '公司营业收入要大于' + this.data.year + '年的收入';
					return false;
				}

				if (this.data.netAsset === '' || this.data.netAsset == null) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司净资产';
					return false;
				} else if (!this.data.netAsset) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '公司净资产填写有误';
					return false;
				}

				if (this.data.totalProfit === '' || this.data.totalProfit == null) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司利润总额';
					return false;
				} else if (!this.data.totalProfit) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '公司利润总额填写有误';
					return false;
				}

				if (this.data.netProfit === '' || this.data.netProfit == null) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司净利润';
					return false;
				} else if (!this.data.netProfit) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '公司净利润填写有误';
					return false;
				}

				if (this.data.amortizeProfit === '' || this.data.amortizeProfit == null) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司息税折旧摊销前利润';
					return false;
				} else if (!this.data.amortizeProfit) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '公司息税折旧摊销前利润填写有误';
					return false;
				}

				// 公司利润总额小于公司营业收入，如果大于营业收入提示：扣除营业外收入、其他收益、投资收益对利润总额的影响后重新计算；净利润＜息税折旧摊销前利润；利润总额＜息税折旧摊销前利润。
				if (Number(this.data.totalProfit) > Number(this.data.income)) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '扣除营业外收入、其他收益、投资收益对利润总额的影响后重新计算；净利润＜息税折旧摊销前利润；利润总额＜息税折旧摊销前利润。';
					return false;
				}


				if (!this.isSave) {
					this.isSave = true
					this.$post(this.domain.valuationProject + 'dataFinance/saveFinancialForecast', qs.stringify({
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							financialForecast: JSON.stringify(this.data)
						}))
						.then((res) => {
							this.$router.push({
								path: '/carryingValue',
								query: {
									type: this.$route.query.type,
									reportId: res.data.content.valuationReportId
								}
							})

						})
				}

			},



			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.init();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.$route.query.type, '', this, 'financialForecast')
			},


		}
	}
</script>

<style scoped src="../../assets/css/data.css"></style>
<style scoped lang="less">
	.fillinMain {
		margin-top: 30px;
	}

	.unit {
		width: 612px;
		font-size: 18px;
		color: #FFFFFF;
		text-align: right;
		margin: 0 auto;
	}

	.unit+div {
		width: 635px;
		margin: 12px auto 0;

		ul::before {
			content: '';
			width: 100%;
			height: 10px;
			display: block;
			background: url(../../assets/img/finance/line.png) center center no-repeat;
			position: absolute;
			top: -3px;
			left: 0;
		}

		ul::after {
			content: '';
			width: 100%;
			height: 10px;
			display: block;
			background: url(../../assets/img/finance/line.png) center center no-repeat;
			position: absolute;
			bottom: -3px;
			left: 0;
		}

		ul {
			background: #142FC1;
			box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
			position: relative;


			li {
				width: 95%;
				margin: 0 auto;
				padding: 16px 0;
				text-align: center;
				position: relative;

				input {
					width: 100%;
					padding: 0 10px;
					font-size: 16px;
					background: #142FC1;
					color: #fff;
					text-align: center;
				}

				>span:first-child {
					width: 46%;
					font-size: 16px;
					font-weight: bold;
					color: #fff;
					border-right: 2px solid #29FFFC;
				}

				>span:last-child {
					width: 54%;
					color: #fff;
					position: relative;

					.showInput {
						width: 100%;
						height: 100%;
						font-size: 16px;
						padding: 0 10px;
						color: #fff;
						text-align: center;
						position: absolute;
						top: 0;
						left: 0;
						background: #142FC1;
						display: none;
					}
				}
			}

			li:nth-of-type(1) {
				padding: 20px 0;


				span {
					font-size: 18px;
					font-weight: bold;
				}

			}


			li:not(:first-child)::before {
				content: '';
				width: 100%;
				height: 10px;
				display: block;
				background: url(../../assets/img/finance/badge.png) center center no-repeat;
				background-size: 100% 100%;
				position: absolute;
				top: 0;
				left: 0;
			}

		}


	}


	@media screen and (max-width:1500px) and (max-height:700px) {

		.unit,
		.unit+div ul li:nth-of-type(1) span {
			font-size: 20px;
		}

		.unit+div ul li span:first-child,
		.unit+div ul li input,
		.unit+div ul li span:last-child .showInput {
			font-size: 20px;
		}

	}
</style>