<template>
	<div class="main">
		<Header :type="type" />
		<div id="viewInformationPage">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav />
				</div>
				<div class="viewInfo">
					<div>
						<p class="examineTitle">基本信息</p>
						<ul class="dataValuationInfo">
							<li>
								<span>公司全称</span>
								<span>{{data.companyName}}</span>
							</li>
							<li>
								<span>所属行业</span>
								<span>{{data.industryName+'- -'+data.industryChildName}}</span>
							</li>
							<li>
								<span>注册资本</span>
								<span>{{this.method.formateNum(data.regCapital,2)}}万元</span>
							</li>
							<li>
								<span>法定代表人</span>
								<span>{{data.legalPeople}}</span>
							</li>
							<li>
								<span>成立日期</span>
								<span>{{data.establishDate}}</span>
							</li>
							<li>
								<span>统一社会信用代码</span>
								<span>{{data.creditCode}}</span>
							</li>
							<li>
								<span>详细地址</span>
								<span>{{data.address}}</span>
							</li>
							<li>
								<span>数据资产介绍</span>
								<span>{{data.dataDesc?data.dataDesc:'暂无数据'}}</span>
							</li>
							<li>
								<span>数据类型</span>
								<span>{{dataType?dataType:'暂无数据'}}</span>
							</li>
							<li>
								<span>第一股东</span>
								<span>{{data.firstHolderRatio?data.firstHolderRatio+'%':'暂无数据'}}</span>
							</li>
							<li>
								<span>第二股东</span>
								<span>{{data.secHolderRatio?data.secHolderRatio+'%':'暂无数据'}}</span>
							</li>
							<li>
								<span>第三股东</span>
								<span>{{data.thirdHolderRatio?data.thirdHolderRatio+'%':'暂无数据'}}</span>
							</li>
							<li>
								<span>第四股东</span>
								<span>{{data.fourHolderRatio?data.fourHolderRatio+'%':'暂无数据'}}</span>
							</li>
							<li>
								<span>其他股东</span>
								<span>{{data.otherHolderRatio?data.otherHolderRatio+'%':'暂无数据'}}</span>
							</li>
						</ul>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '../../common/fillin/examineTitle.vue'
	import examineNav from '../../common/fillin/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				data: {
					companyName: '',
					industry: '', // 一级行业标识码
					industryChild: '', //二级行业标识码
					industryName: '', //一级行业名称
					industryChildName: '', //二级行业名称
					regCapital: '', //注册资本
					legalPeople: '', //法定代表人
					establishDate: '', //成立时间
					creditCode: '', // 统一社会信用代码
					address: '', // 详细地址
					dataDesc: '', //数据资产介绍
					dataResourceType: [], //数据类型
					firstHolderRatio: '', // 第一股东持股比例
					secHolderRatio: '', // 第二股东持股比例
					thirdHolderRatio: '', // 第三股东持股比例
					fourHolderRatio: '', // 第四股东持股比例
					otherHolderRatio: '', // 其他股东持股比例
				},
				dataType: '',
				type:14,
			}
		},
		created() {
			if (this.$route.query.userToken) {
				localStorage.userToken = this.$route.query.userToken
			}
		
			if (this.$route.query.encipher) {
				localStorage.userName = this.method.base64ToUtf8(this.$route.query.encipher)
			}
		
		},
		mounted() {

			this.getBaseInfo()
		},
		methods: {
			getBaseInfo() {
				this.$post(this.domain.valuationProject + 'auditInfoShow/getBaseInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.data = res.data.content

						JSON.parse(res.data.content.dataResourceType).map((el, i) => {
							if (i == JSON.parse(res.data.content.dataResourceType).length - 1) {
								this.dataType += el
							} else {
								this.dataType += el + '、'
							}
						})

					})
			}
		}
	}
</script>

<style scoped lang="less">
	.dataValuationInfo {
		width: 90%;
		margin: 0 auto;
	}

	.dataValuationInfo li:nth-of-type(1) span {
		font-size: 14px;
		font-weight: bold;
	}

	.dataValuationInfo li span:last-child {
		text-align: left;
		padding-left: 6%;
	}
</style>