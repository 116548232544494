<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="newViewPage"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav :type="type" :financeIndex='2' />
				</div>
				<div class="newViewInfo">
					<div class="fillinMain">
						<img class="leftTop" :src="require('@/assets/img/dataValutaion/leftTop.png')" />
						<img class="leftBottom" :src="require('@/assets/img/dataValutaion/leftBottom.png')" />
						<img class="rightTop" :src="require('@/assets/img/dataValutaion/rightTop.png')" />
						<img class="rightBottom" :src="require('@/assets/img/dataValutaion/rightBottom.png')" />
						<div class="fillinContent">
							<p class="examineTitle">数据资产</p>

							<div>
								<p class="unit">单位：元</p>

								<div class="yearLimit">
									<label for="netWorth" style="width: 268px;font-weight: bold;font-size: 16px;">
										无形资产账面净值
										<i
											style="font-size: 12px;">({{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}})</i>
										<span class="reRed">*</span>
									</label>
									<div class="baseRight">
										<input type="number" id="netWorth" autocomplete="off">
										<span class="showInput">
											{{netWirth?this.method.formateNum(netWirth,2):''}}
										</span>
										<span class="measure" style="right: 27px;">元</span>
									</div>
								</div>

								<div v-if="dataResources[0].resourceType==1">
									<ul class="forms" v-for="(item,index) in singlenessBusiness">
										<i></i>
										<li>
											<div class="rests businessName">
												<label for="businessName">业务名称<span class="reRed">*</span></label>
												<div class="baseRight">
													<input type="text" id="businessName" autocomplete="off" placeholder="请输入业务名称"
														v-model="item.businessName">
												</div>
											</div>
										</li>
										<li>
											<div>项数</div>
											<div>
												<i>数据资源名称 </i>
												<i class="reRed">*</i>
											</div>
											<div>账面原值</div>
											<div>
												账面净值
												<span
													style="font-size: 12px;">({{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}})</span>
												<span class="reRed">*</span>
											</div>
										</li>
										<li v-for="(resource,i) in JSON.parse(item.dataResources)">
											<div>{{i+1}}</div>
											<div>
												<input type="text" v-model="resource.name" />
											</div>
											<div>
												<input type="number" placeholder="原值" autocomplete="off" v-model="resource.originalValue" />
												<span class="showInput">
													{{resource.originalValue!=''?method.formateNum(resource.originalValue,2):''}}
												</span>
											</div>
											<div>
												<input type="number" placeholder="净值" autocomplete="off" v-model="resource.netValue" />
												<span class="showInput">
													{{resource.netValue!=''?method.formateNum(resource.netValue,2):''}}
												</span>
											</div>
										</li>
										<li>
											<div class="yearLimit rests">
												<label for="totalYear">数据资源预计未来经济寿命年限<span class="reRed">*</span></label>
												<div class="baseRight">
													<input type="number" id="totalYear" autocomplete="off" v-model="item.serviceLife">
													<span class="measure">年</span>
												</div>
												<p v-text="'（2年≤年限≤10年）'"></p>
											</div>
										</li>
										<i></i>
									</ul>
									<ul class="forms">
										<i></i>
										<li>
											<div>合计</div>
											<div v-for="(sum,i) in JSON.parse(productSum[0].dataResources)">
												<input type="number" disabled v-if="i!=0" />
												<span class="showInput" v-if="i!=0">
													{{sum.value!=''?method.formateNum(sum.value,2):''}}
												</span>
											</div>
										</li>
										<i></i>
									</ul>
								</div>

								<div v-else>
									<ul class="forms" v-for="(item,index) in singlenessBusiness">
										<i></i>
										<li>
											<div>项数</div>
											<div>
												<i>数据资源名称 </i>
												<i class="reRed">*</i>
											</div>
											<div>账面原值</div>
											<div>
												账面净值
												<i
													style="font-size: 12px;">({{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}})</i>
												<span class="reRed">*</span>
											</div>
										</li>
										<li v-for="(resource,i) in JSON.parse(item.dataResources)">
											<div>{{i+1}}</div>
											<div>
												<input type="text" v-model="resource.name" />
											</div>
											<div>
												<input type="number" placeholder="原值" autocomplete="off" v-model="resource.originalValue" />
												<span class="showInput">
													{{resource.originalValue!=''?method.formateNum(resource.originalValue,2):''}}
												</span>
											</div>
											<div>
												<input type="number" placeholder="净值" autocomplete="off" v-model="resource.netValue" />
												<span class="showInput">
													{{resource.netValue!=''?method.formateNum(resource.netValue,2):''}}
												</span>
											</div>
										</li>
										<li>
											<div>合计</div>
											<div v-for="(sum,i) in JSON.parse(productSum[0].dataResources)">
												<input type="number" disabled v-if="i!=0" />
												<span class="showInput" v-if="i!=0">
													{{sum.value!=''?method.formateNum(sum.value,2):''}}
												</span>
											</div>
										</li>
										<li>
											<div class="yearLimit rests">
												<label for="totalYear">数据资源预计未来经济寿命年限<span class="reRed">*</span></label>
												<div class="baseRight">
													<input type="number" id="totalYear" autocomplete="off" v-model="item.serviceLife">
													<span class="measure">年</span>
												</div>
												<p v-text="'（2年≤年限≤10年）'"></p>
											</div>
										</li>

										<i></i>
									</ul>
								</div>

							</div>



						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '@/common/fillin/examineTitle.vue'
	import common from '@/views/common'
	import examineNav from '../../nav/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [common],
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				type: '13',
				dataResources: [{
					resourceType: ''
				}],
				singlenessBusiness: [],
				productSum: [],
				createTime: '',
				netWirth: ''


			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getDataFinancialFutureValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						this.$Spin.hide();

						this.createTime = res.data.content.createTime
						this.netWirth = res.data.content.netWirth
						this.dataResources = res.data.content.dataResources


						res.data.content.dataResources.map(el => {
							if (el.businessName == 'productSum') {
								this.productSum.push(el)
							} else {
								this.singlenessBusiness.push(el)
							}
						})



					})
			}

		}
	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	.forms {
		margin-bottom: 25px !important;

		.close {
			width: 26px;
			height: 26px;
			position: absolute;
			top: -10px;
			right: -10px;
			z-index: 3;
			cursor: pointer;
		}

		li:nth-of-type(1) {
			font-size: 16px;
			font-weight: bold;

			i {
				font-weight: bold;
			}
		}

		>li:not(:nth-of-type(1)) {
			border-top: 1px solid #29FFFC;
		}

		>li:not(:nth-of-type(1))::before {
			content: '';
			display: block;
			width: 10px;
			height: 9px;
			background: url(../../../../assets/img/contribution/cornerMark.png) center center no-repeat;
			background-size: contain;
			position: absolute;
			top: -1px;
			left: 0;
		}

		li {
			font-size: 14px;
			text-align: center;
			display: flex;
			align-items: center;
			position: relative;


			>div:nth-of-type(1):not(.rests) {
				width: 10%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			>div:nth-of-type(2) {
				width: 30%;
			}

			>div:nth-of-type(3) {
				width: 25%;
			}

			>div:nth-of-type(4) {
				width: 35%;
			}

			>div:nth-of-type(5) {
				width: 10%;
			}

			>div {
				position: relative;
				padding: 15px 0;
				color: #fff;
			}

			>div::after {
				content: '';
				display: block;
				width: 2px;
				height: 25px;
				background: #29FFFC;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			>div:last-child::after {
				background: transparent;
			}

			input {
				width: 76%;
				height: 30px;
				background: #1237C6;
				border: 1px solid #29FFFC;
				font-size: 14px;
				color: #fff;
				padding-right: 6px;
				pointer-events: none;
			}

			input[type="text"] {
				padding-left: 6px;
			}

			input[type="number"] {
				text-align: right;
			}

			input:focus {
				border-color: #fff;
			}

			input:disabled {
				background: #4B9BFF;
				border-color: #4B9BFF;
			}

			.showInput {
				width: 71%;
				height: 27px;
				background: #1237c6;
				line-height: 28px;
				font-size: 14px;
				padding: 0 2px;
				color: #fff;
				text-align: right;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				overflow: hidden;
			}

			input:disabled+.showInput {
				display: block;
				background: #4B9BFF;
				border-color: #4B9BFF;
			}

			.addResource {
				width: 480px;
				height: 36px;
				line-height: 36px;
				border-radius: 6px;
				border: 1px solid #19ECFF;
				font-size: 14px;
				color: #29FFFC;
				text-align: center;
				margin: 20px auto;
				cursor: pointer;
			}

			.rests {
				padding-left: 3% !important;
			}


			.businessName {
				display: flex;
				align-items: center;
				padding-top: 25px;
				padding-bottom: 25px;

				label {
					font-size: 16px;
					font-weight: bold;
				}

				input {
					width: 340px;
				}
			}



		}
	}

	.addBusinessType {
		width: 480px;
		height: 36px;
		line-height: 36px;
		background: #29FFFC;
		border-radius: 6px;
		font-size: 14px;
		color: #1237C6;
		text-align: center;
		margin: 25px auto;
		cursor: pointer;
	}

	.yearLimit {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 25px 0;

		>p {
			font-size: 14px;
			color: #fff;
		}

		label {
			width: 226px;
			font-size: 14px;
			color: #fff;
		}

		input,
		.baseRight,
		.showInput {
			width: 242px;
		}

		input {
			width: 76%;
			height: 30px;
			background: #1237C6;
			border: 1px solid #29FFFC;
			font-size: 14px;
			color: #fff;
			padding: 0 26px 0 10px;
		}

		input:focus {
			border-color: #fff;
		}

		.showInput {
			width: 75%;
			height: 27px;
			line-height: 30px;
			background: #1237C6;
			padding: 0 6px;
			color: #fff;
			font-size: 14px;
			position: absolute;
			top: 1px;
			left: 1px;
			overflow: hidden;
		}

		.measure {
			width: 25px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 0;
			color: #fff;
			font-size: 14px;
		}

		.baseRight {
			height: 30px;
			position: relative;
		}
	}
</style>