<template>
	<div class="dialog" v-show="showMask">
		<div class="dialog-container-cache" v-if="type == 'cacheType'">
			<!-- 是否需载入缓存遮罩层 -->
			<img src="../../assets/img/fillin/lingdang.png" class="dia-img-em">
			<p class="dialog-title">{{title}}</p>
			<div class="close-btn" @click="cacheMask">
				<img src="../../assets/img/fillin/guanbi.png" alt="关闭遮罩层-心流">
			</div>
			<div class="cache-save-branch-bg" @click="cacheTrueMask">
				是
			</div>
			<div class="cache-save-branch" @click="cacheMask">
				否
			</div>
		</div>
		<div class="dialog-container" v-else>
			<img
				v-if="type == 'notEmpty'||type == 'notEmpty-info' ||type == 'whyEmpty'||type == 'delType'||type=='closeData'||type=='yesNo'||type=='notClose'"
				src="../../assets/img/fillin/提示.png" class="dia-img-em">
			<img v-else src="../../assets/img/fillin/tishitanhchuang.png" class="dia-img">

			<p class="dialog-title">{{title}}</p>
			<!-- 数据为空时候  弹出提示层  确定-->
			<div v-if="type == 'notEmpty'" class="dia-save">
				<div @click="closeMask">
					确定
				</div>
			</div>

			<!-- 数据为空时候  弹出提示层  确定-->
			<div v-if="type == 'notEmpty-info'||type=='notClose'" class="dia-save">
				<div @click="closeMaskInfo">
					确定
				</div>
			</div>

			<!-- 填写 关闭 弹出提示框   确定、取消-->
			<div v-if="type == 'closeData'||type=='yesNo'" class="dia-close">
				<div class="dia-save-branch-bg" @click="closeMask">
					{{type=='yesNo'?'否':'取消'}}
				</div>
				<div class="dia-save-branch" @click="confirmJump">
					{{type=='yesNo'?'是':'确定'}}
				</div>
			</div>

			<!-- 软项指标   未填写没填写 -->
			<div v-if="type == 'whyEmpty'" class="dia-save">
				<div @click="whyem">
					确定
				</div>
			</div>


			<!-- 列表 弹出提示框   确定、取消-->
			<div v-if="type == 'delType'" class="dia-close">
				<div class="dia-save-branch-bg" @click="closeMask">
					取消
				</div>
				<div class="dia-save-branch" @click="listConfirmJump">
					确定
				</div>
			</div>

			<!-- 一键生成报告  提示 -->
			<div v-if="type == 'generateBtnType'" class="dia-save">
				<div @click="closeMask">
					确定
				</div>
			</div>


			<div class="close-btn" @click="closeMask" v-if="type!='notClose'">
				<img src="../../assets/img/fillin/guanbi.png" alt="关闭遮罩层-心流">
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		props: {
			value: {},
			type: {
				type: "",
				default: 'default'
			},
			content: {
				type: String,
				default: ''
			},
			title: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				showMask: false,
			}
		},
		methods: {
			closeMask() {
				this.showMask = false;
			},
			closeMaskInfo() {
				this.$emit('closeMaskInfoJump');
				this.closeMask();
			},
			// 关闭弹出层
			confirmJump() {
				this.$emit('confirmJump');
				this.closeMask();
			},
			whyem() {
				this.$emit('whyem');
				this.closeMask();
			},
			listConfirmJump() {
				this.$emit('delTypeJump');
				this.closeMask();
			},
			cacheTrueMask() {
				this.$emit('cacheTrueMask');
				this.closeMask();
			},
			cacheMask() {
				this.$emit('cacheMask');
				this.closeMask();
			}

		},
		mounted() {
			this.showMask = this.value;
			// console.log(this.value)
		},
		watch: {
			value(newVal, oldVal) {
				this.showMask = newVal;
			},
			showMask(val) {
				this.$emit('input', val);
			}
		},
	}
</script>
<style lang="less" scoped>
	.dialog {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.6);
		z-index: 9999;
	}

	.dialog-container {
		width: 417px;
		height: 291px;
		background: #ffffff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 8px;
		position: relative;
		background: url("../../assets/img/fillin/tishi.png");
		background-size: 100% 100%;
	}

	.dialog-container-cache {
		width: 392px;
		height: 269px;
		background: #ffffff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 8px;
		position: relative;
		background: url("../../assets/img/fillin/tishi.png");
		background-size: 100% 100%;
	}

	.dialog-container .dia-img {
		width: 130px;
		display: block;
		margin: auto;
		padding-top: 45px;
	}

	.dia-img-em {
		width: 80px;
		display: block;
		margin: auto;
		padding-top: 48px;
	}

	.dialog-title {
		width: 89%;
		font-size: 14px;
		color: #fff;
		text-align: center;
		margin: 20px auto 0;
	}

	.dia-save {
		width: 150px;
		height: 40px;
		line-height: 40px;
		background: url("../../assets/img/fillin/baocun.png");
		background-size: 100% 100%;
		text-align: center;
		color: #fff;
		font-size: 14px;
		margin: auto;
		margin-top: 20px;
		cursor: pointer;
	}

	.dia-save:hover {
		background: url(../../assets/img/fillin/baocunHover.png);
		background-size: 100% 100%;
	}

	.dia-close {
		// width: 100%;
		overflow: hidden;
		margin-left: 63px;
	}

	.dia-save-branch {
		width: 120px;
		height: 30px;
		line-height: 30px;
		background: url("../../assets/img/fillin/baocun.png");
		background-size: 100% 100%;
		text-align: center;
		color: #fff;
		font-size: 14px;
		margin: auto;
		margin-top: 20px;
		float: left;
		cursor: pointer;
	}

	.dia-save-branch:hover {
		background: url(../../assets/img/fillin/baocunHover.png);
		background-size: 100% 100%;
	}

	.dia-save-branch-bg {
		width: 120px;
		height: 30px;
		line-height: 30px;
		background: url("../../assets/img/fillin/baocun.png");
		background-size: 100% 100%;
		text-align: center;
		color: #fff;
		font-size: 14px;
		margin: auto;
		float: left;
		cursor: pointer;
		margin-top: 20px;
		margin-right: 30px;
	}

	.cache-save-branch {
		width: 150px;
		height: 60px;
		background: url("../../assets/img/fillin/shi.png");
		background-size: 100% 100%;
		text-align: center;
		line-height: 60px;
		color: #fff;
		font-size: 14px;
		margin: auto;
		float: left;
		cursor: pointer;
		// margin-left: 20px;
	}

	.cache-save-branch-bg {
		width: 140px;
		height: 60px;
		background: url("../../assets/img/fillin/fou.png");
		background-size: 100% 100%;
		text-align: center;
		line-height: 60px;
		color: #fff;
		font-size: 14px;
		// margin: auto;
		float: left;
		cursor: pointer;
		// margin-top: 15px;
		margin-left: 51px;
	}

	.close-btn {
		width: 66px;
		position: absolute;
		right: 0px;
		top: 0px;
		cursor: pointer;
	}

	.close-btn img {
		width: 100%;
	}
</style>