<template>
	<div>
		<div class="tools">
			<button @click="pdf()" v-if="pageInfo.isTestAccount==0||this.$route.query.admin == 1">导出PDF</button>
		</div>

		<div id="pageContent">
			<div class="homePage page">
				<div class="content" id="homePage">
					<img class="logo" src="../../../assets/img/logo/flowBlack.png" alt="logo" />
					<p>数据价值测评证书</p>

					<div style="width: 867px;margin: 0 auto;">
						<ul class="certificateInfo">
							<li>
								<span>企业名称：{{pageInfo.CompanyName}}</span>
							</li>
							<li>
								<span>统一社会信用代码：{{pageInfo.CreditCode}}</span>
							</li>
							<li>
								<span>证书编号：{{pageInfo.No}}</span>
							</li>
							<li>
								<span>签发日期：{{pageInfo.StartDate}}</span>
							</li>
							<li>
								<span>有效期至：{{pageInfo.EndDate}}</span>
							</li>
						</ul>
						<p class="regulation">
							依据《数据安全法》、《数据资产评估指导意见》、《信息安全技术 数据交易服务安全要求》(BG/T 37932-2019 ）、以及心流慧估自研《数据价值测评模型》等法律法规和技术，贵公司的数据价值测评等级为：
						</p>
						<div class="grade">{{pageInfo.Evaluation}}</div>
						<div class="statement">
							<p>声明</p>
							<ul>
								<li>1、测评为五个等级：A、AA、AAA、AAAA、AAAAA，等级越高，代表数据价值越高。</li>
								<li>2、本证书严禁涂改、伪造，否则将视为无效。</li>
								<li>3、未经本机构书面许可，任问单位和个人不得复制，分发或以其他方式使用本证书及其内容。</li>
								<li>4、如对本证书内容有疑问，可联系颁证机构进行查询验证。</li>
								<li>5、本证书最终解释权归北京心流慧估科技有限公司所有。</li>
							</ul>
						</div>

						<div class="signature">
							<p>
								<span style="font-size: 26px;">首席测评官：</span>
								<img src="../../../assets/img/dataEvaluation/certificate/CGO.png" alt="CGO" />
							</p>
							<p style="font-size:33px;margin-left: 48px;">北京心流慧估科技有限公司</p>
							<img class="chapter" src="../../../assets/img/dataEvaluation/certificate/chapter.png" alt="章" />
						</div>

						<p class="website">www.flcccc.com</p>

					</div>
				</div>
			</div>

		</div>


		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>

	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'
	import common from '@/views/common'
	import '@/assets/css/loading.css'
	import PdfOnload from '@/utils/pdf'

	export default {
		mixins: [common],
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
				pageArr: [],
				pdfIndex: 0,
				pageInfo: {
					isTestAccount: null, //0:无水印可下载，1：有水印不可下载
					CompanyName: '',
					CreditCode: '',
					EndDate: '',
					Evaluation: '',
					No: '',
					StartDate: ''
				}


			}
		},
		mounted() {
			this.init()

		},
		methods: {
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.CompanyName + '数据价值测评证书.pdf'
				var pageArr = document.getElementsByClassName("content");
				this.pdfArr = [];
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				console.log(this.pdfArr)
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			},
			init() {
				this.$post(this.domain.valuationProject + 'certificate/getCertificateInfo', qs.stringify({
						reportId: this.reportId
					}))
					.then(res => {
						this.pageInfo = res.data.content

						this.$nextTick(() => {
							this.addPageWatermark(this.pageInfo.isTestAccount)

						})

					})

			}

		}


	}
</script>

<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	.page {
		width: 100%;
		height: 1754px;
		position: relative;
		line-height: normal;
		vertical-align: middle;
	}

	.content {
		width: 1240px;
		height: 100%;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
	}

	#homePage {
		padding: 100px;
		background: url(../../../assets/img/dataEvaluation/certificate/certificateBackground.jpg) center center no-repeat;

		.logo {
			width: 370px;
			// height: 121px;
			display: block;
			margin: 70px auto 40px;
		}

		.logo+p {
			font-size: 89px;
			color: #000000;
			text-align: center;
			font-weight: bold;
		}

		.certificateInfo {
			margin-top: 40px;

			li {
				margin-bottom: 25px;

				span {
					font-size: 23px;
					color: #000000;
				}
			}
		}

		.regulation {
			width: 867px;
			margin-top: 38px;
			text-align: justify;
			font-size: 23px;
			color: #050407;
			text-indent: 2em;
			line-height: 40px;
		}

		.grade {
			width: 867px;
			height: 233px;
			line-height: 233px;
			background: url(../../../assets/img/dataEvaluation/certificate/grade.png) center center no-repeat;
			margin: 18px auto;
			text-align: center;
			font-size: 75px;
			color: #050407;
			font-weight: bold;
		}

		.statement {
			>p {
				font-size: 20px;
				color: #323333;
				margin-bottom: 8px;
			}

			ul {

				li {
					font-size: 20px;
					color: #323333;
					margin-bottom: 8px;
				}
			}
		}

		.signature {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			position: relative;
			top: 103px;

			img {
				vertical-align: middle;
			}

			.chapter {
				width: 240px;
				position: absolute;
				right: 74px;
				bottom: -37px;
			}

			p {
				color: #323333;
			}
		}

		.website {
			position: absolute;
			bottom: 146px;
			right: 183px;
			font-size: 23px;
			color: #050407;
		}

	}
</style>