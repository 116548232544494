<template>
    <div>
        <!-- 免责声明 -->
        <div class="agreement disclaimer">
                <div class="text_content">
                    <h3>免责声明</h3>
                    <div class="content agreementContent">
                        <p>使用本站点前，请仔细阅读下列条款：</p>
                        <p>“心流网”（www.flcccc.com）是为用户提供线上企业股权估值及其他相关服务的网络平台。</p>
                        <p>1、心流网依据国际通用的估值模型和自主研发的估值指标体系，在线进行企业股权估值。所采用的数据均为用户提供和公开渠道可获取，心流网不对前述数据的准确性、完整性和真实性负有实质审核的义务。网站自动生成的相关报告，仅给用户提供参考股权估值区间，不能作为企业并购、转让等交易的法定依据，并不构成任何投资建议，投资者据此做出的任何投资决策与心流网无关。</p>
                        <p>2、用户应妥善保管用户账号和密码等个人信息，心流网承诺不向任何第三方泄露用户个人信息或其他商业信息。除非司法机关、监管机构或其他政府部门依据法律程序要求心流网提供，心流网将根据相关部门的要求提供相关信息资料，及时通知用户。</p>
                        <p>3、心流网挑选具备良好声誉的网站作为友情链接列入网站进行展示，供用户浏览和参考。但该行为并不视为心流网与所列示网站一定具有合作关系，请自行对相关网站的内容进行审慎辨别及判断，对于用户使用外部链接网站而引起的损失或损害，心流网不承担任何责任。</p>
                        <p>4、您使用心流网时，应遵守中国有关法律规定与本声明的内容，不得破坏心流网运行安全，不得利用心流网进行任何违法活动，不得损害心流网所有者的合法权益。</p>
                        <p>5、除转载信息外，心流网所有站内原创内容的著作权属于北京心流慧估科技有限公司所有。任何机构或者个人未经北京心流慧估科技有限公司书面许可，不得以任何形式使用前述内容。</p>
                        <p>您浏览和使用心流网即视为您接受以上条款。心流网在法律许可范围内有权对本声明进行解释和修改，并将以网站信息更新的方式另行通知。</p>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
    export default {
        name:"disclaimer",
        data() {
            return {

            }
        }
    }
</script>
<style scoped>
    .content {
        width: 100%;
    }
    .agreement {
        padding: 0 20px;
    }
    .agreement h3 {
        text-align: center;
        font-size:22px;
        line-height:48px;
        color:#000;
        font-weight: bold;
    }
    .agreement p {
        font-size: 14px;
        color:#000;
        line-height:28px;
        text-align: justify;
        text-indent:28px;
    }
</style>