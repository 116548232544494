import sensitiveWord from '../js/sensitiveWord.json'
import qs from 'qs'
export default {
	filterSensitiveWords(e, dataKey, whetherFinancial) { //过滤敏感词    e:dom对象  dataKey：基本信息key   whetherFinancial:是否是财务报表
		var that = this;
		var sensitiveWordsList = sensitiveWord.sensitiveWord;
		var sensitiveWordsFlag = false;
		for (var i = 0; i < sensitiveWordsList.length; i++) {
			if (e.value.indexOf(sensitiveWordsList[i]) != -1) {
				sensitiveWordsFlag = true;
			}
			var len = sensitiveWordsList[i].length;
			var str = '';
			for (var l = 0; l < len; l++) {
				str += '*'
			}
			var r = new RegExp(sensitiveWordsList[i], "ig");
			e.value = e.value.replace(r, str);
		}
		if (whetherFinancial == 'statement') {
			dataKey[0] = e.value;
		} else {
			dataKey[e.getAttribute('id')] = e.value;
		}
		return sensitiveWordsFlag
	},
	// 报告中估值结果单位转换及千分位展示(最大值、最小值)
	valuationResultConversion(min, max) {
		// 最小值
		if (Math.abs(min) / 10000 > 1) {
			if (Math.sign(min) == -1) { //值为负数
				min = '-' + this.formateNumTwo((Math.abs(min) / 10000).toFixed(2)) + '亿元'
			} else {
				min = this.formateNumTwo((Math.abs(min) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(min) == -1) { ////值为负数
				min = '-' + this.formateNumTwo(Math.abs(min).toFixed(2)) + '万元';
			} else {
				min = this.formateNumTwo(Math.abs(min).toFixed(2)) + '万元';
			}
		}

		// 最大值
		if (Math.abs(max) / 10000 > 1) {
			if (Math.sign(max) == -1) { //值为负数
				max = '-' + this.formateNumTwo((Math.abs(max) / 10000).toFixed(2)) + '亿元'
			} else {
				max = this.formateNumTwo((Math.abs(max) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(max) == -1) { ////值为负数
				max = '-' + this.formateNumTwo(Math.abs(max).toFixed(2)) + '万元';
			} else {
				max = this.formateNumTwo(Math.abs(max).toFixed(2)) + '万元';
			}
		}
		return min + '~' + max
	},
	resultConversion(data) { //单个数据换算单位和千分位展示
		if (Math.abs(data) / 10000 > 1) {
			if (Math.sign(data) == -1) { //值为负数
				data = '-' + this.formateNumTwo((Math.abs(data) / 10000).toFixed(2)) + '亿元'
			} else {
				data = this.formateNumTwo((Math.abs(data) / 10000).toFixed(2)) + '亿元'
			}
		} else {
			if (Math.sign(data) == -1) { //值为负数
				data = '-' + this.formateNumTwo(Math.abs(data).toFixed(2)) + '万元';
			} else {
				data = this.formateNumTwo(Math.abs(data).toFixed(2)) + '万元';
			}
		}
		return data
	},
	switchNum: function(min, max) {
		var unit = '';
		// 最小值
		if (Math.abs(min) / 10000 > 1) {
			if (Math.sign(min) == -1) { //值为负数
				min = (Math.abs(min) / 10000).toFixed(2)
			} else {
				min = (Math.abs(min) / 10000).toFixed(2)
			}
		} else {
			if (Math.sign(min) == -1) { ////值为负数
				min = Math.abs(min).toFixed(2)
			} else {
				min = Math.abs(min).toFixed(2)
			}
		}

		// 最大值
		if (Math.abs(max) / 10000 > 1) {
			if (Math.sign(max) == -1) { //值为负数
				max = (Math.abs(max) / 10000).toFixed(2)
			} else {
				max = (Math.abs(max) / 10000).toFixed(2)
			}
			unit = '亿元'
		} else {
			if (Math.sign(max) == -1) { ////值为负数
				max = Math.abs(max).toFixed(2)
			} else {
				max = Math.abs(max).toFixed(2)
			}
			unit = '万元'
		}
		return {
			min: min,
			max: max,
			unit: unit
		}
	},
	technologyNum: function(data) {
		var unit = '';
		if (Math.abs(data) / 10000 > 1) {
			if (Math.sign(data) == -1) { //值为负数
				data = (Math.abs(data) / 10000).toFixed(2)
			} else {
				data = (Math.abs(data) / 10000).toFixed(2)
			}
			unit = '亿元'
		} else {
			if (Math.sign(data) == -1) { ////值为负数
				data = Math.abs(data).toFixed(2)
			} else {
				data = Math.abs(data).toFixed(2)
			}
			unit = '万元'
		}
		return {
			data: data,
			unit: unit
		}
	},
	/**
	 * @func 数字格式化      千分位
	 * @param {string} str   需要格式化的数字
	 * @param {number} num   小数的位数
	 * @returns {string} str
	 */
	formateNumTwo(str, num) {
		var isnegative = true; // 表示正负数；false：小于0
		var value;
		if (isNaN(parseFloat(str))) {
			return;
		}
		if (parseFloat(str) < 0) {
			isnegative = false;
			value = str.split("-")[1]
		} else {
			value = str;
		}
		value = value.replace(/^\s+|\s+$/g, '');
		var right = value.split(".")[1] ? value.split(".")[1] : "0",
			rightValue;
		var left = value.split(".")[0].split("").reverse();
		if (right.length == num) {
			rightValue = right;
		} else if (right.length < num) {
			var i = 0,
				rightValue = right;
			while (num - right.length - i > 0) {
				rightValue += "0";
				i++;
			}

		} else {
			rightValue = right.substr(0, num)
		}
		var total = new Array();
		for (var i = 0; i < left.length; i++) {
			total.push(left[i]);
			if ((i + 1) % 3 == 0 && (i + 1) != left.length) {
				total.push(",");
			}
		}
		if (isnegative) {
			return num == 0 ? total.reverse().join("") : total.reverse().join("") + "." + rightValue;
		} else {
			return num == 0 ? "-" + total.reverse().join("") : "-" + total.reverse().join("") + "." + rightValue;
		}
	},
	formateNum(s, n) {
		if (s == "" || s == null) {
			return '0.00';
		}

		if (n) {
			n = n > 0 && n <= 20 ? n : 2;
			s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
		} else {
			s = s + "";
		}
		var l = s.split('.')[0].split('').reverse();
		var r = s.split('.')[1];

		var t = '';
		for (var i = 0; i < l.length; i++) {

			t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length && l[i + 1] != '.' && l[i] != '.' && l[i + 1] != "-" ?
				',' : '');
		}
		if (r == undefined) {
			console.log(111111)
			return t.split('').reverse().join('');
		} else {
			return t.split('').reverse().join('') + '.' + r;
		}
	},



	// 填写页面缓存 (没有报告id没有数据和有报告id没有数据这两种情况进行缓存)
	caching(category, info, reportType, stage, _this) {
		// 缓存接口调用===========
		var cacheIntervalTime = 2000; //缓存时间间隔秒数  （目前是5秒）
		var cacheCurrentTime = Date.now(); //当前时间
		if (sessionStorage.cacheTime) {
			if (Number(cacheCurrentTime) > (Number(sessionStorage.cacheTime) + cacheIntervalTime)) {
				sessionStorage.cacheTime = Date.now();
				// 缓存接口调用===========
				var url = '';
				// 临时  http://192.168.12.120:8089/
				url = _this.domain.ajaxUrl + 'cache/saveCache'
				var params = {
					category: category,
					info: info,
					reportType: reportType,
					stage: stage
				};
				_this.$post(url, qs.stringify(params))
					.then((res) => {
						// console.log(res)
					});
				// console.log('当前时间超过上次保存的时间5秒')
			} else {
				// console.log('当前时间没超过上次保存的时间5秒')
			}
		} else {
			sessionStorage.cacheTime = Date.now();
		}
	},
	cacheInfo(category, info, reportType, stage, _this) {
		var url = '';
		// 临时  http://192.168.12.120:8089/
		url = _this.domain.ajaxUrl + 'cache/saveCache'
		var params = {
			category: category,
			info: info,
			reportType: reportType,
			stage: stage
		};
		_this.$post(url, qs.stringify(params))
			.then((res) => {
				// console.log(res)
			});
	},
	// 清缓存调用
	clearCaching(type, stage, _this, getinfo) {
		var url = '';
		url = _this.domain.ajaxUrl + 'cache/deleteCache'
		var params = {
			reportType: type,
			stage: stage,
		};
		_this.$post(url, qs.stringify(params))
			.then((res) => {
				// 获取getinfo接口
				if (getinfo == 'getBaseInfo') {
					_this.getBaseInfo()
				} else if (getinfo == 'getBalanceSheet') {
					_this.getBalanceSheet()
				} else if (getinfo == 'getProfit') {
					_this.getProfit()
				} else if (getinfo == 'getQuestion') {
					_this.getQuestion()
				} else if (getinfo == 'getOperatingReceipt') {
					_this.getOperatingReceipt()
				} else if (getinfo == 'getAccountingPolicy') {
					_this.getAccountingPolicy()
				}

			});
	},
	transform() {
		// 获取元素
		var element = document.getElementById('formWidth');
		var width = 1870;
		var height = 1027;

		// 计算缩放比例，假设你想要元素在一屏内完全显示
		if (window.innerWidth < 1200) { //1290 是给两边各留20px的距离
			var scale = Math.min(1160 / width, (window.innerHeight - 90) / height);
		} else {
			var scale = Math.min((window.innerWidth - 40) / width, (window.innerHeight - 80) / height);
		}

		var calculateHeight = -(scale * element.offsetHeight / 2 - 165) + 'px'

		// 应用缩放和偏移 calc(-50% - 40px)  translate(-50%, -50%)
		// element.style.transform = 'translate(-50%,-50%) scale(' + scale + ')';

		element.style.transform = 'scale(' + scale + ') translate(-50%,-50%)';

		var labelElements = document.getElementsByTagName('label');

		for (var i = 0; i < labelElements.length; i++) {
			// labelElements[i].style.fontSize = 1/scale+'em';
		}

	},
	disableWheel(evt) {
		console.log('禁用滚轮')
		// 禁用滚轮
		evt = evt || window.event
		if (evt.preventDefault) {
			// Firefox + Chrome + Safari
			evt.preventDefault()
			evt.stopPropagation()
		} else {
			// IE
			evt.cancelBubble = true
			evt.returnValue = false
		}
		return false
	},
	ratioEquality(originalWidth, originalHeight,
		presentWidth) { //等比设置元素的宽高  originalWidth:原始宽   originalHeight：原始高   presentWidth：现在的宽
		const height = (originalHeight * presentWidth) / originalWidth

		// console.log(presentWidth)
		// console.log('新高度------',height)

		return height

	},
	ratioEqualityHeight(originalWidth, originalHeight, presentHeight) {
		const width = (presentHeight / originalHeight) * originalWidth

		return width

	},
	utf8ToBase64(str) { //加密
	    var encoder = new TextEncoder();
	    var view = encoder.encode(str);
	    var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(view)));
	    return base64String;
	},
	base64ToUtf8(base64String) { //解密
	    // 使用 atob 解码 Base64 字符串
	    var binaryString = atob(base64String);
	    // 将二进制字符串转换为字符数组（每个字符代表一个字节）
	    var len = binaryString.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binaryString.charCodeAt(i);
	    }
	    // 使用 TextDecoder 将字节序列解码为 UTF-8 字符串（实际上是 UTF-16，因为 JavaScript）
	    var decoder = new TextDecoder('utf-8');
	    var utf8String = decoder.decode(bytes);
	    return utf8String;
	}





}