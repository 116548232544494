<template>
	<div class="main">
		<Header :type="type" />
		<div id="viewInformationPage">
			<examineTitle />
			<div class="examineContent">
				<div>
					<examineNav />
				</div>
				<div class="viewInfo">
					<div>
						<div class="softIndicatorModule" v-for="(el,i) in softIndexProblem">
							<p class="examineTitle">{{el.contentName}}</p>
							<ul>
								<li v-for="(item,index) in el.contentDetails">
									<div class="position-tit-container">
										<span>{{index+1}}</span>
										<div v-html="item.content"></div>
										<span class="desc-container" v-if="item.canExplain">
											<span></span>
											<div class="desc-main">
												<p v-html="item.explaination.replace(/\\n/g,'<br>')"></p>
												<span></span>
											</div>
										</span>
									</div>
									<div class="options-container">
										<ul>
											<li v-for="(answer, a) in item.options" :class="[answer.chooseFlag=='1'?'active':'']" ref="soft">

												<!-- <input type="radio" :checked="answer.chooseFlag=='1'?'checked':''" :id="answer.id"
													:name="answer.questionId" :data-type="item.contentType" disabled> -->
												<label :for="answer.id" :class="answer.chooseFlag=='1'?'yes':'no'">{{answer.content}}</label>
												<div class="desc-container" v-if="answer.canExplain!=false">
													<span></span>
													<div class="desc-main">
														<p v-html="answer.explaination.replace(/\\n/g,'<br>')"></p>
														<span></span>
													</div>
												</div>
											</li>
										</ul>

									</div>

								</li>
							</ul>
						</div>
					</div>
				</div>

			</div>
		</div>



		<Modal v-model="alert" class-name="vertical-center-modal videoAlert" :mask-closable="false">
			<div style="height: 315px;">
				<video :src="video" crossorigin="anonymous" class="example-video video-js vjs-big-play-centered vjs-fluid"
					controls preload="auto" style="height: 100%;"></video>

			</div>
			<p slot="footer"></p>
		</Modal>



	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import examineTitle from '../../common/fillin/examineTitle.vue'
	import examineNav from '../../common/fillin/examineNav.vue'
	import qs from 'qs'
	import axios from 'axios'

	export default {
		components: {
			Header,
			examineTitle,
			examineNav
		},
		data() {
			return {
				type: 14,
				softIndexProblem: {},
				alert: false,
				video: '',
			}
		},
		mounted() {
			this.getQuestion()
		},
		updated() {

		},
		methods: {
			getQuestion() {
				var this_ = this
				this.$post(this.domain.valuationProject + 'auditInfoShow/getQuestion', qs.stringify({
						stage: sessionStorage.stage,
						reportType: this.$route.query.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: sessionStorage.versions
					}))
					.then((res) => {
						this.softIndexProblem = res.data.content

						this.$nextTick(function() {
							if (this.$refs.soft) {
								this.$refs.soft.forEach((item, i) => {
									// console.log(parseInt(item.offsetHeight / 22))
									if (parseInt(item.offsetHeight / 22) > 1) {
										item.style.borderRadius = '150px';
									}
								})
							}

							$(document).on('click', '.viewVideo', function() {
								this_.alert = true
								this_.video = this_.domain.testUrl + $(this).attr('data-url')
								console.log(this_.video)
							})

						})


					})
			}

		}
	}
</script>


<style scoped src="../../assets/css/evaluateCommon.css"></style>
<style scoped lang="less">
	.softIndicatorModule {
		margin-top: 30px;
	}

	.softIndicatorModule:first-child {
		margin-top: 0;
	}

	.position-tit-container>span {
		font-size: 16px;
	}

	.options-container label {
		font-size: 14px;
	}

	.options-container {
		padding-left: 10%;
	}

	.options-container li {
		width: 110%;
	}
</style>