<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default {
		props: [
			'chartData', 'id', 'width'
		],
		data() {
			return {


			}
		},
		mounted() {
			this.initChart(this.id)

		},
		methods: {
			initChart(id) {
				var this_ = this

				// 渲染平台资源数量统计图表
				var LowerLeftObliqueCut = echarts.graphic.extendShape({
					buildPath: (ctx, shape) => {
						const xAxisPoint = shape.xAxisPoint;
						// 左下角斜切
						const c0 = [shape.x, shape.y];
						const c1 = [shape.x + this_.width, shape.y];
						const c2 = [xAxisPoint[0] + this_.width, xAxisPoint[1]];
						const c3 = [xAxisPoint[0], xAxisPoint[1] - 10];

						ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath();
					}
				});
				var InclinedRoofColumn = echarts.graphic.extendShape({
					buildPath: (ctx, shape) => {
						const xAxisPoint = shape.xAxisPoint;
						// 右下角斜切
						const c0 = [shape.x, shape.y];
						const c1 = [shape.x + this_.width, shape.y];
						const c2 = [xAxisPoint[0] + this_.width, xAxisPoint[1] - 10];
						const c3 = [xAxisPoint[0], xAxisPoint[1]];

						ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath();
					}
				});
				// 注册形状
				echarts.graphic.registerShape('InclinedRoofColumn', InclinedRoofColumn);
				echarts.graphic.registerShape('LowerLeftObliqueCut', LowerLeftObliqueCut);


				const statisticsplatformData = [
					this.chartData.xdata,
					this.chartData.data
				]

				var maxData = []
				this.chartData.data.map(item => {
					maxData.push(100)
				})

				var option = {
					tooltip: {
						trigger: "none",
						show: false
					},
					grid: {
						// top: "10%",
						left: "0",
						bottom: "3%",
						right: "0",
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: statisticsplatformData[0],
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLine: {
							symbol: ['none'],
							symbolSize: [10, 17],
							lineStyle: {
								color: '#1459F4',
							},
						},
						axisLabel: {
							//调整x轴的lable
							color: '#282828',
							fontSize: 16,
							margin: 15
						}
					},
					yAxis: {
						type: "value",
						max: 100,
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							fontSize: 16,
							color: "#282828"
						},
						splitLine: {
							show: false //网格线
						}
					},
					axisPointer: {
						show: false,
						link: {
							xAxisIndex: 'all'
						},
						type: 'shadow',
						label: {
							backgroundColor: '#777'
						}
					},
					series: [{
						name: '',
						type: 'custom',
						z: -1,
						renderItem: (params, api) => {
							const location = api.coord([api.value(0), api.value(1)]);

							const categoryIndex = api.value(0); // 假设 y 轴是类别轴api.value(1),数字就是api.value(0)
							const colors = this_.chartData.maxLeftColor; // 示例颜色数组
							const color = colors[categoryIndex % colors.length]; // 循环使用颜色

							// 返回一个图形元素
							return {
								type: 'LowerLeftObliqueCut',
								x: -this_.width,
								shape: {
									api,
									xValue: api.value(0),
									yValue: api.value(1),
									x: location[0],
									y: location[1],
									xAxisPoint: api.coord([api.value(0), 0]) // 计算坐标点
								},
								style: {
									fill: color
								},
								emphasis: {
									// 高亮时的样式配置  
									style: {
										fill: color, // 与正常状态相同的填充色 
									}
								}

							};
						},
						data: maxData,
					}, {
						name: '',
						type: 'custom',
						z: -1,
						renderItem: (params, api) => {
							const location = api.coord([api.value(0), api.value(1)]);

							const categoryIndex = api.value(0); // 假设 y 轴是类别轴api.value(1),数字就是api.value(0)
							const colors = this_.chartData.maxRightColor; // 示例颜色数组
							const color = colors[categoryIndex % colors.length]; // 循环使用颜色

							// 返回一个图形元素
							return {
								type: 'InclinedRoofColumn',
								shape: {
									api,
									xValue: api.value(0),
									yValue: api.value(1),
									x: location[0],
									y: location[1],
									xAxisPoint: api.coord([api.value(0), 0]) // 计算坐标点
								},
								style: {
									fill: color,
								},
								emphasis: {
									// 高亮时的样式配置  
									style: {
										fill: color, // 与正常状态相同的填充色 
									}
								}
							};
						},
						data: maxData,
					}, {
						name: '',
						type: 'custom',
						renderItem: (params, api) => {
							const location = api.coord([api.value(0), api.value(1)]);

							const categoryIndex = api.value(0); // 假设 y 轴是类别轴api.value(1),数字就是api.value(0)
							const colors = this_.chartData.leftColor; // 示例颜色数组
							const color = colors[categoryIndex % colors.length]; // 循环使用颜色

							// 返回一个图形元素
							return {
								type: 'LowerLeftObliqueCut',
								x: -this_.width,
								shape: {
									api,
									xValue: api.value(0),
									yValue: api.value(1),
									x: location[0],
									y: location[1],
									xAxisPoint: api.coord([api.value(0), 0]) // 计算坐标点
								},
								style: {
									fill: color
								},
								emphasis: {
									// 高亮时的样式配置  
									style: {
										fill: color, // 与正常状态相同的填充色 
									}
								}

							};
						},
						data: statisticsplatformData[1],
					}, {
						name: '',
						type: 'custom',
						barGap: 0,
						renderItem: (params, api) => {
							const location = api.coord([api.value(0), api.value(1)]);

							const categoryIndex = api.value(0); // 假设 y 轴是类别轴api.value(1),数字就是api.value(0)
							const colors = this_.chartData.rightColor; // 示例颜色数组
							const color = colors[categoryIndex % colors.length]; // 循环使用颜色

							// 返回一个图形元素
							return {
								type: 'InclinedRoofColumn',
								shape: {
									api,
									xValue: api.value(0),
									yValue: api.value(1),
									x: location[0],
									y: location[1],
									xAxisPoint: api.coord([api.value(0), 0]) // 计算坐标点
								},
								style: {
									fill: color,
								},
								emphasis: {
									// 高亮时的样式配置  
									style: {
										fill: color, // 与正常状态相同的填充色 
									}
								}
							};
						},
						data: statisticsplatformData[1],
					}, {
						name: '',
						tooltip: {
							show: false
						},
						type: 'pictorialBar',
						emphasis: {
							disabled: true
						},
						itemStyle: { //顶部
							color(params) {
								var colorList = this_.chartData.topColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						symbol: 'diamond',
						symbolSize: [this_.width * 2, '16'],
						symbolOffset: [0, '-8'],
						symbolPosition: 'end',
						data: statisticsplatformData[1],
						z: 3
					}, {
						name: '',
						tooltip: {
							show: false
						},
						type: 'pictorialBar',
						emphasis: {
							disabled: true
						},
						label: {
							show: true,
							position:'top',
							fontSize: 16,
							color: '#282828',
							formatter: function(params, index) {
								return this_.method.formateNum(statisticsplatformData[1][params.dataIndex], 2);
							}
						},
						itemStyle: { //顶部
							color(params) {
								var colorList = this_.chartData.maxTopColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						symbol: 'diamond',
						symbolSize: [this_.width * 2, '16'],
						symbolOffset: [0, '-8'],
						symbolPosition: 'end',
						data: maxData,
						z: 2
					}]
				}

				var myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (myChart == undefined) {
					myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);

			}


		}


	}
</script>

<style>
</style>