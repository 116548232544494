<template>
	<div>
		<ul class="valuationTopNav">
			<li v-for="(item,index) in list" :class="[slideIndex === index?'active':'']">
				<p @click="switchNav(index,item.url)">{{item.name}}</p>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				slideIndex: 0,
				list: [{
					name: '基本信息',
					url: '/examineInformation'
				}, {
					name: '软性指标',
					url: '/examineSoftInformation'
				}]

			}
		},
		mounted() {
			if (this.$route.query.auditStatus != '1') {
				this.list.unshift({
					name: '审核状态',
					url: '/examineAuditStatus'
				})
			}
			
		this.getCurrentPage()
			
		},
		methods: {
			switchNav(i, url) {
				this.slideIndex = i
				this.$router.push({
					path: url,
					query: {
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
						auditStatus: this.$route.query.auditStatus
					}
				})
			},
			getCurrentPage(){
				for(var i=0;i<this.list.length;i++){
					if(this.$route.path==this.list[i].url){
						this.slideIndex=i
						break
					}
				}
			}
			
			
		}

	}
</script>

<style scoped lang="less">
	.valuationTopNav {
		li {
			margin-bottom: 36px;

			p {
				width: 127px;
				height: 33px;
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				color: #fff;
				cursor: pointer;
				position: relative;
			}

			p::after {
				content: '';
				display: block;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid #fff;
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -9px;
			}

		}

		li.active p {
			background: url(../../assets/img/examine/titleBG.png) center center no-repeat;
			text-shadow: 0px 1px 9px #29FFFC;
			color: #29FFFC;
		}

		li.active p::after {
			border-left: 5px solid #29FFFC;
		}

	}
</style>