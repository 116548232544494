// 配置全局接口域名
const domain = 'https://www.flcccc.com/';
const ajaxUrl='flow/';


const valuationProject = 'flow/data/'; //估值项目


// const valuationProject = 'http://192.168.12.95:8089/';

// const valuationProject = ''; //估值项目
// const ajaxUrl='';


export default {
	testUrl: domain,
	valuationProject: valuationProject,
	ajaxUrl:ajaxUrl
}
