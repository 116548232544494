<template>
	<div class="footer">©{{year}}心流网 京ICP备18036155号-2&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证：京B2-20182113&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;京公网安备 11010502037130号</div>
</template>

<script>
	import API_CONFIG from "../../../vue.config.js"
	export default {
		data() {
			return {
				year: '',
				flowUrl:''
			}
		},
		mounted() {
			var nowYear = new Date();
			this.year = nowYear.getFullYear();
			this.flowUrl = API_CONFIG.devServer.proxy['/api'].target;
		}
	}
</script>

<style scoped>
	.footer{
		width:100%;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		position: fixed;
		bottom: 15px;
	}
</style>