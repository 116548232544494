import { render, staticRenderFns } from "./confidentiality.vue?vue&type=template&id=458b1a91&scoped=true"
import script from "./confidentiality.vue?vue&type=script&lang=js"
export * from "./confidentiality.vue?vue&type=script&lang=js"
import style0 from "./confidentiality.vue?vue&type=style&index=0&id=458b1a91&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "458b1a91",
  null
  
)

export default component.exports