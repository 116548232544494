<template>
	<div>
		<p class="unit">单位：元</p>

		<div class="yearLimit">
			<label for="netWorth" style="width: 268px;font-weight: bold;font-size: 16px;">
				无形资产账面净值
				<i style="font-size: 12px;">({{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}})</i>
				<span class="reRed">*</span>
			</label>
			<div class="baseRight">
				<input type="number" id="netWorth" autocomplete="off" v-model="netWorth"
					@blur="displayThousandths($event,netWorth,'netWorth')" @wheel="method.disableWheel($event)">
				<span class="showInput" @click="inputFocus($event)">
					{{netWorth!=''?this.method.formateNum(netWorth,2):''}}
				</span>
				<span class="measure" style="right: 27px;">元</span>
			</div>
		</div>


		<ul class="forms" v-for="(item,index) in singlenessBusiness">
			<img v-if="index!=0" class="close" :src="require('@/assets/img/finance/close.png')" alt="关闭按钮"
				@click="delBusiness(index)" />
			<i></i>
			<li>
				<div class="rests businessName">
					<label for="businessName">业务名称<span class="reRed">*</span></label>
					<div class="baseRight">
						<input type="text" id="businessName" autocomplete="off" placeholder="请输入业务名称" v-model="item.businessName"
							@blur="resourceName">
					</div>
				</div>
			</li>
			<li>
				<div>项数</div>
				<div>
					<i>数据资源名称 </i>
					<i class="reRed">*</i>
				</div>
				<div>账面原值</div>
				<div>
					账面净值
					<span class="reRed">*</span>
					<span
						style="font-size: 12px;">({{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}})</span>
				</div>
				<div>操作</div>
			</li>
			<li v-for="(resource,i) in item.dataResources">
				<div>{{i+1}}</div>
				<div>
					<input type="text" v-model="resource.name" @blur="resourceName" />
				</div>
				<div>
					<input type="number" placeholder="原值" autocomplete="off" v-model="resource.originalValue"
						@focus="focus($event)" @blur="displayThousandths($event,resource.originalValue,'originalValue',index,i)" />
					<span class="showInput" @click="inputFocus($event)">
						{{resource.originalValue!=''?method.formateNum(resource.originalValue,2):''}}
					</span>
				</div>
				<div>
					<input type="number" placeholder="净值" autocomplete="off" v-model="resource.netValue" @focus="focus($event)"
						@blur="displayThousandths($event,resource.netValue,'netValue',index,i)" />
					<span class="showInput" @click="inputFocus($event)">
						{{resource.netValue!=''?method.formateNum(resource.netValue,2):''}}
					</span>
				</div>
				<div>
					<img v-if="i!=0" :src="require('@/assets/img/finance/del.png')" alt="删除" style="cursor: pointer;width: 16px;"
						@click="del(index,i)" />
				</div>
			</li>


			<li style="padding: 10px 0;">
				<p class="addResource" @click="addResource(index)">+ 添加一项数据资源</p>
			</li>

			<li>
				<div class="yearLimit rests">
					<label for="totalYear">数据资源预计未来经济寿命年限<span class="reRed">*</span></label>
					<div class="baseRight">
						<input type="number" id="totalYear" autocomplete="off" v-model="item.serviceLife"
							@blur="changeYear(item.serviceLife)" @wheel="method.disableWheel($event)">
						<span class="measure">年</span>
					</div>
					<p v-text="'（2年≤年限≤10年）'"></p>
				</div>
			</li>

			<i></i>
		</ul>

		<p class="addBusinessType" @click="addBusinessType">+ 添加业务类型</p>

		<ul class="forms">
			<i></i>
			<li>
				<div>合计</div>
				<div v-for="(sum,i) in productSum">
					<input type="number" disabled v-if="i!=0" />
					<span class="showInput" v-if="i!=0">
						{{sum.value!=''?method.formateNum(sum.value,2):''}}
					</span>
				</div>
			</li>
			<i></i>
		</ul>


		<Modal v-model="model" class-name="vertical-center-modal" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' src="../../../assets/img/fillin/unsave.png">
			<p>确定是否删除？</p>
		</Modal>

	</div>
</template>

<script>
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'



	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			jumpAddress: String //保存之后的跳转地址

		},
		data() {
			return {
				year: new Date().getFullYear(),
				model: false,
				createTime: '',
				netWorth: '', //无形资产
				iaBookValue: '', //会计政策统计表中无形资产账面净值总计
				singlenessBusiness: [{
					seq: 1,
					valuationReportId: "",
					businessName: "", //业务线名称
					serviceLife: "", //年限
					dataResources: [{
						name: '',
						originalValue: '', //原值
						netValue: '' //净值
					}]
				}],
				productSum: [{
					value: ''
				}, {
					value: ''
				}, {
					value: ''
				}],
				isSave: false,
				businessIdx: 0,
				resourcesIdx: 0,
				resourceType: 1, // 0：单一  1：多种
				delName: '',
				hint: false


			}
		},
		mounted() {
			this.getDataResources()
			this.pageModify('false');
		},
		methods: {
			...mapMutations(['pageModify']),
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")
			},
			inputFocus(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'inline-block';
				e.currentTarget.previousSibling.focus();
			},
			resourceName() {
				this.isSave = false
			},
			changeYear(value) {
				this.isSave = false
				if (value > 10 || value < 2) {
					this.showDialogBar(true, 'notEmpty', '年限不正确')
				}

			},
			change() {
				this.pageModify('true');
			},
			summation() { //计算合计
				var originalValue = 0 //原值总和
				var netValue = 0 //净值总和

				this.singlenessBusiness.map((el, s) => {
					el.dataResources.map((item, k) => {
						originalValue += Number(item.originalValue)
						netValue += Number(item.netValue)
					})
				})

				this.productSum[1].value = originalValue
				this.productSum[2].value = netValue
			},
			displayThousandths: function(e, val, key, i, j) {
				this.isSave = false
				e.currentTarget.setAttribute("placeholder", "请输入")
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (val.length < 20) {
					console.log('符合')

				} else {
					console.log('走l')

					this.singlenessBusiness[i].dataResources[j][key] = ''

					this.$Message.error({
						background: true,
						content: '请输入合理数据'
					});
					return false;
				}

				this.summation()

				e.currentTarget.nextSibling.style.display = 'inline-block';

				//调用缓存共用方法
				// if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0')) {
				// 	this.method.caching("financialBookValue", JSON.stringify(this.data), this.$route.query.type, '', this)
				// }
			},
			addResource(i) {
				this.isSave = false
				this.singlenessBusiness[i].dataResources.push({
					name: '',
					originalValue: '', //原值
					netValue: '' //净值
				})
			},
			addBusinessType() {
				this.isSave = false
				this.singlenessBusiness.push({
					valuationReportId: "",
					businessName: "", //业务线名称
					serviceLife: "", //年限
					dataResources: [{
						name: '',
						originalValue: '', //原值
						netValue: '' //净值
					}]
				})
			},
			del(i, j) {
				this.model = true
				this.businessIdx = i
				this.resourcesIdx = j
				this.delName = 'resources'
			},
			delBusiness(i) {
				this.model = true
				this.businessIdx = i
				this.delName = 'business'
			},
			submitDialog() {
				this.isSave = false
				if (this.delName == 'resources') {
					this.singlenessBusiness[this.businessIdx].dataResources.splice(this.resourcesIdx, 1);
				} else {
					this.singlenessBusiness.splice(this.businessIdx, 1)
				}

				this.summation()
			},
			getDataResources() {
				this.$post(this.url + 'dataResources/getDataResources', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						resourceType: this.resourceType
					}))
					.then(res => {
						this.netWorth = res.data.content.netWorth
						this.iaBookValue = res.data.content.iaBookValue ? res.data.content.iaBookValue : ''
						this.createTime = res.data.content.createTime


						if (res.data.content.multiple) {

							this.singlenessBusiness = []
							res.data.content.multiple.map(el => {
								if (el.businessName == 'productSum') {
									this.productSum = JSON.parse(el.dataResources)
								} else {
									el.dataResources = JSON.parse(el.dataResources)
									this.singlenessBusiness.push(el)
								}
							})

						}


					})

			},
			save() {
				var this_ = this
				var shouldBreak = false; // 标志变量  

				for (var i = 0; i < this.singlenessBusiness.length && !shouldBreak; i++) {
					this.singlenessBusiness[i].seq = i + 1
					for (var j = 0; j < this.singlenessBusiness[i].dataResources.length && !shouldBreak; j++) {
						if (!this.singlenessBusiness[i].businessName) {
							this.showDialogBar(true, 'notEmpty', '请输入业务名称');
							shouldBreak = true; // 设置标志变量为true，以跳出循环  
							break; // 跳出内层循环  
						} else if (!this.singlenessBusiness[i].dataResources[j].name) {
							this.showDialogBar(true, 'notEmpty', '请输入数据资源名称');
							shouldBreak = true; // 设置标志变量为true，以跳出循环  
							break; // 跳出内层循环  
						} else if (!this.singlenessBusiness[i].dataResources[j].netValue) {
							this.showDialogBar(true, 'notEmpty', '账面净值为必填项');
							shouldBreak = true;
							break;
						} else if (this.singlenessBusiness[i].serviceLife > 10 || this.singlenessBusiness[i].serviceLife < 2) {
							this.showDialogBar(true, 'notEmpty', '年限不正确');
							shouldBreak = true;
							break;
						}
					}

					if (shouldBreak) {
						break; // 如果需要，这里可以再加一个break来确保跳出外层循环  
					}
				}

				// 这里的代码会在满足条件并跳出循环后执行  
				if (shouldBreak) {
					return false
				}

				if (this.productSum[2].value <= 0) {
					this.showDialogBar(true, 'notEmpty-info', '账面净值为必填项');
					return false
				}

				if (!this.netWorth) {
					this.showDialogBar(true, 'notEmpty-info', '无形资产账面净值为必填项');
					return false
				}

				if (this.productSum[1].value && this.productSum[2].value > this.productSum[1].value) {
					this.showDialogBar(true, 'notEmpty-info', '请核实账面净值的合计应与账面原值的合计一致');
					return false
				}
				
				if (this.productSum[2].value > this.netWorth) {
					this.hint = true
					this.showDialogBar(true, 'notEmpty-info', '请核实账面净值的合计应与无形资产账面净值一致');
					return false
				}

				if (this.iaBookValue > this.netWorth) {
					this.hint = true
					this.showDialogBar(true, 'notEmpty-info', '请核实无形资产账面净值应与会计政策统计表中无形资产账面净值统计表的总计一致');
					return false
				}
				

				console.log('可以保存----', this.singlenessBusiness)


				this.saveInterface()


			},
			saveInterface() {
				var this_ = this
				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'dataResources/saveDataResources', qs.stringify({
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							dataResources: JSON.stringify(this.singlenessBusiness),
							productSum: JSON.stringify(this.productSum),
							netWorth: this.netWorth,
							reportType: this.type,
							resourceType: this.resourceType
						}))
						.then(res => {
							setTimeout(function() {
								this_.$router.push({
									name: this_.jumpAddress,
									query: {
										reportId: res.data.content
									}
								})
							}, 500)

						})
				}
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
				if (this.hint) {
					this.saveInterface()
				}
			}


		}

	}
</script>

<style scoped lang="less">
	.forms {
		margin-bottom: 25px !important;

		.close {
			width: 26px;
			height: 26px;
			position: absolute;
			top: -10px;
			right: -10px;
			z-index: 3;
			cursor: pointer;
		}

		li:nth-of-type(1),
		li:nth-of-type(2) {
			font-size: 16px;
			font-weight: bold;

			i {
				font-weight: bold;
			}
		}

		>li:not(:nth-of-type(1)) {
			border-top: 1px solid #29FFFC;
		}

		>li:not(:nth-of-type(1))::before {
			content: '';
			display: block;
			width: 10px;
			height: 9px;
			background: url(../../../assets/img/contribution/cornerMark.png) center center no-repeat;
			background-size: contain;
			position: absolute;
			top: -1px;
			left: 0;
		}

		li {
			font-size: 14px;
			text-align: center;
			display: flex;
			align-items: center;
			position: relative;


			>div:nth-of-type(1):not(.rests) {
				width: 10%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			>div:nth-of-type(2) {
				width: 30%;
			}

			>div:nth-of-type(3) {
				width: 25%;
			}

			>div:nth-of-type(4) {
				width: 25%;
			}

			>div:nth-of-type(5) {
				width: 10%;
			}

			>div {
				position: relative;
				padding: 15px 0;
				color: #fff;
			}

			>div::after {
				content: '';
				display: block;
				width: 2px;
				height: 25px;
				background: #29FFFC;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			>div:last-child::after {
				background: transparent;
			}

			input {
				width: 76%;
				height: 30px;
				background: #1237C6;
				border: 1px solid #29FFFC;
				font-size: 14px;
				color: #fff;
				padding-right: 6px;
			}

			input[type="text"] {
				padding-left: 6px;
			}

			input[type="number"] {
				text-align: right;
			}

			input:focus {
				border-color: #fff;
			}

			input:disabled {
				background: #4B9BFF;
				border-color: #4B9BFF;
			}

			.showInput {
				width: 71%;
				height: 27px;
				background: #1237c6;
				line-height: 28px;
				font-size: 14px;
				padding: 0 2px;
				color: #fff;
				text-align: right;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				overflow: hidden;
			}

			input:disabled+.showInput {
				display: block;
				background: #4B9BFF;
				border-color: #4B9BFF;
			}

			.addResource {
				width: 480px;
				height: 36px;
				line-height: 36px;
				border-radius: 6px;
				border: 1px solid #19ECFF;
				font-size: 14px;
				color: #29FFFC;
				text-align: center;
				margin: 20px auto;
				cursor: pointer;
			}

			.addResource:hover {
				border-color: #00d5d2;
				color: #00d5d2;
			}

			.rests {
				padding-left: 3% !important;
			}

			.businessName {
				display: flex;
				align-items: center;
				padding-top: 25px;
				padding-bottom: 25px;

				label {
					font-size: 16px;
					font-weight: bold;
				}

				input {
					width: 340px;
				}
			}



		}
	}

	.addBusinessType {
		width: 480px;
		height: 36px;
		line-height: 36px;
		background: #29FFFC;
		border-radius: 6px;
		font-size: 14px;
		color: #1237C6;
		text-align: center;
		margin: 25px auto;
		cursor: pointer;
	}

	.addBusinessType:hover {
		background: #00d5d2;
	}

	.yearLimit {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 25px 0;

		>p {
			font-size: 14px;
			color: #fff;
		}

		label {
			width: 226px;
			font-size: 14px;
			color: #fff;
		}

		input,
		.baseRight,
		.showInput {
			width: 242px;
		}

		input {
			width: 76%;
			height: 30px;
			background: #1237C6;
			border: 1px solid #29FFFC;
			font-size: 14px;
			color: #fff;
			padding: 0 26px 0 10px;
		}

		input:focus {
			border-color: #fff;
		}

		.showInput {
			width: 74%;
			height: 27px;
			line-height: 28px;
			background: #1237C6;
			padding: 0 6px;
			color: #fff;
			font-size: 14px;
			position: absolute;
			top: 2px;
			left: 2px;
			overflow: hidden;
		}

		.measure {
			width: 25px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 0;
			color: #fff;
			font-size: 14px;
		}

		.baseRight {
			height: 30px;
			position: relative;
		}
	}
</style>