<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'
	import {
		parse
	} from 'qs';

	export default ({
		props: [
			'chartData', 'id'
		],
		data() {
			return {
				myChart: null,
				dynamicMax: 0,
				grid: {
					top: "10%",
					left: "5%",
					bottom: "10%",
					right: "5%",
					containLabel: true
				},
				newCharts: [],
				color1: ['#8A8BFA', '#548CFF', '#45FFCA', '#FFD36E'],
				color: ['#0003FF', '#7437FF', '#1459F4', '#0E44D1', '#4D4FF8', '#00A4FC', '#4081FE', '#7172FB', '#69A0FD',
					'#37DBFF', '#3FC6FD', '#69EFFF', '#B9D8FF', '#86FBFF', '#A0A1FD', '#93BFFE', '#98EEFE', '#AFFFFB',
					'#CFD0FE', '#65DCFE'
				],
				bottomSymbo: 'image://' + require('@/assets/img/reportCharts/columnar1.png'),
				images: [{
						path: 'image://' + require('@/assets/img/reportCharts/columnar1.png')
					}, {
						path: 'image://' + require('@/assets/img/reportCharts/columnar2.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar3.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar4.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar5.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar6.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar7.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar8.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar9.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar10.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar11.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar12.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar13.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar14.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar15.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar16.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar17.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar18.png')
					},
					{
						path: 'image://' + require('@/assets/img/reportCharts/columnar19.png')
					}, {
						path: 'image://' + require('@/assets/img/reportCharts/columnar20.png')
					}
				],
				newImg: []


			}
		},

		mounted() {
			var this_ = this;
			if (this.chartData) {
				this.newImg = this.generateColors(this.chartData.data.length, this.images)
				this_.initChart()
			}


		},
		methods: {
			generateColors(dataCount, initColor) {
				let colors = []; // 初始化一个空数组来存储颜色  

				// 循环添加颜色，直到达到所需的数据量  
				for (let i = 0; i < dataCount; i++) {
					// 使用模运算符来确保索引在initColor数组的长度范围内  
					let index = i % initColor.length;
					colors.push(initColor[index]);
				}

				// 返回处理后的颜色数组  
				return colors;
			},
			generateColors2(dataCount, initColor) {
				let colors = []; // 初始化一个空数组来存储颜色  

				// 循环添加颜色，直到达到所需的数据量  
				for (let i = 0; i < dataCount; i++) {
					// 使用模运算符来确保索引在initColor数组的长度范围内  
					let index = i % initColor.length;
					console.log(i % initColor.length)
					// if(index==0){
					// 	colors.push({
					// 		path:this.bottomSymbo
					// 	});
					// }else{
					// 	colors.push(initColor[index-1]);
					// }

				}

				// 返回处理后的颜色数组  
				return colors;
			},
			initChart() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id)
			},
			setOptions(id) {
				var this_ = this;
				var yData = this.chartData.data
				var barWidth = 50;
				

				var chartsData = JSON.stringify(this.chartData.data)
				var chartsSum = yData.length == 1 ? yData : this_.calculateCumulativeSums(JSON.parse(chartsData))


				var legend = []
				var series = [{ //设置柱状堆叠的最下面的椭圆
					type: "pictorialBar",
					symbol: this.bottomSymbo,
					symbolPosition: 'start',
					symbolSize: [barWidth, 6],
					symbolOffset: [0, 6],
					data: chartsSum[0].value,
					z: 22,
				}]



				console.log('jjjjjjjj----', chartsSum)

				yData.map((el, i) => {
					legend.push(el.name)
					series.push({
						name: el.name,
						type: 'bar',
						showBackground: true,
						stack: '总量',
						backgroundStyle: {
							color: 'transparent', // 设置背景图片
						},
						emphasis: {
							disabled: true,
							focus: 'none'
						},
						barWidth: barWidth,
						barGap: '10%', // Make series be overlap
						barCateGoryGap: '10%',
						itemStyle: {
							color: (yData.length == 1 || i == 0) ? '#0003FF' : i == yData.length - 1 ? '#65DCFE' : this
								.generateColors(
									yData.length, this.color)[i],
						},
						data: el.value,
						z: 10
					})

					if (yData.length == 1) {
						series.push({
							type: "pictorialBar",
							symbol: 'image://' + require('@/assets/img/reportCharts/oneTop.png'),
							symbolPosition: 'end',
							symbolSize: [barWidth, 12],
							symbolOffset: [0, -6],
							data: chartsSum[i].value,
							z: 22,
						})
					} else {
						if (i == yData.length - 1) { //设置柱状堆叠的最上面柱子顶部的椭圆
							series.push({
								type: "pictorialBar",
								symbol: 'image://' + require('@/assets/img/reportCharts/multipleTops.png'),
								symbolPosition: 'end',
								symbolSize: [barWidth, 12],
								symbolOffset: [0, -6],
								data: chartsSum[i].value,
								z: 22,
							})
						} else if (i == yData.length - 2) { //设置柱状堆叠的最上面柱子底部部的椭圆
							series.push({
								type: "pictorialBar",
								symbol: 'image://' + require('@/assets/img/reportCharts/multipleBottom.png'),
								symbolPosition: 'end',
								symbolSize: [barWidth, 6],
								symbolOffset: [0, -1],
								data: chartsSum[i].value,
								z: 22,
							})
						} else {
							series.push({
								type: "pictorialBar",
								symbol: this.generateColors(this.chartData.data.length, this.images)[i + 1].path,
								symbolPosition: 'end',
								symbolSize: [barWidth, 6],
								symbolOffset: [0, -1],
								data: chartsSum[i].value,
								z: 22
							})
						}
					}



				})

				console.log('series--', series)

				var option = {
					tooltip: {
						show: false,
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'none', // 默认为直线，可选为：'line' | 'shadow'
						},
						formatter(params) {
							// 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
							// console.log(params)
							return params.reduce((pre, i) => {
								if (i.componentSubType === 'bar') {
									i.marker = i.marker.replace(/\[object Object\]/, i.color);
									i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								return pre;
							}, '');
						},
					},
					grid: this_.grid,
					animation: false,
					legend: {
						show: false,
						data: legend,
						bottom: 0,
						textStyle: {
							fontSize: 14
						}
					},
					xAxis: [{
						type: "category",
						data: this.chartData.year,
						axisTick: {
							alignWithLabel: true
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1416F4"
							}
						},
						axisLabel: {
							textStyle: {
								color: "#fff"
							},
							margin: 20
						}
					}],
					yAxis: {
						type: "value",
						name:'万元',
						nameTextStyle:{
							color: "#282828",
							fontSize: 16
						},
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1416F4"
							}
						},
						axisLabel: {
							textStyle: {
								color: "#282828",
								fontSize: 14
							},
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: series
				};

				this.myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (this.myChart == undefined) {
					this.myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				this.myChart.setOption(option);

				// 计算一个比最大值稍大一点的值，可以使用一些系数来控制
				this.dynamicMax = this_.myChart.getModel().getComponent("yAxis", 0).axis.scale._extent[1] + this_.myChart
					.getModel().getComponent("yAxis", 0).axis.scale._interval; // 1 是一个系数，你可以根据需要调整


				// console.log('输出最大值是：', this.dynamicMax, this_.myChart.getModel().getComponent("yAxis", 0).axis)




			},
			symbolPosition(data) {
				var this_ = this;
				var arr = [];

				console.log('ll---', this.dynamicMax)

				this.chartData.data.forEach(function(ele, i) {
					arr[i] = {
						// value: this_.myChart.getModel().getComponent("yAxis", 0).axis.scale._extent[1]+this_.myChart.getModel().getComponent("yAxis", 0).axis.scale._niceExtent[1],
						value: this_.dynamicMax,
						symbolOffset: [0, -6],
						symbolPosition: 'end'
					}
				});
				return arr
			},
			calculateCumulativeSums(arr) {
				// 初始化累加数组，与数组长度一致，初始值为0    
				let cumulativeSums = Array(arr[0].value.length).fill(0);

				// 遍历数组中的每个对象    
				for (let i = 0; i < arr.length; i++) {
					const {
						value
					} = arr[i];
					// 创建一个当前累加和的副本，用于更新当前对象的value  
					const currentSums = cumulativeSums.slice();

					// 遍历value数组的每个元素    
					for (let j = 0; j < value.length; j++) {
						// 确保当前值不是空字符串，如果是，则忽略    
						if (typeof value[j] === 'string' || value[j] === '') {
							continue;
						}
						// 累加当前值和累加数组中对应索引的值    
						currentSums[j] += value[j];
					}

					// 使用当前累加和的副本更新当前对象的value  
					arr[i].value = currentSums;
					// console.log('oooo----', currentSums)

					// 更新累加数组以包含当前对象的值，用于下一个对象的累加  
					cumulativeSums = currentSums;
				}
				return arr; // 返回更新后的数组    
			},
			findArryMax() {
				let mergedValues = [];
				var aa = JSON.stringify(this.chartData.data)
				var chartsSum = this.calculateCumulativeSums(JSON.parse(aa))
				// 遍历原始数组，将每个对象的value数组合并到mergedValues中  
				chartsSum.forEach(item => {
					mergedValues = mergedValues.concat(item.value);
				});
				// 过滤掉非数字的值  
				mergedValues = mergedValues.filter(value => typeof value === 'number' && !isNaN(value));

				// 找到合并后的数组中的最大值  
				// this.dynamicMax = Math.max(...mergedValues);
				// console.log('输出最大值---------', mergedValues, this.dynamicMax); // 输出最大值

			}



		}

	})
</script>

<style>
</style>