<template>
	<div>
		<!-- <header data-v-4c171652="" class="" v-if="channel=='1902'">
			<div data-v-4c171652="" class="main-layout">
				<div data-v-4c171652="" class="platform-logo">
					<a data-v-4c171652="" href="https://www.chinahtz.com">
						<img data-v-4c171652="" src="https://www.chinahtz.com/static/img/header_logo.77f445b1.png"
							alt="" class="img-logo">
					</a>
				</div>
			</div>
		</header> -->
		<flowHeader />
		<router-view></router-view>
	</div>
</template>

<script>
	import '../assets/css/common.css';
	import '../assets/css/myAlert.css';
	import flowHeader from '../common/header/flow_header.vue';
	// import '../assets/css/V-next.css';


	export default {
		data() {
			return {

			}
		},
		components: {
			flowHeader
		},
		mounted() {
			
		},
		methods: {

		}
	}
</script>
<style>

</style>
