<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default ({
		props: [
			'chartData', 'id'
		],
		data() {
			return {
				myChart: null,
				dynamicMax: 0,
				grid: {
					top: "10%",
					left: "2%",
					bottom: "3%",
					right: "0",
					containLabel: true
				}
			}
		},

		mounted() {
			var this_ = this;

			this_.initChart()

		},
		methods: {
			initChart() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id)
			},
			setOptions(id) {
				var this_ = this;
				var yData = this.chartData.data
				var barWidth = 60;

				var maxData = []
				yData.map(item => {
					maxData.push(100)
				})

				var option = {
					grid: this_.grid,
					animation: false,
					xAxis: [{
						type: "category",
						data: this.chartData.year,
						axisTick: {
							alignWithLabel: true
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							color: "#282828",
							fontSize: 16,
							margin: 20
						}
					}],
					yAxis: {
						type: "value",
						max: 100,
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1459F4"
							}
						},
						axisLabel: {
							fontSize: 16,
							color: "#282828"
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
						name: '',
						type: 'pictorialBar',
						symbolSize: [barWidth, 13],
						symbolOffset: [0, -6],
						symbolPosition: 'end',
						z: 12,
						label: {
							show: true,
							position: "top",
							fontSize: 16,
							color: '#282828',
							formatter: function(params, index) {
								return this_.method.formateNum(yData[params.dataIndex], 2);
							}
						},
						itemStyle: {
							color(params) {
								var colorList = this_.chartData.maxColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							},
						},
						emphasis: { //取消鼠标移上去的高亮效果
							disabled: true
						},
						data: maxData
					}, {
						name: "数据上椭圆",
						type: 'pictorialBar',
						symbolSize: [barWidth, 13],
						symbolOffset: [0, -6],
						symbolPosition: 'end',
						z: 12,
						itemStyle: {
							color(params) {
								var colorList = this_.chartData.topColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: yData
					}, {
						name: '下椭圆',
						type: 'pictorialBar',
						symbolSize: [barWidth, 13],
						symbolOffset: [0, 6],
						z: 12,
						itemStyle: {
							color(params) {
								var colorList = this_.chartData.bottomColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: yData
					}, {
						type: 'bar',
						barWidth: barWidth,
						z: '-1',
						barGap: '-100%', // barGap值为-100%时可以使柱子重叠
						itemStyle: {
							color(params) {
								var colorList = this_.chartData.backgroundColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: maxData
					}, {
						type: 'bar',
						barWidth: barWidth,
						itemStyle: {
							color(params) {
								var colorList = this_.chartData.dataColor
								var index = params.dataIndex;
								return params.color = colorList[index]
							}
						},
						emphasis: {
							disabled: true
						},
						data: yData
					}]
				};

				this.myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (this.myChart == undefined) {
					this.myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				this.myChart.setOption(option);


			},
			symbolPosition() {
				var this_ = this;
				var arr = [];

				this.chartData.data.forEach(function(ele, i) {
					arr[i] = {
						// value: this_.myChart.getModel().getComponent("yAxis", 0).axis.scale._extent[1],
						value: this_.dynamicMax,
						symbolOffset: [0, -6],
						symbolPosition: 'end'
					}
				});
				return arr
			}

		}

	})
</script>

<style>
</style>