<template>
	<div id="app">
		<div id="logo">
			<header class="back-white">
				<div class="flow-header-container topone">
					<div class="logo">
						<router-link to="/" tag="a"></router-link>
					</div>
					<div class="header-two-right">
						<div class="country-container">
							<div class="china active"> <span class="country-name">中国</span> <span
									class="country-img countryImgActive"></span> </div>
						</div>
						<router-link to="/" tag="a" class="router-link-active">首页</router-link>
					</div>
				</div>
			</header>
			<div>
				<div class="login-main">
					<div class="vertical">
						<div class="login-page">
							<div class="login-main-container">
								<p>账户登录</p>
								<div class="login">
									<input class="loginInput" type="text" placeholder="邮箱/已验证手机号" v-model="loginForm.username"
										ref="input1" @blur="userName" />
									<input class="loginInput" type="password" placeholder="密码" v-model="loginForm.password" />
									<p :class="['hint',loginForm.show?'hide':'']">{{loginForm.hint}}</p>
									<input class="btn loginBtn" type="button" name="" id="loginBtn" @click="logo()" value="登  录" />
									<p>
										<router-link to="/forgetPassword" tag="a" class="forgetP">忘记密码</router-link>
										<router-link to="/register" tag="a" class="reg">立即注册</router-link>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <Button type="primary" @click="info()">显示普通提醒</Button> -->

				<div class="logogFoot">
					<span>
						<span>©2018心流网 <a target="_blank" href="https://beian.miit.gov.cn">
								京ICP备18036155号-2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证:京B2-20182113&nbsp;&nbsp;&nbsp;</a></span>
						<span>
							<a target="_blank"
								href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037130">京公网安备11010502037130号</a>
						</span>
					</span>
				</div>
			</div>

		</div>
		<div id="mobile_logo">
			<img src="../../assets/img/logo-08.svg">
			<div class="login-main-container">
				<p>账户登录</p>
				<div class="login">
					<input class="loginInput" type="text" placeholder="请输入您的邮箱/手机号" v-model="loginForm.username" ref="input1"
						@blur="userName" />
					<input class="loginInput" type="password" placeholder="请输入您的密码" v-model="loginForm.password" />
					<p :class="['hint',loginForm.show?'hide':'']">{{loginForm.hint}}</p>
					<input class="btn loginBtn" type="button" name="" id="loginBtn" @click="logo()" value="登  录" />
					<p>
						<router-link to="/forgetPassword" tag="a" class="forgetP">忘记密码</router-link>
						<router-link to="/register" tag="a" class="reg">立即注册</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios';
	export default {
		data() {
			return {
				loginForm: {
					username: '',
					password: '',
					show: true, //true 隐藏提示
					hint: '', //错误信息的提示语
					fromPath: '',
					query: {}
				}
			}
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				// 通过 `vm` 访问组件实例,将值传入fromPath
				vm.fromPath = from.path;
				vm.query = from.query;
				// console.log(from)
			});
		},
		mounted() {
			var __this = this;
			// __this.username = __this.$refs.input1.value;
			// console.log(this.$router.currentRoute.fullPath)
		},
		methods: {
			logo: function(e) {
				let _this = this;
				let phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/; //电话
				let emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/; //邮箱
				let type;
				if (this.loginForm.username == "") {
					this.loginForm.show = false;
					this.loginForm.hint = "请输入账户";
					return false
				} else if (this.loginForm.username.indexOf("@") == -1 && !phoneReg.test(this.loginForm.username)) {
					this.loginForm.show = false;
					this.loginForm.hint = "手机号输入有误";
					return false
				} else if (this.loginForm.username.indexOf("@") != -1 && !emailReg.test(this.loginForm.username)) {
					this.loginForm.show = false;
					this.loginForm.hint = "邮箱格式输入有误";
					return false
				} else if (this.loginForm.password == "") {
					this.loginForm.show = false;
					this.loginForm.hint = "请输入密码";
					return false
				} else {
					this.loginForm.show = true;
				}

				if (this.loginForm.username.indexOf("@") == -1) {
					type = 2;
				} else {
					type = 1;
				}

				this.$post('flow/customer/login', qs.stringify({
						loginName: this.loginForm.username,
						password: this.loginForm.password,
						type: type
					}))
					.then((res) => {
						// console.log(res.data)
						if (res.data.code == 102) {
							this.loginForm.show = false;
							this.loginForm.hint = res.data.message;
						} else if (res.data.code == 103) {
							this.loginForm.show = false;
							this.loginForm.hint = "您尚未注册";
						} else if (res.data.code == 200) {
							localStorage.setItem("userToken", res.data.token);
							localStorage.setItem("userName", this.loginForm.username);
							console.log(this.fromPath)
							if (this.fromPath == '/register' || this.fromPath == '/forgetPassword') {
								this.$router.push({
									path: '/',
									query: this.query
								});
							} else {
								this.$router.push({
									path: this.fromPath,
									query: this.query
								});
							}
						}

					});

			},
			userName: function(e) {
				// console.log(e.target.value)
			},
			info: function() {
				this.$Message.info('这是一条普通的提醒');
			}


		}
	}
</script>

<style scoped>
	::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #BFBFBF;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #BFBFBF;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #BFBFBF;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #BFBFBF;
	}

	#logo {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		background: url(../../assets/img/login/backgroundImage.jpg) no-repeat center center;
		background-size: cover;
	}


	.hide {
		display: none;
	}

	header.back-white {
		height: 80px;
		border: none;
		position: absolute;
		background: rgba(20, 89, 244, 0.06);
	}

	header.back-white .header-two-right>a {
		color: #000;
	}

	.back-f1 {
		background: #F5F7FA;
		color: #fff;
	}

	header {
		height: 72px;
		width: 100%;
		min-width: 1200px;
		background: #F5F7FA;
		border-bottom: 1px solid rgba(255, 255, 255, .2);
	}

	footer {
		height: 399px;
		min-width: 1200px;
		width: 100%;
		background: #F5F7FA;
	}


	.flow-header-container {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
	}

	.flow-header-container>div.logo {
		margin: 10px 25px;
		height: 40px;
		width: 174px;
		float: left;
		margin-left: 0;
		margin-right: 0;
	}


	.topone>div.logo {
		margin-top: 10px !important;
	}

	.topone>div.logo>a {
		height: 100%;
		width: 100%;
	}

	.toptwo>div.logo,
	.topone>div.logo {
		background: url(../../assets/img/report/flowLogo.png) no-repeat;
		margin: 8px 0 0;
		width: 200px;
		height: 60px;
		background-size: 200px;
		cursor: pointer;
	}

	.toptwo>div.logo>a {
		width: 100%;
		height: 100%;
	}

	.flow-header-container>div.header-two-right {
		float: right;
		width: 130px;
		height: 50px;
		line-height: 50px;
		margin: 15px 0;
	}

	.header-two-right>span {
		display: inline-block;
		font-size: 14px;
	}

	.country-container {
		float: left;
		cursor: pointer;
		width: 62px;
		font-size: 0;
	}

	.country-container>div.active span {
		font-size: 14px;
	}

	.country-container span {
		display: none;
	}

	.country-select span {
		display: none;
	}

	.country-container .active span {
		display: inline-block;
	}

	.country-select .active span {
		display: inline-block;
	}


	.header-two-right .country-img,
	.header-one-right .country-img {
		height: 15px;
		width: 24px;
		background: url(../../assets/img/login/@2x.svg) 0% 0% / 12px 15px no-repeat;
		background-size: 12px 15px;
		margin-left: 8px;
	}

	.router-link-active {
		margin-left: 20px;
	}

	.logogFoot {
		width: 100%;
		height: 80px;
		background: #fff;
		font-size: 14px;
		color: #585858;
		text-align: center;
		line-height: 80px;
		position: absolute;
		bottom: 0;
	}

	.logogFoot a {
		color: #585858;
		cursor: pointer;
	}


	.vertical {
		width: 1200px;
		height: 100%;
		max-height: calc(100% - 170px);
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
	}
	
	.login-page {
		width: 406px;
		height: 420px;
		background: #FFFFFF;
		box-shadow: 0px 4px 18px 0px rgba(212, 231, 254, 0.5);
	}
	
	@media screen and (max-height: 800px) {
		.vertical {
			width: 100%;
			overflow-y: scroll;
		}
	
		.register .registerContent,
		.login-page {
			position: relative;
			right: calc((100% - 1150px) / 2);
		}
	}

	.login-main-container>p {
		margin-top: 46px;
		font-size: 22px;
		text-align: center;
		color: #41403F;
		font-weight: bold;
	}

	.login-main-container .login {
		width: 346px;
		margin: 49px auto 0;
	}

	.loginInput {
		width: 100%;
		height: 48px;
		background: #FFFFFF;
		border: 1px solid #BFBFBF;
		display: block;
		padding-left: 23px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}

	.login>input:nth-child(2) {
		margin-top: 20px;
	}

	.loginBtn {
		width: 100%;
		height: 50px;
		background: #1459F4;
		color: #fff;
		cursor: pointer;
		font-size: 16px;
		margin-top: 30px;
		margin-bottom: 60px;
		display: block;
	}

	.login>p {
		width: 86%;
		margin: 0 auto;
	}

	.login>p>a {
		color: #1459F4;
		font-size: 12px;
	}

	.login>p>a.forgetP {
		float: left;
	}

	.login>p>a.reg {
		float: right;
	}

	.hint {
		color: red;
		position: absolute;
		top: 53%;
	}

	#mobile_logo {
		display: none;
	}

	@media screen and (max-width: 750px) {
		#logo {
			display: none;
		}

		#mobile_logo {
			display: block;
		}

		#mobile_logo>img {
			width: 250px;
			margin: 25% auto 50px;
			display: block;
		}

		.login-main-container {
			width: 90%;
			margin: 0 auto;
		}

		.login-main-container .login {
			width: 100%;
			margin: 0;
		}

		.login-main-container>p {
			margin: 0 0 40px 0;
		}

		.loginInput {
			border: 1px solid #ddd;
			font-size: 14PX;
			line-height: 65px;
			height: 65px;
			padding: 7px 10px;
		}

		.hint {
			top: 65%;
		}

		.loginBtn,
		.login>p>a {
			font-size: 14PX;
		}

		#loginBtn {
			line-height: 65px;
			height: 65px;
		}
	}
</style>