<template>
	<div class="">
		<div class="fillNav">
			<ul>
				<li v-for="(item,index) in list" :key="index">
					<div :class="['navItem', slideIndex === index?'active':'']" @click="switchNav(index,item.url)">
						<img v-if="index==1||index==3"
							:src="slideIndex === index?(item.isComplete?require('@/assets/img/fillin/completion.png'):item.selectIcon):(item.isComplete?require('@/assets/img/fillin/selectedCompletion.png'):item.icon)"
							style="margin-right: 20px;" />
						<span>{{item.text}}</span>
						<img v-if="index==0||index==2"
							:src="slideIndex === index?(item.isComplete?require('@/assets/img/fillin/completion.png'):item.selectIcon):(item.isComplete?require('@/assets/img/fillin/selectedCompletion.png'):item.icon)"
							style="margin-left: 20px;" />
					</div>
				</li>
			</ul>
		</div>


		<Modal v-model="myAlert" class-name="vertical-center-modal" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' src="../../assets/img/fillin/unsave.png">
			<p>当前数据尚未保存，是否确定离开？</p>
		</Modal>

	</div>
</template>

<script>
	import qs from 'qs';
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		data() {
			return {
				slideIndex: 0,
				myAlert: false,
				skip: 0,
				list: [{
					text: '基本信息',
					icon: require('@/assets/img/fillin/unselectedLTicon.png'),
					selectIcon: require('@/assets/img/fillin/selectedLT.png'),
					isComplete: false,
					url: '/information'
				}, {
					text: '财务指标',
					icon: require('@/assets/img/fillin/unselectedRTicon.png'),
					selectIcon: require('@/assets/img/fillin/selectedRT.png'),
					isComplete: false,
					url: '/yearForecast'
				}, {
					text: '软性指标',
					icon: require('@/assets/img/fillin/unselectedLBicon.png'),
					selectIcon: require('@/assets/img/fillin/selectedLB.png'),
					isComplete: false,
					url: '/softIndex'
				}, {
					text: '估值报告',
					icon: require('@/assets/img/fillin/unselectedRBicon.png'),
					selectIcon: require('@/assets/img/fillin/selectedRB.png'),
					isComplete: false,
					url: '/payment'
				}]
			}
		},
		computed: {
			...mapState(['currentPageModify', 'currentPageSave', 'checkStatus'])
		},
		created() {
			if (this.$route.query.userToken) {
				localStorage.userToken = this.$route.query.userToken
			}
		},
		mounted() {
			this.pageModify('fasle');
			window.addEventListener('resize', () => {
				this.method.transform()
			})
			this.method.transform()


			if (this.$route.path == '/information') {
				this.slideIndex = 0;
			} else if (this.$route.path == '/softIndex') {
				this.slideIndex = 2;
			} else if (this.$route.path == '/yearForecast' || this.$route.path == '/carryingValue' || this.$route
				.path == '/incomeForecast') {
				this.slideIndex = 1;
			} else if (this.$route.path == '/payment') {
				this.slideIndex = 3;
			}

			this.completionStatus()

		},
		methods: {
			...mapMutations(['pageModify', 'setCheckStatus']),
			switchNav(i, url) {
				console.log(i)
				if (this.currentPageModify == 'true' && this.currentPageSave == false && i != this.slideIndex) {
					this.myAlert = true;
					this.skip = i;
				} else {
					this.slideIndex = i;
					if (this.$route.query.reportId) {
						this.$router.push({
							path: this.list[i].url,
							query: {
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					} else {
						this.$router.push({
							path: this.list[i].url,
							query: {
								type: this.$route.query.type
							}
						})
					}
				}

			},
			submitDialog() {
				this.myAlert = false;
				if (this.$route.query.reportId) {
					this.$router.push({
						path: this.list[this.skip].url,
						query: {
							type: this.$route.query.type,
							reportId: this.$route.query.reportId
						}
					})
				} else {
					this.$router.push({
						path: this.list[this.skip].url,
						query: {
							type: this.$route.query.type
						}
					})
				}
			},
			completionStatus() { //purpose 1：预支付 0：查看数据填写情况
				this.$post(this.domain.valuationProject + 'pay/getToPayDataReportInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						purpose: '0'
					}))
					.then((res) => {
						console.log('成功-----',res.data)
						if (res.data.content.check) {
							var check = JSON.parse(res.data.content.check)
							this.setCheckStatus(check)
							this.list[0].isComplete = check.baseInfoFlag
							if (check.financialForecastFlag && check.dataBookValueFlag && check.dataIncomeFlag) {
								this.list[1].isComplete = true
							}
							
							this.list[2].isComplete = check.questionInfoFlag
							
							if(res.data.content.auditStatus==='0') this.list[3].isComplete=true

						}else{
							this.setCheckStatus('')
						}
					})
			}


		}
	}
</script>

<style scoped lang="less">
	.fillNav {
		width: 96%;
		height: 74%;
		position: absolute;
		left: 50%;
		margin-left: -48%;

		& ul {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			li {
				width: 50%;
				height: 130px;
				position: relative;

				.navItem {
					width: 308px;
					height: 130px;
					line-height: 130px;
					text-align: center;
					position: absolute;
					cursor: pointer;

					img {
						vertical-align: middle;
					}


				}



				.navItem span {
					font-size: 30px;
					font-weight: bold;
					background: #fff;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;

				}

				.navItem.active span {
					background: linear-gradient(180deg, #09F6FE 27%, #0071F8 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}


			}

			li:nth-of-type(1),
			li:nth-of-type(2) {
				top: 155px;
			}

			li:nth-of-type(3),
			li:nth-of-type(4) {
				top: -30px;
			}

			li:nth-of-type(1) .navItem {
				background: url(../../assets/img/fillin/unselectedLTop.png) center center no-repeat;
				left: 19px;
			}

			li:nth-of-type(2) .navItem {
				background: url(../../assets/img/fillin/unselectedRTop.png) center center no-repeat;
				right: 27px;
			}

			li:nth-of-type(3) .navItem {
				background: url(../../assets/img/fillin/unselectedLBottom.png) center center no-repeat;
			}

			li:nth-of-type(4) .navItem {
				background: url(../../assets/img/fillin/unselectedRBottom.png) center center no-repeat;
				right: 8px;
			}

			li:nth-of-type(1) .navItem.active {
				background: url(../../assets/img/fillin/navLeftTop.png) center center no-repeat;
			}

			li:nth-of-type(2) .navItem.active {
				background: url(../../assets/img/fillin/navRightTop.png) center center no-repeat;
			}

			li:nth-of-type(3) .navItem.active {
				background: url(../../assets/img/fillin/navLeftBottom.png) center center no-repeat;
			}

			li:nth-of-type(4) .navItem.active {
				background: url(../../assets/img/fillin/navRightBottom.png) center center no-repeat;
			}




		}


	}
</style>