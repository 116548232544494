<template>
	<div class="resourceMain">
		<div class="newCategory" style="margin-bottom: 20px;">数据资源</div>

		<div class="noInformation" v-if="noInformation">
			<img :src="require('@/assets/img/dataResource/noInformation.png')" />
			<div>
				<span>暂无数据资源信息</span>
				<div id="fillout" @click="fillout">立即添加</div>
			</div>
		</div>

		<div v-else>
			<div class="resourceRditBox resourceFrame" v-if="isShowRedact">
				<i></i>
				<p>数据资源</p>
				<ul>
					<li>
						<label for="companyName">数据资源名称<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="text" id="companyName" class="required" data-value='false' v-model="redact.resourceName"
								@focus="focus($event)" @change="change" autocomplete="off" maxlength="30">
						</div>
					</li>
					<li>
						<div class="originalNetWorth">
							<label>数据资源账面原值</label>
							<div class="baseRight">
								<input type="number" autocomplete="off" v-model="redact.originalValue"
									@blur="displayThousandths($event,redact.originalValue,'originalValue')" @focus="focus($event)"
									@change="change" @wheel="method.disableWheel($event)">
								<div class="showInput" @click="inputFocus($event)">
									{{redact.originalValue?method.formateNum(redact.originalValue,2):''}}
								</div>
								<span class="measure">元</span>
							</div>
						</div>

						<div class="originalNetWorth">
							<label>数据资源账面净值
								<span
									style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</span>
							</label>
							<div class="baseRight">
								<input type="number" autocomplete="off" v-model="redact.netValue"
									@blur="displayThousandths($event,redact.netValue,'netValue')" @focus="focus($event)" @change="change"
									@wheel="method.disableWheel($event)">
								<div class="showInput" @click="inputFocus($event)">
									{{redact.netValue?method.formateNum(redact.netValue,2):''}}
								</div>
								<span class="measure">元</span>
							</div>
						</div>
					</li>
					<li>
						<label>数据资源介绍<span class="reRed">*</span></label>
						<div class="baseRight text-area-container">
							<Input v-model="redact.resDescribe" type="textarea" :maxlength="maxLength" @input="updateCount"
								placeholder="请简要描述数据资源产品/服务情况，包括不限于其功能、特点、使用场景和潜在用途等方面。" />
							<span class="char-count">{{ currentCount }}/{{ maxLength }}</span>
						</div>
					</li>
				</ul>

				<div class="saveBox">
					<span data-isClick="false" @click="cancelRedact('')">取消</span>
					<span data-isClick="false" @click="addSave('')">确定</span>
				</div>

				<i></i>
			</div>

			<div class="resourceList">
				<p class="addBtn" v-if="isShowAdd" @click="addSet">添加</p>
				<ul>
					<li v-for="(item,i) in dataResource">

						<div class="resourceRditBox listEditing resourceFrame" ref="editData">
							<i></i>
							<ul>
								<li>
									<label for="companyName">数据资源名称<span class="reRed">*</span></label>
									<div class="baseRight empty">
										<input type="text" id="companyName" class="required" data-value='false'
											v-model="redact.resourceName" @focus="focus($event)" @change="change" autocomplete="off"
											maxlength="30">
									</div>
								</li>
								<li>
									<div class="originalNetWorth">
										<label>数据资源账面原值</label>
										<div class="baseRight">
											<input type="number" autocomplete="off" v-model="redact.originalValue"
												@blur="displayThousandths($event,redact.originalValue,'originalValue')" @focus="focus($event)"
												@change="change" @wheel="method.disableWheel($event)">
											<div class="showInput" @click="inputFocus($event)">
												{{redact.originalValue?method.formateNum(redact.originalValue,2):''}}
											</div>
											<span class="measure">元</span>
										</div>
									</div>

									<div class="originalNetWorth">
										<label>数据资源账面净值
											<span
												style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</span>
										</label>
										<div class="baseRight">
											<input type="number" autocomplete="off" v-model="redact.netValue"
												@blur="displayThousandths($event,redact.netValue,'netValue')" @focus="focus($event)"
												@change="change" @wheel="method.disableWheel($event)">
											<div class="showInput" @click="inputFocus($event)">
												{{redact.netValue?method.formateNum(redact.netValue,2):''}}
											</div>
											<span class="measure">元</span>
										</div>
									</div>
								</li>
								<li>
									<label>数据资源介绍<span class="reRed">*</span></label>
									<div class="baseRight text-area-container">
										<Input v-model="redact.resDescribe" type="textarea" :maxlength="maxLength" @input="updateCount"
											placeholder="请简要描述数据资源产品/服务情况，包括不限于其功能、特点、使用场景和潜在用途等方面。" />
										<span class="char-count">{{ currentCount }}/{{ maxLength }}</span>
									</div>
								</li>
							</ul>

							<div class="saveBox">
								<span data-isClick="false" @click="cancelRedact(i)">取消</span>
								<span data-isClick="false" @click="addSave(i)">确定</span>
							</div>

							<i></i>
						</div>

						<div class="detailedDisplay resourceFrame">
							<i></i>
							<p class="operate">
								<span @click="edit(i)">编辑</span>
								<span @click="del(i)">删除</span>
							</p>
							<div>
								<span>数据资源名称：</span>
								<span>{{item.resourceName}}</span>
							</div>

							<div>
								<p>
									<span>数据资源账面原值：</span>
									<span>{{item.originalValue?method.formateNum(item.originalValue,2)+'元':''}}</span>
								</p>
								<p>
									<span>数据资源账面净值：
										<i
											style="position: absolute;left: 0;top: 18px;font-size: 12px;">（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）</i>
									</span>
									<span>{{method.formateNum(item.netValue,2)}}元</span>
								</p>
							</div>
							<div>
								<span>数据资源介绍：</span>
								<span>{{item.resDescribe}}</span>
							</div>
							<i></i>
						</div>
					</li>
					<li class="totalNetWorth" v-if="dataResource.length>0">
						<span>数据资源账面净值合计</span>
						<span>{{totalNetWorth?method.formateNum(totalNetWorth,2):''}}</span>
					</li>
				</ul>

				<div class="saveBox" v-if="dataResource.length>0" style="margin: 20px auto 0;">
					<span style="margin-right: 61px;" @click="nextStep">下一步</span>
				</div>
			</div>

		</div>


		<Modal v-model="model" class-name="vertical-center-modal" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' src="../../assets/img/fillin/unsave.png">
			<p>确定是否删除？</p>
		</Modal>


	</div>
</template>

<script>
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	import {
		merge
	} from 'lodash'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			jumpAddress: String //保存之后的跳转地址


		},
		data() {
			return {
				model: false,
				noInformation: true,
				dataResource: [
					// {
					// 	valuationReportId: "",
					// 	resourceName: '',
					// 	originalValue: '', //原值
					// 	netValue: '', //净值
					// 	resDescribe: '' //数据资源介绍
					// }

				],
				netWorth: '', //资产负债表里的无形资产
				redact: {
					valuationReportId: '',
					id: '',
					resourceName: '',
					originalValue: '', //原值
					netValue: '', //净值
					resDescribe: '' //数据资源介绍
				},
				totalNetWorth: '',
				isSave: false,
				maxLength: 200, //介绍最大长度
				currentCount: 0, //介绍当前输入多少字数
				isShowRedact: false, //是否展示编辑框
				isShowAdd: false, //是否展示添加按钮
				hint: false,
				resourceIdx: '',
				createTime: ''


			}
		},
		mounted() {
			this.init()
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),
			change() {
				this.isSave = false
			},
			updateCount() {
				this.isSave = false
				this.currentCount = this.redact.resDescribe.length;
				// 如果需要，可以在这里添加额外的逻辑，如超过最大字数时阻止输入等  
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")
			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			displayThousandths: function(e, val, key, i, j) {
				this.isSave = false
				e.currentTarget.nextSibling.style.display = 'block';
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (val == '') {
					this.$set(this.redact, key, '')
				}

				if (String(val).indexOf(".") != -1) {
					this.$set(this.redact, key, Math.round(Number(val) * 100) / 100) //保留两位
				} else {
					if (val.length > 20) { //判断数据的合理性
						this.$Message.error({
							background: true,
							content: '请输入合理数据'
						});
						this.$set(this.redact, key, '')
					}
				}

			},
			convertData(obj) {
				const newData = JSON.parse(JSON.stringify(obj));;
				return newData
			},
			setTotal() {
				var totalNum = 0;
				for (var k = 0; k < this.dataResource.length; k++) {
					console.log(this.dataResource[k].netValue)
					totalNum += Number(this.dataResource[k].netValue)
					this.totalNetWorth = Number(totalNum)
				}
			},
			shoeHide() {
				if (this.dataResource.length > 0) {
					this.isShowAdd = true
					this.noInformation = false
				} else {
					this.isShowAdd = false
					this.noInformation = true
				}
			},
			init() {
				this.$post(this.url + 'dataResources/getDataResourcesDetail', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						// reportId:'f9fb91f071ef43efaeeb6d372f2dcb1e'
					}))
					.then(res => {
						this.dataResource = res.data.content.dataResourcesDetail
						this.netWorth = res.data.content.netWorth
						this.createTime = res.data.content.createTime
						this.shoeHide()
						this.setTotal()
					})

			},
			emptyData() {
				var obj = {
					valuationReportId: '',
					id: '',
					resourceName: '',
					originalValue: '', //原值
					netValue: '', //净值
					resDescribe: '' //数据资源介绍
				}
				this.redact = this.convertData(obj)
			},
			fillout() { //刚进入页面的立即添加
				this.noInformation = false
				this.isShowRedact = true
				this.emptyData()
			},
			addSet() {
				this.isShowRedact = true //显示初始的编辑框
				this.isShowAdd = false //隐藏添加按钮
				this.emptyData()
			},
			setEditBoxShow(i) {
				this.$refs.editData.map((el, j) => {
					if (j == i) {
						this.$refs.editData[i].style.display = 'block' //展示当前的元素的编辑框
						this.$refs.editData[i].nextSibling.style.display = 'none'
					} else {
						this.$refs.editData[j].style.display = 'none' //展示当前的元素的编辑框
						this.$refs.editData[j].nextSibling.style.display = 'block'
					}
				})
			},
			setEditBoxHide(i) {
				this.$refs.editData.map((el, j) => {
					if (j == i) {
						this.$refs.editData[i].style.display = 'none' //展示当前的元素的编辑框
						this.$refs.editData[i].nextSibling.style.display = 'block'
					} else {
						this.$refs.editData[j].style.display = 'none' //展示当前的元素的编辑框
						this.$refs.editData[j].nextSibling.style.display = 'block'
					}
				})
			},
			edit(i) { //编辑
				this.isShowAdd = false //隐藏添加按钮
				this.isShowRedact = false

				this.setEditBoxShow(i)


				this.redact = this.convertData(this.dataResource[i]) //赋值展示到页面
				this.updateCount() //更新数据介绍的文字输入的总数

			},
			del(i) {
				this.model = true
				this.resourceIdx = i
			},
			submitDialog() {
				this.isSave = false

				this.redact = this.dataResource[this.resourceIdx]
				this.saveInterface(0)
				this.dataResource.splice(this.resourceIdx, 1);

				if (this.dataResource.length == 0) {
					this.noInformation = true
				}
			},
			cancelRedact(i) {
				this.isShowRedact = false
				this.emptyData()

				if (i === '') {
					if (this.dataResource.length == 0) {
						this.isShowAdd = false
						this.noInformation = true
					} else {
						this.isShowAdd = true
					}
				} else {
					this.isShowAdd = true
					this.setEditBoxHide(i)
				}
				console.log('取消i---', i)
			},
			addSave(i) {


				console.log('转换数据---', this.convertData(this.redact))

				if (!this.redact.resourceName) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资源名称');
					return false
				}

				// if (!this.redact.netValue) {
				// 	this.showDialogBar(true, 'notEmpty-info', '请输入数据资源账面净值');
				// 	return false
				// }

				if (!this.redact.resDescribe) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资源介绍');
					return false
				}

				if (this.redact.netValue && this.redact.originalValue && Number(this.redact.netValue) > Number(this.redact
						.originalValue)) {
					this.showDialogBar(true, 'notEmpty-info', '请核实数据资源账面净值应与数据资源账面原值一致');
					return false
				}


				if (i === '') {
					this.dataResource.unshift(this.redact)
				} else {
					this.$refs.editData[i].style.display = 'none'
					this.$refs.editData[i].nextSibling.style.display = 'block'
					this.dataResource[i] = this.convertData(this.redact)
				}
				this.isShowRedact = false
				this.isShowAdd = true

				this.saveInterface(1)
				console.log('点击了确定', this.dataResource)
			},
			saveInterface(deleteFlag) {
				var this_ = this

				this.setTotal()

				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'dataResources/saveDataResourcesDetail', qs.stringify({
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							dataResources: JSON.stringify(this.redact),
							reportType: this.type,
							deleteFlag: deleteFlag // 删除标识  0：删除   1：正常
						}))
						.then(res => {

							if (!this.$route.query.reportId) {
								this.$router.push({
									query: merge({}, {
										'reportId': res.data.content,
									})
								})
							}

							setTimeout(function() {
								this_.isSave = false
								this_.init()
							}, 200)

						})
				}

			},
			nextStep() {
				var this_ = this
				if (this.netWorth && this.totalNetWorth && Number(this.totalNetWorth) > Number(this.netWorth)) {
					this.showDialogBar(true, 'notEmpty-info', '数据资源账面净值合计数应≤资产负债表中无形资产账面净值，请核实后修改');
					this.hint = true
					return false
				} else {
					setTimeout(function() {
						this_.$router.push({
							name: this_.jumpAddress,
							query: {
								reportId: this_.$route.query.reportId
							}
						})
					}, 500)
				}

			},


			//是  载入缓存
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				var this_ = this
				this.hideDialogBar()

				if (this.hint) {
					setTimeout(function() {
						this_.$router.push({
							name: this_.jumpAddress,
							query: {
								reportId: this_.$route.query.reportId
							}
						})
					}, 500)
				}
			}

		}


	}
</script>

<style lang="less">
	.resourceMain {
		width: 80%;
		margin: 0 auto;

		.saveBox {
			width: 70%;
			margin-left: 24%;
			margin-top: 0;
		}
	}

	.resourceFrame {
		width: 100%;
		box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
		border-top: 1px solid #29FFFC;
		border-bottom: 1px solid #29FFFC;
		position: relative;

		>i {
			width: 100%;
			position: absolute;
			display: block;
		}

		>i:nth-of-type(1) {
			top: 0;
		}

		>i:nth-of-type(2) {
			bottom: 0;
		}


		>i:nth-of-type(1)::before {
			content: '';
			width: 16px;
			height: 3px;
			background: #29FFFC;
			display: block;
			position: absolute;
			top: -2px;
			left: 0;
		}

		>i:nth-of-type(1)::after {
			content: '';
			width: 16px;
			height: 3px;
			background: #29FFFC;
			display: block;
			position: absolute;
			top: -2px;
			right: 0;
		}

		>i:nth-of-type(2)::before {
			content: '';
			width: 16px;
			height: 3px;
			background: #29FFFC;
			display: block;
			position: absolute;
			bottom: -2px;
			left: 0;
		}

		>i:nth-of-type(2)::after {
			content: '';
			width: 16px;
			height: 3px;
			background: #29FFFC;
			display: block;
			position: absolute;
			bottom: -2px;
			right: 0;
		}
	}

	.noInformation {
		text-align: center;
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;


		img {
			height: 23vh;
			vertical-align: middle;
			margin-right: 25px;
		}

		>div {
			text-align: left;

			span {
				font-weight: 400;
				font-size: 14px;
				color: #fff;
				margin-top: 20px;
			}
		}

		#fillout {
			width: 112px;
			height: 42px;
			line-height: 42px;
			font-weight: bold;
			font-size: 16px;
			color: #fff;
			text-align: center;
			background: url(../../assets/img/dataResource/fillout.png) center center no-repeat;
			background-size: contain !important;
			cursor: pointer;
			margin-top: 15px;
		}

		#fillout:hover {
			background: url(../../assets/img/dataResource/filloutHover.png) center center no-repeat;
		}

	}

	.resourceRditBox {
		margin-bottom: 20px;

		>p {
			font-weight: bold;
			font-size: 16px;
			color: #29FFFC;
			padding: 20px 40px 0;
		}

		>ul {
			padding: 20px 40px 0 40px;

			li:first-child {
				align-items: center;
			}

			li {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;

				label {
					font-weight: bold;
					font-size: 14px;
					color: #fff;
					position: relative;
				}

				.baseRight {
					width: calc(100% - 139px);
					position: relative;
				}

				input {
					width: 100%;
					height: 30px;
					border: 1px solid #19ECFF;
					box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
					background: #1237c6;
					color: #fff;
					padding: 0 6px;
				}

				input[type="text"] {
					font-size: 14px;
				}

				input[type="number"] {
					font-size: 0;
				}

				input:focus {
					border-color: #fff;
					font-size: 14px;
				}

				.text-area-container {

					textarea {
						height: 170px;
						resize: none;
						border: 1px solid #19ECFF;
						box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
						background: #1237c6;
						border-radius: 0;
						font-size: 14px;
						color: #fff;
					}

					.char-count {
						position: absolute;
						bottom: 5px;
						right: 10px;
						font-size: 14px;
						color: #fff;
					}
				}

				.showInput {
					width: 100%;
					height: 30px;
					line-height: 30px;
					border: 1px solid #19ECFF;
					box-shadow: 1px 1px 19px 2px rgba(0, 237, 255, 0.5) inset;
					background: #1237c6;
					font-size: 14px;
					color: #fff;
					padding: 0 6px;
					position: absolute;
					top: 0;
					left: 0;
					overflow: hidden;
				}

				.measure {
					font-size: 12px;
					color: #fff;
					position: absolute;
					right: 0px;
					top: 6px;
				}

				.originalNetWorth {
					width: 48%;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.baseRight {
						width: calc(100% - 139px);
					}

					input,
					.showInput {
						width: 88%;
					}
				}

			}
		}

	}

	.resourceList {
		.addBtn {
			font-size: 16px;
			color: #29FFFC;
			position: relative;
			text-align: right;
			cursor: pointer;
		}

		.addBtn:hover {
			color: rgba(0, 231, 228, 1);
		}

		.addBtn::before {
			content: '';
			width: 14px;
			height: 14px;
			display: block;
			position: absolute;
			top: 23%;
			right: 38px;
			background-size: contain !important;
		}

		.addBtn::before {
			background: url(../../assets/img/dataResource/addIcon.png) center center no-repeat;
		}

		.addBtn:hover::before {
			background: url(../../assets/img/dataResource/addIconHover.png) center center no-repeat;
		}

		>ul {

			.totalNetWorth {
				height: 60px;
				line-height: 60px;
				background: #18B2FF;
				margin-top: 20px;

				span {
					font-size: 14px;
					font-weight: bold;
					color: #fff;
					position: relative;
					text-align: center;
				}

				>span:first-child {
					width: 30%;
				}

				>span:last-child {
					width: 70%;
				}

				>span:first-child::after {
					content: '';
					width: 2px;
					height: 26px;
					background: #fff;
					display: block;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}
			}

			>li {
				.listEditing {
					display: none;
				}

				.detailedDisplay {
					margin: 10px 0;
					padding: 16px 0;

					.operate {
						position: absolute;
						top: 26px;
						right: 0;

						span {
							font-size: 14px;
							color: #29FFFC;
							position: relative;
							margin-right: 40px;
							cursor: pointer;
						}

						span:hover {
							color: rgba(0, 231, 228, 1);
						}

						span::before {
							content: '';
							width: 16px;
							height: 16px;
							display: block;
							position: absolute;
							top: 49%;
							left: -20px;
							transform: translateY(-50%);
							background-size: contain !important;
						}

						span:first-child::before {
							background: url(../../assets/img/dataResource/redactIcon.png) center center no-repeat;
						}

						span:last-child::before {
							background: url(../../assets/img/dataResource/del.png) center center no-repeat;
						}

						span:first-child:hover::before {
							background: url(../../assets/img/dataResource/redactIconHover.png) center center no-repeat;
						}

						span:last-child:hover::before {
							background: url(../../assets/img/dataResource/delHover.png) center center no-repeat;
						}
					}

					.operate+div {

						span:last-child {
							width: 60%;
						}
					}

					>div {
						padding: 10px 40px 10px 40px;

						>p {
							width: 50%;
							display: inline-block;

							>span:last-child {
								width: calc(100% - 130px);
							}
						}

						span {
							font-size: 14px;
							color: #fff;
							position: relative;
						}

						span:first-child {
							font-weight: bold;
						}

						span:last-child {
							width: 80%;
							word-break: break-all;
							vertical-align: top;
						}


					}
				}



			}

		}
	}


	@media screen and (max-width: 1400px) {
		.resourceMain {
			width: 100%;
		}
	}
</style>