<template>
	<div>
		<DrawPileBar :id="'aa'" :chartData="incomeForecast1" v-if="charts" />
		
		<Pile3D :id="'bb'" :chartData="incomeForecast1" v-if="charts" />

		<Histogram :id="'incomeStatement'" :chartData="incomeForecast" v-if="charts" />

	</div>
</template>

<script>
	import DrawPileBar from '../../common/echarts/drawPileBar.vue'
	import Histogram from '../../common/echarts/histogram.vue'
	import Pile3D from '../../common/echarts/pile3D.vue'

	import qs from 'qs'

	export default ({
		components: {
			DrawPileBar,
			Histogram,
			Pile3D
		},
		data() {
			return {
				charts: false,
				incomeForecast1: {
					data: [],
					year: ["2021E", "2022E", "2023E", "2024E"]
				},
				incomeForecast: {
					data: [],
					year: []
				},

			}
		},
		mounted() {
			var this_ = this
			var that = this


			this.$post(this.domain.valuationProject + 'valuationReport/qryDataValuationReport', qs.stringify({
				reportId: '6f67f3fbabf34b82ad39d21f1671cbd7',
				channel: '0000'
			})).then(res => {

				this_.charts = true
				this_.incomeForecast1.data = [{
					name: '数据1',
					value: [28, 53, 4, 5]
				}, {
					name: '数据2',
					value: [15, 3, 4, 5]
				}, {
					name: '数据3',
					value: [22, 3, 14, '']
				}, {
					name: '数据4',
					value: [52, 3, 4, 15]
				}]

				that.pageInfo = res.data.content;
				var incomeForecast = JSON.parse(that.pageInfo.financialIncome.income)
			
				this.incomeForecast.data = []
				this.incomeForecast.year = []
				incomeForecast.map((el, i) => {
					this.incomeForecast.data.push(el.value)
					this.incomeForecast.year.push(Number(that.pageInfo.financialForecast.year) + i + '年')
				})

			})



		},
		methods: {


		}

	})
</script>

<style scoped lang="less">
	#aa,#bb {
		width: 945px;
		height: 500px;
		margin: 50px;
	}
	
	#incomeStatement {
		width: 945px;
		height: 550px;
		margin: 50px auto 0;
	}
</style>