<template>
	<div class="information">
		<div class="highlightRemarks">
			<p>注： 红色 <span class="reRed">*</span>为必填项</p>
		</div>

		<ul class="relevantQuestion">
			<li>
				<label for="companyName">企业名称<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="text" id="companyName" class="required" data-value='false' v-model="basicInformation.companyName"
						@blur="searchMatch($event)" @focus="focus($event)" @input="fuzzyQuery" @change="change" autocomplete="off"
						placeholder="请输入">

					<div id="getCompanyNameList" data-area="" @click="preventBlur($event)">
						<p v-for="(item,i) in getCompanyNameList" :key="i" :data-area="item.base"
							@click="checkedName(item.name,item.base)">
							{{item.name}}
						</p>
					</div>
				</div>
			</li>
			<li>
				<label for="time">成立日期<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<Date-picker size="large" value-format="yyyy-MM-dd" type="date" confirm :editable="false" placeholder="选择成立日期"
						:options="forbidDate" id="time" @on-change="timeDate" v-model="basicInformation.establishDate"
						placement="bottom">
					</Date-picker>
				</div>
			</li>
			<li>
				<label>所属行业<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<div class="firstIndustry">
						<Select v-model="basicInformation.industryName" @on-change="industryChange">
							<Option v-for="(item, i) in firstIndustry" :value="item.name" :key="i">
								{{ item.name }}
							</Option>
						</Select>
					</div>
				</div>
			</li>
			<li>
				<label style="visibility: hidden;">所属行业<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<Select v-model="basicInformation.industryChildName" @on-change="secondIndustryChange">
						<Option v-for="(item, i) in secondIndustry" :value="item.name" :key="i">
							{{ item.name }}
						</Option>
					</Select>
				</div>
			</li>
			<li>
				<label for="registeredCapital">注册资本<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="number" id="regCapital" autocomplete="off" v-model="basicInformation.regCapital"
						@blur="displayThousandths($event,basicInformation.regCapital,'regCapital')" @focus="focus($event)"
						@change="change" @wheel="method.disableWheel($event)" placeholder="请输入">
					<div class="showInput" @click="inputFocus($event)">
						{{this.method.formateNum(basicInformation.regCapital,2)}}
					</div>
					<span class="measure">万元</span>
				</div>
			</li>
			<li>
				<label for="legalRepresentative">法定代表人<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="text" id="legalPeople" autocomplete="off" maxlength="20" v-model="basicInformation.legalPeople"
						@blur="blur($event)" @focus="focus($event)" @change="change" placeholder="请输入">
				</div>
			</li>
			<li>
				<label for="socialCode">统一社会信用代码<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="text" id="creditCode" class="required" autocomplete="off" data-value='false' maxlength="18"
						v-model="basicInformation.creditCode" @blur="blur($event)" @focus="focus($event)" @change="change"
						placeholder="请输入">
				</div>
			</li>
			<li>
				<label for="address">详细地址<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="text" id="address" autocomplete="off" v-model="basicInformation.address" @blur="blur($event)"
						@focus="focus($event)" @change="change" placeholder="请输入">
				</div>
			</li>
			<li style="width: 100%;" v-if="this.type == 13">
				<label for="dataDesc">数据资产介绍<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<textarea id="" id="dataDesc" placeholder="请描述数据资产提供的产品或服务，包括不限于其功能、特点、使用场景和潜在用途等方面，字数200字以内。" maxlength="200"
						v-model="basicInformation.dataDesc" @blur="blurDataDesc($event)" @focus="focus($event)"
						@change="change"></textarea>
				</div>
			</li>
			<li style="width: 100%;">
				<label for="address">数据类型（多选）<span class="reRed">*</span></label>
				<div class="baseRight empty" style="background: transparent;">
					<div class="dataType">
						<p v-for="(item,index) in dataType">
							<input type="checkbox" :id="'dataType'+index" ref="dataType"
								:checked="basicInformation.dataResourceType.indexOf(item) != -1?'checked':''" />
							<label :for="'dataType'+index">{{item}}</label>
						</p>
					</div>
				</div>
			</li>
			<li>
				<label for="income">{{year}}年公司营业收入(预测)<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="number" id="income" autocomplete="off" v-model="basicInformation.income"
						@blur="displayThousandths($event,basicInformation.income,'income')" @focus="focus($event)" @change="change"
						@wheel="method.disableWheel($event)" placeholder="请输入">
					<div class="showInput" @click="inputFocus($event)">
						{{this.method.formateNum(basicInformation.income,2)}}
					</div>
					<span class="measure">万元</span>
				</div>
			</li>
			<li>
				<label for="netAsset">{{year}}年公司净资产(预测)<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="number" id="netAsset" autocomplete="off" v-model="basicInformation.netAsset"
						@blur="displayThousandths($event,basicInformation.netAsset,'netAsset')" @focus="focus($event)"
						@change="change" @wheel="method.disableWheel($event)" placeholder="请输入">
					<div class="showInput" @click="inputFocus($event)">
						{{this.method.formateNum(basicInformation.netAsset,2)}}
					</div>
					<span class="measure">万元</span>
				</div>
			</li>
			<li>
				<label for="netProfit">{{year}}年公司净利润(预测)<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="number" id="netProfit" autocomplete="off" v-model="basicInformation.netProfit"
						@blur="displayThousandths($event,basicInformation.netProfit,'netProfit')" @focus="focus($event)"
						@change="change" @wheel="method.disableWheel($event)" placeholder="请输入">
					<div class="showInput" @click="inputFocus($event)">
						{{this.method.formateNum(basicInformation.netProfit,2)}}
					</div>
					<span class="measure">万元</span>
				</div>
			</li>
			<li v-if="type==15">
				<label for="amortizeProfit">{{year}}年公司息税折旧摊销前利润(预测)<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="number" id="amortizeProfit" autocomplete="off" v-model="basicInformation.amortizeProfit"
						@blur="displayThousandths($event,basicInformation.amortizeProfit,'amortizeProfit')" @focus="focus($event)"
						@change="change" @wheel="method.disableWheel($event)" placeholder="请输入">
					<div class="showInput" @click="inputFocus($event)">
						{{this.method.formateNum(basicInformation.amortizeProfit,2)}}
					</div>
					<span class="measure">万元</span>
				</div>
			</li>
			<li>
				<label for="firstHolderRatio">第一股东<span class="reRed">*</span></label>
				<div class="baseRight empty">
					<input type="text" id="firstHolderRatio" autocomplete="off" v-model="basicInformation.firstHolderRatio"
						@blur="((ev) => { percentage(ev, basicInformation, 'firstHolderRatio') })" @focus="focus($event)"
						@wheel="method.disableWheel($event)" placeholder="请输入">
					<span class="measure">%</span>
				</div>
			</li>
			<li>
				<label for="secHolderRatio">第二股东</label>
				<div class="baseRight empty">
					<input type="text" id="secHolderRatio" autocomplete="off" v-model="basicInformation.secHolderRatio"
						@blur="((ev) => { percentage(ev, basicInformation, 'secHolderRatio') })" @focus="focus($event)"
						@wheel="method.disableWheel($event)" placeholder="请输入">
					<span class="measure">%</span>
				</div>
			</li>
			<li>
				<label for="thirdHolderRatio">第三股东</label>
				<div class="baseRight empty">
					<input type="text" id="thirdHolderRatio" autocomplete="off" v-model="basicInformation.thirdHolderRatio"
						@blur="((ev) => { percentage(ev, basicInformation, 'thirdHolderRatio') })" @focus="focus($event)"
						@wheel="method.disableWheel($event)" placeholder="请输入">
					<span class="measure">%</span>
				</div>
			</li>
			<li>
				<label for="fourHolderRatio">第四股东</label>
				<div class="baseRight empty">
					<input type="text" id="fourHolderRatio" autocomplete="off" v-model="basicInformation.fourHolderRatio"
						@blur="((ev) => { percentage(ev, basicInformation, 'fourHolderRatio') })" @focus="focus($event)"
						@wheel="method.disableWheel($event)" placeholder="请输入">
					<span class="measure">%</span>
				</div>
			</li>
			<li>
				<label for="otherHolderRatio">其他股东</label>
				<div class="baseRight empty">
					<input type="text" id="otherHolderRatio" autocomplete="off" disabled placeholder="请输入"
						v-model="basicInformation.otherHolderRatio"
						@blur="((ev) => { percentage(ev, basicInformation, 'otherHolderRatio') })" @focus="focus($event)"
						@wheel="method.disableWheel($event)" style="border-color: #1975ff;color:#b1b1b1 ;">
					<span class="measure">%</span>
				</div>
			</li>
		</ul>




	</div>
</template>

<script>
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';


	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: {
				type: String
			},
			jumpAddress: String //保存之后的跳转地址


		},
		data() {
			return {
				isSave: false,
				projectName: this.url,
				inputTimer: null,
				getCompanyNameList: [], //模糊查询公司列表
				checkedCompanyName: '', //模糊查询列表中选中的公司名称
				firstIndustry: [],
				secondIndustry: [],
				year: new Date().getFullYear(),
				basicInformation: {
					id: '',
					companyName: '',
					industry: '', // 一级行业标识码
					industryChild: '', //二级行业标识码
					industryName: '', //一级行业名称
					industryChildName: '', //二级行业名称
					regCapital: '', //注册资本
					legalPeople: '', //法定代表人
					establishDate: '', //成立时间
					creditCode: '', // 统一社会信用代码
					address: '', // 详细地址
					dataDesc: '', //数据资产介绍
					dataResourceType: [], //数据类型
					position: 10,
					bussinessDesc: '',
					income: '', //公司营业收入(预测)
					netAsset: '', //公司净资产(预测)
					netProfit: '', //公司净利润（预测）
					amortizeProfit: '', //公司息税折旧摊销前利润(预测)
					firstHolderRatio: '', // 第一股东持股比例
					secHolderRatio: '', // 第二股东持股比例
					thirdHolderRatio: '', // 第三股东持股比例
					fourHolderRatio: '', // 第四股东持股比例
					otherHolderRatio: '', // 其他股东持股比例
					valuationReportId: '',
					totalOwnershipInterest: 0, //所有者权益（或股东权益）
					paidUpCapital: 0, //实收资本(或股本)
					incomeTotal: null, //收入预测第一年的值
					netProfitTotal: null //净利润预测第一年的值

				},
				reportMatchId: '',
				forbidDate: {
					disabledDate(date) {
						const disabledDate = date.getFullYear()
						return (
							disabledDate > new Date().getFullYear()
						)
					}
				},
				isCacheValue: '0',
				empty: '',
				scrollTop: 0,
				cacheAlert: true,
				dataType: ['数字', '图片', '音频', '视频', '文字', '其他'],
				hint: false

			}
		},
		created() {
			if (this.$route.query.userToken) {
				localStorage.userToken = this.$route.query.userToken
			}

			if (this.$route.query.encipher) {
				localStorage.userName = this.method.base64ToUtf8(this.$route.query.encipher)
			}

		},
		mounted() {
			this.pageModify('false');
			this.getBaseInfo('1', 'getBaseInfo')

		},
		watch: {
			getCompanyNameList: {
				handler: function(newValue, oldValue) {
					// console.log('新列表-----', newValue)
					this.$set(this, 'getCompanyNameList', newValue)
				},
				deep: true
			}

		},
		computed: {
			...mapState(['checkStatus'])
		},
		methods: {
			...mapMutations(['pageModify']),
			timeDate: function(val) {
				this.isSave = false
				this.pageModify('true');
				this.basicInformation.establishDate = val;
				document.querySelectorAll('.empty')[1].querySelector('.ivu-date-picker').style.borderColor = '#19ECFF'
				//调用缓存共用方法
				if (!this.$route.query.reportId || !this.basicInformation.id) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.type, '', this)
				}
			},
			change() {
				this.pageModify('true');
			},
			blur: function(e) {
				this.isSave = false
				e.currentTarget.style.borderColor = "#19ECFF";
				e.currentTarget.setAttribute("placeholder", "请输入")
				if (this.method.filterSensitiveWords(e.target, this.basicInformation)) {
					e.currentTarget.style.borderColor = "red";

					this.showDialogBar(true, 'notEmpty', '您输入的内容存在敏感词')

				} else {
					e.currentTarget.style.borderColor = "#19ECFF";
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || !this.basicInformation.id) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.type, '', this)
				}

			},
			percentage: function(e, val, key) {
				this.pageModify('true');
				e.currentTarget.style.border = "1px solid #00E4FF";
				this.fixed(val, key)
				//调用缓存共用方法
				if (!this.$route.query.reportId || !this.basicInformation.id) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.type, '', this)
				}
			},
			blurDataDesc(e) {
				this.isSave = false
				e.currentTarget.style.borderColor = "#19ECFF";
				e.currentTarget.setAttribute("placeholder", "请描述数据资产提供的产品或服务，包括不限于其功能、特点、使用场景和潜在用途等方面，字数200字以内。")
			},
			focus(e) {
				e.currentTarget.style.borderColor = "#fff";
				e.currentTarget.setAttribute("placeholder", "")
				this.checkedCompanyName = ''
			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				// e.currentTarget.previousSibling.style.fontSize = '18px';
				e.currentTarget.previousSibling.focus();
			},
			displayThousandths: function(e, val, key) {
				e.currentTarget.style.borderColor = "#19ECFF";
				e.currentTarget.setAttribute("placeholder", "请输入")
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (val == '') {
					this.$set(this.basicInformation, key, '0')
				} else {
					if (count > 2) {
						this.$set(this.basicInformation, key, Math.round(Number(val) * 100) / 100)
					}
				}
				if (e.currentTarget.parentNode.lastChild.innerHTML == '万元') {
					if (val <= 2147483647 && val >= -2147483648) {
						// console.log('符合')
					} else {
						this.basicInformation[key] = "";
						this.showDialogBar(true, 'notEmpty', '请输入合理数值')
						return false;
					}
					e.currentTarget.nextSibling.style.display = 'block';
					// e.currentTarget.style.fontSize = '0';
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || !this.basicInformation.id) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.type, '', this)
				}
			},
			preventBlur(event) {
				event.stopPropagation();
			},
			fixed: function(data, key) {
				if (data[key] > 100) {
					this.showDialogBar(true, 'notEmpty', '请输入不大于100的数字')

					data[key] = '';
				} else {
					data[key] = Number(data[key]).toFixed(2)
				}

				if (key == 'firstHolderRatio') {
					this.basicInformation.otherHolderRatio = (100 - (Number(this.basicInformation.firstHolderRatio) +
						Number(this.basicInformation.secHolderRatio) + Number(this.basicInformation.thirdHolderRatio) +
						Number(this.basicInformation.fourHolderRatio))).toFixed(2);
				} else if (key == 'secHolderRatio') {
					if (Number(this.basicInformation.secHolderRatio) > Number(this.basicInformation.firstHolderRatio) || this
						.basicInformation.secHolderRatio < 0 || (Number(this.basicInformation.secHolderRatio) + Number(
							this.basicInformation.firstHolderRatio)) > 100) {
						this.showDialogBar(true, 'notEmpty', '请输入正确的持股比例')
						data['secHolderRatio'] = '';
					} else {
						this.basicInformation.otherHolderRatio = (100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio)).toFixed(2);
					}
				} else if (key == 'thirdHolderRatio') {
					if (Number(this.basicInformation.thirdHolderRatio) > Number(this.basicInformation.secHolderRatio) || this
						.basicInformation.thirdHolderRatio < 0 || (Number(this.basicInformation.thirdHolderRatio) + Number(this
							.basicInformation.secHolderRatio) + Number(this.basicInformation.firstHolderRatio)) > 100) {
						this.showDialogBar(true, 'notEmpty', '请输入正确的持股比例')
						data['thirdHolderRatio'] = '';
					} else {
						this.basicInformation.otherHolderRatio = (100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio)).toFixed(2);
					}
				} else if (key == 'fourHolderRatio') {
					if (Number(this.basicInformation.fourHolderRatio) > Number(this.basicInformation
							.thirdHolderRatio) || this
						.basicInformation.fourHolderRatio < 0 || (Number(this.basicInformation.fourHolderRatio) +
							Number(this.basicInformation.thirdHolderRatio) + Number(this.basicInformation
								.secHolderRatio) + Number(this.basicInformation.firstHolderRatio)) > 100) {

						this.showDialogBar(true, 'notEmpty', '请输入正确的持股比例')

						data['fourHolderRatio'] = '';
					} else {
						this.basicInformation.otherHolderRatio = (100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio)).toFixed(2);
					}
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || !this.basicInformation.id) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.type, '', this)
				}

			},
			searchMatch(e) {
				this.isSave = false
				var target = document.getElementById('companyName')
				var this_ = this

				console.log('失去了焦点')

				target.setAttribute("placeholder", "请输入")
				target.style.borderColor = "#19ECFF";
				if (this.method.filterSensitiveWords(target, this.basicInformation)) {
					target.style.borderColor = "red";
					this.showDialogBar(true, 'notEmpty', '您输入的内容存在敏感词')
				} else {
					target.style.borderColor = "#19ECFF";
				}


				// console.log(this.basicInformation.companyName != '', !this.checkStatus.baseInfoFlag, this.checkStatus)
				if ((this.basicInformation.companyName != '' && !this.checkStatus.baseInfoFlag)) {
					setTimeout(function() {
						console.log('调数据的公司名称---', this_.basicInformation.companyName)
						this_.fetchData(this_.basicInformation.companyName)
					}, 1000)
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || !this.basicInformation.id) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.type, '', this)
				}

			},
			industryChange: function(val) {
				this.isSave = false
				this.pageModify('true');
				for (var i = 0; i < this.firstIndustry.length; i++) {
					if (val == this.firstIndustry[i].name) {
						this.basicInformation.industry = this.firstIndustry[i].code;
						this.industryGroup(this.firstIndustry[i].code, '')
						break;
					}
				}
				// console.log("12")
				document.querySelectorAll('.empty')[2].querySelector('.ivu-select-selection').style.borderColor = '#19ECFF'

				// //调用缓存共用方法
				if (!this.$route.query.reportId || !this.basicInformation.id) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.type, '', this)
				}

			},
			getCompanyName: function(name) {
				this.getCompanyNameList = []
				this.$post(this.projectName + 'baseInfo/getCompanyName', qs.stringify({
						companyName: name
					}))
					.then((res) => {
						// this.getCompanyNameList = res.data.content
						res.data.content.map(el => {
							this.getCompanyNameList.push(el)
						})
					});
			},
			escapeRegExp: function(string) { //转义特殊字符
				return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
			},
			fuzzyQuery: function() {
				var this_ = this;
				var text = this_.escapeRegExp(this_.basicInformation.companyName);
				var regex = new RegExp(text)
				if (/^[\u4e00-\u9fa5]+$/.test($('#companyName').val())) {
					clearTimeout(this_.inputTimer);
					this_.inputTimer = setTimeout(function() { //阻止input方法执行两次
						if (this_.basicInformation.companyName.length >= 4 && this_.basicInformation.companyName.length < 20) {
							console.log('中文输入已完成,调模糊查询')
							this_.getCompanyName(this_.basicInformation.companyName)
						}
					}, 100); // 设置一个适当的延迟时间

				} else {
					// 中文输入未完成
					if (this_.basicInformation.companyName.length == 0) {
						this_.getCompanyNameList = []
					}
				}
			},
			checkedName: function(name, base) {
				console.log('点击了公司列表')
				this.checkedCompanyName = name
				this.basicInformation.companyName = name
				this.basicInformation.area = base
				$('#getCompanyNameList').html('');
				this.fetchData(name)
			},
			oneIndustry: function() { //一级行业
				var params = {
					listedCode: 10
				}

				this.$post(this.projectName + 'dataIndustry/qryOneIndustry', qs.stringify(params))
					.then((res) => {
						this.firstIndustry = res.data.content;
					});
			},
			industryGroup: function(code, name) {
				var params = {
					industryCode: code
				}

				this.$post(this.projectName + 'dataIndustry/qryTwoIndustry', qs.stringify(params))
					.then((res) => {
						this.secondIndustry = res.data.content;
						this.basicInformation.industryChildName = name;
					});
			},
			secondIndustryChange: function(val) {
				this.isSave = false
				this.pageModify('true');
				for (var i = 0; i < this.secondIndustry.length; i++) {
					if (val == this.secondIndustry[i].name) {
						this.basicInformation.industryChild = this.secondIndustry[i].code;
						break;
					}
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || !this.basicInformation.id) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.type, '', this)
				}
				document.querySelectorAll('.empty')[3].querySelector('.ivu-select-selection').style.borderColor = '#19ECFF'
				// $("#secondIndustry").css("border", "none");

			},
			fetchData: function(name) {
				this.$post(this.projectName + 'valuationReport/getBaseInfoByCompanyName', qs.stringify({
						name: name ? name : this.basicInformation.companyName,
						stage: '',
						type: this.type
					}))
					.then((res) => {
						var defaultDataKey = Object.keys(this.basicInformation)
						if (res.data.code == 200 && res.data.content != null) {
							// dataSource ：0官网   1用友
							this.autoDodge = res.data.content.dataSource == '0' ? true : false;

							// if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
							// 	$('.baseContent').css('margin-top', res.data.content.dataSource == '0' ? '100px' : '0');
							// }

							var content = res.data.content;
							var dataKey = Object.keys(content); //获取对象的 key
							this.reportMatchId = content.valuationReportId;
							if (content.createTime) {
								this.toDate = content.createTime.split("-")[0] + "年" + content.createTime
									.split(
										"-")[1] + "月" + content.createTime.split("-")[2] + "日";
							}

							if (content.reportType == 1 && content.stage == 2) {
								this.toType = "成长期快速估值";
							} else if (content.reportType == 1 && content.stage == 3) {
								this.toType = "成熟期快速估值";
							} else if (content.reportType == 2 && content.stage == 2) {
								this.toType = "成长期专业估值";
							} else if (content.reportType == 2 && content.stage == 3) {
								this.toType = "成熟期专业估值";
							} else if (content.reportType == 7 && content.stage == 1) {
								this.toType = "初创期价值诊断";
							} else if (content.reportType == 7 && content.stage == 2) {
								this.toType = "成长期价值诊断";
							} else if (content.reportType == 7 && content.stage == 3) {
								this.toType = "成熟期价值诊断";
							} else if (content.reportType == 8 && content.stage == 1) {
								this.toType = "初创期价值演示";
							} else if (content.reportType == 8 && content.stage == 2) {
								this.toType = "成长期价值演示";
							} else if (content.reportType == 8 && content.stage == 3) {
								this.toType = "成熟期价值演示";
							} else if (content.reportType == 7 && content.stage == 7) {
								this.toType = "达尔文诊断";
							} else if (content.reportType == 2 && content.stage == 7) {
								this.toType = "达尔文估值";
							} else if (content.reportType == 5 && content.stage == 7) {
								this.toType = "达尔文追踪";
							} else if (content.stage == 1) {
								this.toType = "初创期估值";
							} else if (content.stage == 4) {
								this.toType = "并购估值";
							} else if (content.reportType == 11) {
								this.toType = "财务预警";
							} else if (content.reportType == 12) {
								this.toType = "图灵估值";
							} else if (content.reportType == 100) {
								this.toType = "在线尽调";
							} else if (content.reportType == 5) {
								this.toType = "价值追踪";
							}

							this.assignment(dataKey, defaultDataKey, content, 'searchMatch')

							this.$nextTick(function() {
								this.method.cacheInfo("baseInfo", JSON.stringify(this.basicInformation), this.type,
									'', this)
							})

						}
						// else {
						// 	defaultDataKey.forEach((item, idx) => {
						// 		if (item != 'companyName') {
						// 			this.basicInformation[item] = '';
						// 		}
						// 	})
						// }
					});
			},
			assignment: function(param, data, content, describe) {
				//param:参数key的数组   data：主要数据key的数组  content：接口返回的数据      describe：表示是填充调入的数据还是回显的数据
				data.forEach((elem, index) => {
					param.forEach((item, idx) => {
						if (elem == item) {
							if (item == 'regCapital' || item == 'income' || item == 'netAsset' || item == 'netProfit' ||
								item == 'amortizeProfit') {
								if (document.getElementById(item)) {
									document.getElementById(item).nextSibling.style
										.display = 'block';
									this.basicInformation[item] = content[item]
								}
							} else if (item == 'valuationReportId') {
								this.basicInformation[item] = this.$route.query.reportId ? this.$route.query.reportId :
									content[item]
							} else if (item == 'position') {
								this.basicInformation[item] = 10;
								console.log(this.basicInformation[item])
							} else if (item == 'dataResourceType') {
								if (content[item] === '') content[item] = '[]'
								// this.basicInformation[item] = content[item] ? JSON.parse(content[item]) : [];
								this.basicInformation[item] = content[item] ? content[item] : [];
							} else if (item == 'firstHolderRatio' || item == 'secHolderRatio' || item ==
								'thirdHolderRatio' || item == 'fourHolderRatio' || item == 'otherHolderRatio') {
								this.basicInformation[item] = content[item] ? Number(content[item]).toFixed(2) : '';
							} else {
								this.basicInformation[item] = content[item] ? content[item] : '';
							}

							if (describe == 'searchMatch') {
								if (item == 'id' || item == 'industry' || item == 'industryChild' || item == 'industryName' ||
									item == 'industryChildName') {
									this.basicInformation[item] = ''
								} else if (item == 'valuationReportId') {
									this.basicInformation[item] = this.$route.query.reportId ? this.$route.query.reportId : ''
								}

							} else {
								if (item == 'industry') {
									var params = {
										listedCode: 10
									}

									this.$post(this.projectName + 'dataIndustry/qryOneIndustry', qs
											.stringify(params))
										.then((res) => {
											var codeList = res.data.content;
											this.firstIndustry = res.data.content;
											codeList.forEach((item, idx) => {
												if (item.code == content['industry']) {
													this.industryGroup(content.industry,
														content
														.industryChildName); //调用二级行业
												}
											})
										});

								}
							}

						}
					});
				});

				console.log(this.basicInformation)

			},
			getBaseInfo: function(close, describe) {
				this.$Spin.show(); //全局加载中
				// console.log(typeof(this.type))
				this.$post(this.projectName + 'baseInfo/getBaseInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						versions: this.versions,
						stage: '',
						reportType: this.type,
					}))
					.then((res) => {
						this.$Spin.hide();

						if (res.data.content) {
							this.isCacheValue = res.data.content.isCache
							if (res.data.content.createTime) this.year = res.data.content.createTime.substring(0, 4)


							if ((!this.$route.query.reportId || this.$route.query.reportId) && this.isCacheValue == '0' && !
								sessionStorage.isHistoryCache) {
								this.showDialogBar(true, 'cacheType', '您上次填写过估值报告，是否载入缓存信息？')
								return false
							}
							this.basicInformation.valuationReportId = this.$route.query.reportId;
							var content = res.data.content;
							var dataKey = Object.keys(content); //获取对象的 key
							var defaultDataKey = Object.keys(this.basicInformation)
							this.basicInformation.valuationReportId = this.$route.query.reportId;
							this.basicInformation.id = content.id;
							this.assignment(dataKey, defaultDataKey, content, describe)


						} else {
							this.oneIndustry()
						}



					});
			},
			save: function() {
				var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/; //邮箱
				var creditCodeReg = /^[0-9A-Z]{18}$/; //统一社会信用代码
				var url = '';
				if (!this.basicInformation.companyName) {
					this.showDialogBar(true, 'notEmpty-info', '请输入企业名称')
					this.empty = 0;
					return false;
				}
				if (!this.basicInformation.establishDate) {
					this.showDialogBar(true, 'notEmpty-info', '请选择成立日期')
					this.empty = 1;
					return false;
				}
				if (!this.basicInformation.industryName) {
					this.showDialogBar(true, 'notEmpty-info', '请选择所属一级行业')
					this.empty = 2;
					return false;
				}
				if (!this.basicInformation.industryChildName) {
					this.showDialogBar(true, 'notEmpty-info', '请选择所属二级行业')
					this.empty = 3;
					return false;
				}
				if (!this.basicInformation.regCapital) {
					this.showDialogBar(true, 'notEmpty-info', '请输入注册资本')
					this.empty = 4;
					return false;
				}

				// “所有者权益（或股东权益）-实收资本(或股本)”科目金额小于或等于基本信息中公司注册资本金额
				// if (this.type == 13 && this.basicInformation.totalOwnershipInterest - this.basicInformation.paidUpCapital >
				// 	Number(this.basicInformation.regCapital) * 10000) {
				// 	this.hint = true
				// 	this.showDialogBar(true, 'notEmpty-info', '请核实“所有者权益（或股东权益）- 实收资本(或股本)”科目金额应与基本信息中公司注册资本一致')
				// 	this.empty = 4;
				// 	return false;
				// }



				if (!this.basicInformation.legalPeople) {
					this.showDialogBar(true, 'notEmpty-info', '请输入法定代表人')
					this.empty = 5;
					return false;
				}

				if (!this.basicInformation.creditCode) {
					this.showDialogBar(true, 'notEmpty-info', '请输入统一社会信用代码')
					this.empty = 6;
					return false;
				} else {
					if (!creditCodeReg.test(this.basicInformation.creditCode)) {
						this.showDialogBar(true, 'notEmpty-info', '信用代码输入有误')
						this.empty = 6;
						return false;
					}
				}
				if (!this.basicInformation.address) {
					this.showDialogBar(true, 'notEmpty-info', '请输入详细地址')
					this.empty = 7;
					return false;
				}

				if (this.type == 13 && !this.basicInformation.dataDesc) {
					this.showDialogBar(true, 'notEmpty-info', '请输入数据资产介绍')
					this.empty = 8;
					return false;
				}

				if (this.$refs.dataType) {
					this.basicInformation.dataResourceType = []
					this.$refs.dataType.forEach((item, i) => {
						console.log(item.checked)
						if (item.checked) {
							this.basicInformation.dataResourceType.push(this.dataType[i])
						}
					})
				}
				if (this.basicInformation.dataResourceType.length == 0) {
					this.showDialogBar(true, 'notEmpty-info', '请选择数据类型')
					this.empty = this.type == 13 ? 9 : 8;
					return false;
				}

				if (!this.basicInformation.income) {
					this.showDialogBar(true, 'notEmpty-info', '请输入营业收入(预测)')
					this.empty = this.type == 13 ? 10 : 9;
					return false;
				}

				if (!this.basicInformation.netAsset) {
					this.showDialogBar(true, 'notEmpty-info', '请输入净资产(预测)')
					this.empty = this.type == 13 ? 11 : 10;
					return false;
				}

				if (!this.basicInformation.netProfit) {
					this.showDialogBar(true, 'notEmpty-info', '请输入净利润(预测)')
					this.empty = this.type == 13 ? 12 : 11;
					return false;
				}

				if (this.type == 15) { //只有快速需要填写息税折旧摊销前利润，专业是后台系统计算出来的
					if (!this.basicInformation.amortizeProfit) {
						this.showDialogBar(true, 'notEmpty-info', '请输入息税折旧摊销前利润(预测)')
						this.empty = 12;
						return false;
					} else if (this.basicInformation.amortizeProfit < this.basicInformation.netProfit) {
						this.showDialogBar(true, 'notEmpty-info', '息税折旧摊销前利润(预测)大于等于净利润(预测)')
						this.empty = 12;
						return false;
					}
				}

				if (!this.basicInformation.firstHolderRatio) {
					this.showDialogBar(true, 'notEmpty-info', '请输入第一股东比例')
					this.empty = 13;
					return false;
				}
				
	

				if (this.type == 13 && this.basicInformation.incomeTotal && parseInt(Number(this.basicInformation
						.incomeTotal) / 10000) > parseInt(Number(this.basicInformation.income))) {
					if (this.basicInformation.netProfitTotal && parseInt(Number(this.basicInformation.netProfitTotal) / 10000) >
						parseInt(Number(this.basicInformation.netProfit))) {
						this.showDialogBar(true, 'notEmpty-info', '请核实营业收入(预测)应与营业收入预测表中' + this.year +
							'年营业收入一致，净利润(预测)应与净利润预测表中' + this.year + '年净利润一致')
						this.hint = true
						return false;
					} else {
						this.showDialogBar(true, 'notEmpty-info', '请核实营业收入(预测)应与营业收入预测表中' + this.year + '年营业收入一致')
						this.hint = true
						return false;
					}

				} else if (this.type == 13 && this.basicInformation.netProfitTotal && parseInt(Number(this.basicInformation
						.netProfitTotal) / 10000) > parseInt(Number(this.basicInformation.netProfit))) {
					if (this.basicInformation.incomeTotal && parseInt(Number(this.basicInformation.incomeTotal) / 10000) >
						parseInt(Number(this.basicInformation.income))) {
						this.showDialogBar(true, 'notEmpty-info', '请核实营业收入(预测)应与营业收入预测表中' + this.year +
							'年营业收入一致，净利润(预测)应与净利润预测表中' + this
							.year + '年净利润一致')
						this.hint = true
						return false;
					} else {
						this.showDialogBar(true, 'notEmpty-info', '请核实净利润(预测)应与净利润预测表中' + this.year + '年净利润一致')
						this.hint = true
						return false;
					}
				}



				console.log(this.basicInformation)

				this.saveInterface()


			},
			saveInterface() {
				if (!this.isSave) {
					this.isSave = true
					this.$post(this.projectName + 'baseInfo/save', qs.stringify({
							original: this.reportMatchId,
							baseInfo: JSON.stringify(this.basicInformation),
							stage: this.stage,
							reportType: this.type,
							versions: this.versions,
						}))
						.then((res) => {
							this.$router.push({
								name: this.jumpAddress,
								query: {
									reportId: res.data.content.valuationReportId
								}
							});
						});
				}
			},

			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getBaseInfo('1', 'searchMatch');
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.type, '', this, 'getBaseInfo')
				this.getBaseInfo()
				this.hideDialogBar()
			},
			// 回滚到原位
			closeMaskInfoJump() {
				if (this.hint) { //弹框提示后仍然可以保存
					this.saveInterface()
				} else {
					var emptyValue = this.empty

					// 成立日期
					if (this.empty == 1) {
						document.querySelectorAll('.empty')[emptyValue].querySelector('.ivu-date-picker').style.borderColor = 'red'
					} else if (this.empty == 2 || this.empty == 3) {
						document.querySelectorAll('.empty')[emptyValue].querySelector('.ivu-select-selection').style.borderColor =
							'red'
					} else if (this.type == 13 && this.empty == 8) {
						document.querySelectorAll('.empty')[emptyValue].querySelector('textarea').style.borderColor = 'red'
					} else {
						console.log(emptyValue)
						document.querySelectorAll('.empty')[emptyValue].querySelector('input').style.borderColor = 'red'
					}
					$('html, body').animate({
						scrollTop: document.querySelectorAll('.empty')[emptyValue].offsetTop - "140"
					}, 500)
				}

				this.hideDialogBar()
			}




		},
		beforeDestroy() {
			if (this.dialogBarInstance) {
				this.hideDialogBar(); // 确保在组件销毁前清理对话框实例  
			}
		}


	}
</script>

<style scoped lang="less">
	.highlightRemarks {
		width: 100%;
		font-size: 14px;
		color: #fff;
		overflow: hidden;
	}

	.highlightRemarks p {
		float: left;
		line-height: 35px;
	}

	.reRed {
		color: #FE0000;
		padding-left: 5px;
		padding-right: 5px;
	}

	.relevantQuestion {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.relevantQuestion>li {
		width: 45%;
	}

	.relevantQuestion li label {
		width: 100%;
		display: block;
		font-size: 14px;
		color: #fff;
		margin-top: 20px;
		line-height: 20px;
	}

	.baseRight {
		width: 100%;
		margin-top: 5px;
		position: relative;
	}

	.baseRight input {
		width: 100%;
		height: 35px;
		border: 1px solid #29FFFC;
		padding-left: 4%;
		padding-right: 4%;
		font-size: 14px;
		background-color: transparent;
		color: #fff;
	}

	.baseRight input#regCapital {
		padding-right: 40px;
	}

	.baseRight textarea {
		width: 100%;
		height: 80px;
		border: 1px solid #29FFFC;
		padding-left: 1.5%;
		padding-right: 1.5%;
		font-size: 14px;
		background-color: transparent;
		color: #fff;
		padding-top: 10px;
		resize: none;
	}

	.baseRight .measure {
		color: #fff;
		position: absolute;
		right: 10px;
		top: 11px;
	}

	.desc-container {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.desc-main {
		position: absolute;
		top: 0;
		line-height: 1;
		width: 190px;
		z-index: 2;
	}

	.desc-container .desc-main>span:before {
		content: "";
		position: absolute;
		border-right: 6px solid transparent;
		border-left: 6px solid transparent;
		border-bottom: 9px solid #ECC641;
		border-top: 9px solid transparent;
		top: 5px;
		left: 4px;
		z-index: 7;
		display: none;
	}

	.desc-container .desc-main>span:after {
		content: "";
		position: absolute;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		border-bottom: 8px solid #FEEEB4;
		border-top: 6px solid transparent;
		top: 10px;
		left: 5px;
		z-index: 45;
		display: none;
	}

	.desc-container .desc-main>p {
		background: #FEEEB4;
		padding: 13px 10px 13px 15px;
		position: absolute;
		top: 23px;
		left: 4px;
		border: 1px solid #ECC641;
		line-height: 1.5;
		border-radius: 4px;
		border-top-left-radius: 0;
		display: none;
		color: #282828;
		font-size: 12px;
	}

	.desc-container:hover .desc-main>span:after {
		display: block;
	}

	.desc-container:hover .desc-main>span:before {
		display: block;
	}

	.desc-container:hover .desc-main>p {
		display: block;
	}

	.relevantQuestion li label i {
		display: inline-block;
		width: 12px;
		height: 12px;
		background: #ffa446;
		color: #fff;
		text-align: center;
		line-height: 13px;
		font-size: 12px;
		margin-left: 2px;
		margin-top: -14px;
		border-radius: 6px;
		cursor: pointer;
	}

	.companyRevenueDate {
		width: 45px;
		background-color: transparent;
		color: #fff;
		font-size: 14px;
	}

	.companyRevenueRight {
		position: relative;
	}

	.showInput {
		width: 85%;
		height: 31px;
		line-height: 32px;
		border-radius: 3px;
		font-size: 14px;
		color: #fff;
		position: absolute;
		top: 0px;
		left: 0%;
		background-color: #1237c6;
		margin-top: 2px;
		margin-left: 0.5%;
		display: none;
		padding-left: 4%;
	}

	.relevantQuestion .readonColor {
		background-color: #0005e0;
	}

	.infoMask {
		width: 100%;
		height: 100%;
		position: fixed;
		background-color: #000;
		opacity: 0.3;
		z-index: 999;
	}

	.tooltipState {
		width: 60%;
		color: #fff;
		font-size: 12px;
		border: 1px solid #c56d6d;
		color: #ea403e;
		border-radius: 5px;
		padding-left: 10px;
		margin: auto;
		float: left;
		margin-left: 60px;
		padding-top: 10px;
		padding-bottom: 10px;
		position: relative;
	}

	.cuowu {
		position: absolute;
		width: 13px;
		right: 10px;
		top: 14px
	}

	input:focus,
	textarea:focus {
		outline: none;
	}

	.dataType {
		display: flex;
		justify-content: space-between;

		p {
			display: flex;
			align-items: center;
			cursor: pointer;

			label {
				position: relative;
				cursor: pointer;
				margin-top: 0;
			}

			label::before {
				content: "";
				display: inline-block;
				width: 18px;
				height: 18px;
				border: 2px solid #19ECFF;
				position: relative;
				top: 3px;
				margin-right: 10px;
				cursor: pointer;
			}

			input[type="checkbox"] {
				position: absolute;
				clip: rect(0, 0, 0, 0);
				cursor: pointer;
			}

			input[type="checkbox"]:checked+label::before {
				background: url(../../assets/img/fillin/dataTypeSelected.png) center center no-repeat;
				background-size: 100% 100%;
				border: none;
			}

		}

		p:last-child {
			margin-right: 0;
		}

	}

	/deep/ .ivu-select-single .ivu-select-selection {
		width: 100%;
		height: 35px !important;
		line-height: 35px !important;
		border: 1px solid #19ECFF;
		border-radius: 0;
		background-color: transparent !important;
		padding-left: 9px;
		color: #fff;
	}

	/deep/ .ivu-date-picker {
		width: 100%;
		height: 35px !important;
		border: 1px solid #19ECFF;
		border-radius: 0;
		background-color: transparent !important;
	}

	/deep/ .ivu-date-picker .ivu-select-dropdown {
		left: 2px !important;
		top: 87% !important;
	}

	/deep/ .industry .ivu-select-dropdown {
		min-width: 360px !important;
		left: -61px !important;
		top: 90% !important;
	}

	/deep/ .ivu-input {
		height: 35px !important;
		border-radius: 0 !important;
		background-color: transparent !important;
		border: none;
		color: #fff;
		font-size: 14px;
		padding-left: 19px;
		padding-top: 4px !important;
	}

	/deep/ .ivu-input::placeholder {
		color: rgba(255, 255, 255, .9);
	}

	/deep/ .ivu-input-suffix i {
		color: #19ECFF;
		font-size: 20px !important;
		line-height: 35px !important;
		position: relative;
		right: 0;
		top: -2px;
	}

	/deep/ .ivu-select-placeholder {
		height: 35px !important;
		line-height: 35px !important;
		font-size: 14px !important;
		color: #fff !important;
	}

	/deep/ .ivu-select-selected-value {
		height: 35px !important;
		line-height: 35px !important;
		font-size: 14px !important;
	}



	/deep/ .ivu-icon-ios-arrow-down:before {
		color: #19ECFF;
	}

	/deep/ .ivu-select-item {
		font-size: 14px !important;
		white-space: normal;
		line-height: 24px;
		padding: 5px 16px;
	}

	/deep/ .ivu-date-picker-header .ivu-icon {
		color: #c5c8ce !important;
	}
</style>