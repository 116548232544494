<template>
	<div :id="id"></div>
</template>

<script>
	import * as Highcharts from 'highcharts'
	import * as Exporting from 'highcharts/modules/exporting'
	import Highcharts3D from 'highcharts/highcharts-3d'
	Exporting(Highcharts)
	Highcharts3D(Highcharts)

	export default {
		props: [
			'chartData', 'id'
		],
		data() {
			return {
				data: [],
				initColor: ['#0003FF', '#7437FF', '#1459F4', '#0E44D1', '#4D4FF8', '#00A4FC', '#4081FE', '#7172FB', '#69A0FD',
					'#37DBFF', '#3FC6FD', '#69EFFF', '#B9D8FF', '#86FBFF', '#A0A1FD', '#93BFFE', '#98EEFE', '#AFFFFB',
					'#CFD0FE', '#65DCFE'
				],


			}
		},
		mounted() {


			if (this.chartData.length != 1) {
				this.chartData.data.map((el, i) => {
					this.data.push({
						name: el.name,
						data: el.value,
						color: this.generateColors(this.chartData.data.length, this.initColor)[i],
						stack: 'male'
					})

				})

				// console.log('预测表HighCharts：', this.data, this.chartData.year)

				this.init()
			}

		},
		methods: {
			init() {
				// k：千（kilo），表示 1,000
				// M 或 m：百万（mega），表示 1,000,000
				// G：十亿（giga），表示 1,000,000,000
				// T：万亿（tera），表示 1,000,000,000,000
				// P：千万亿（peta），表示 1,000,000,000,000,000
				// E：百万亿亿（exa），表示 1,000,000,000,000,000,000

				Highcharts.chart(this.id, {
					chart: {
						type: 'column',
						options3d: {
							enabled: true,
							alpha: 10,
							beta: 20,
							viewDistance: 280,
							depth: 240
						},
						marginTop: 50,
						marginLeft: 130,
						marginBottom: 60,
						backgroundColor: 'transparent'
					},
					credits: {
						enabled: false
					},
					exporting: {
						enabled: false
					},
					title: {
						text: ''
					},
					legend: {
						enabled: false, // fasle  关闭图例
						layout: 'horizontal', //horizontal 水平排列   //vertical 垂直
						// align: 'right',
						// verticalAlign: 'middle',
						symbolPadding: 10, // 控制图例标记与文本之间的距离
						itemMarginTop: 5, // 控制图例顶部的距离
						itemMarginBottom: 5 // 控制图例底部的距离
					},
					xAxis: {
						categories: this.chartData.year,
						labels: {
							style: {
								color: '#fff',
								fontSize: 14
							}
						}
					},
					yAxis: {
						allowDecimals: false,
						// min: 0,
						title: {
							align: 'high',
							offset: 0,
							text: '万元',
							rotation: 0,
							y: -40,
							style:{
								fontSize:16,
								color: '#282828',
							}
						},
						labels: {
							format: '{value}', // 直接显示数值并加上 "¥" 符号  
							style: {
								fontSize: 14,
								color: '#282828',
							}
						}
					},
					tooltip: {
						enabled: false,
						headerFormat: '<b>{point.key}</b><br>',
						pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
					},
					plotOptions: {
						column: {
							stacking: 'normal',
							depth: 40,
							maxPointWidth: 40
						}
					},
					series: this.data
				})

			},
			generateColors(dataCount, initColor) {
				let colors = []; // 初始化一个空数组来存储颜色  

				// 循环添加颜色，直到达到所需的数据量  
				for (let i = 0; i < dataCount; i++) {
					// 使用模运算符来确保索引在initColor数组的长度范围内  
					let index = i % initColor.length;
					colors.push(initColor[index]);
				}

				// 返回处理后的颜色数组  
				return colors;
			}


		}


	}
</script>

<style>
</style>