<template>
	<div :id="id"></div>
</template>

<script>
	import * as echarts from 'echarts'

	export default ({
		props: [
			'chartData', 'id'
		],
		data() {
			return {
				myChart: null,
				dynamicMax: 0,
				grid: {
					top: "10%",
					left: "2%",
					bottom: "5%",
					right: "5%",
					containLabel: true
				}
			}
		},

		mounted() {
			var this_ = this;
			if (this.chartData) {


				// setTimeout(() => {
				this_.initChart()
				// }, 10);

			}

		},
		methods: {
			initChart() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id)
			},
			setOptions(id) {
				var this_ = this;
				var yData = this.chartData.data
				var barWidth = 50;
				var option = {
					grid: this_.grid,
					animation: false,
					xAxis: [{
						type: "category",
						data: this.chartData.year,
						axisTick: {
							alignWithLabel: true
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1416F4"
							}
						},
						axisLabel: {
							textStyle: {
								color: "#fff"
							},
							margin: 20
						}
					}],
					yAxis: {
						type: "value",
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1416F4"
							}
						},
						axisLabel: {
							textStyle: {
								color: "#282828"
							},
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
						name: "数据上椭圆",
						type: 'pictorialBar',
						symbolSize: [barWidth, 13],
						symbolOffset: [0, -6],
						symbolPosition: 'end',
						z: 12,
						label: {
							show: true,
							position: "top",
							fontSize: 14,
							color: '#1416F4',
							formatter: function(params, index) {
								return this_.method.formateNum(params.value, 2);
							}
						},
						color: "#6768FF",
						data: yData
					}, {
						name: '下椭圆',
						type: 'pictorialBar',
						symbolSize: [barWidth, 13],
						symbolOffset: [0, 6],
						z: 12,
						color: '#1416F4',
						data: yData
					}, {
						type: 'bar',
						showBackground: true,
						backgroundStyle: {
							color: '#E3E4FF', // 设置背景图片
						},
						barWidth: barWidth,
						barGap: '10%', // Make series be overlap
						barCateGoryGap: '10%',
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
								offset: 0,
								color: "#9397FF"
							}, {
								offset: 1,
								color: "#3638F6"
							}]),
						},
						data: yData
					}, ]
				};

				this.myChart = echarts.getInstanceByDom(document.getElementById(id));
				if (this.myChart == undefined) {
					this.myChart = echarts.init(document.getElementById(id));
				}
				// 使用刚指定的配置项和数据显示图表。
				this.myChart.setOption(option);

				// 计算一个比最大值稍大一点的值，可以使用一些系数来控制
				this.dynamicMax = this_.myChart.getModel().getComponent("yAxis", 0).axis.scale._extent[1] + this_.myChart
					.getModel().getComponent("yAxis", 0).axis.scale._interval; // 1 是一个系数，你可以根据需要调整


				setTimeout(() => {
					this_.columnar(this.id)
				}, 100);

			},
			columnar(id) {
				var this_ = this;
				var yData = this.chartData.data
				var barWidth = 50;
				var option = {
					grid: this_.grid,
					animation: false,
					xAxis: [{
						type: "category",
						data: this.chartData.year,
						axisTick: {
							alignWithLabel: true
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#1416F4"
							}
						},
						axisLabel: {
							textStyle: {
								color: "#fff"
							},
							margin: 20
						}
					}],
					yAxis: {
						type: "value",
						name: '万元',
						nameTextStyle: {
							color: '#282828' ,// Y轴名称颜色
							padding: 20
						},
						max: this.dynamicMax,
						axisLine: {
							show: true, //轴线
							lineStyle: {
								color: "#1416F4"
							}
						},
						axisLabel: {
							textStyle: {
								color: "#282828"
							},
							margin: 10
						},
						splitLine: {
							show: false //网格线
						}
					},
					series: [{
						name: '',
						type: 'pictorialBar',
						symbolSize: [barWidth, 13],
						z: 12,
						color: '#BCBEFF',
						data: this_.symbolPosition(yData)
					}, {
						name: "数据上椭圆",
						type: 'pictorialBar',
						symbolSize: [barWidth, 13],
						symbolOffset: [0, -6],
						symbolPosition: 'end',
						z: 12,
						label: {
							show: true,
							position: "top",
							fontSize: 14,
							color: '#1416F4',
							formatter: function(params, index) {
								return this_.method.formateNum(params.value, 2);
							}
						},
						color: "#6768FF",
						data: yData
					}, {
						name: '下椭圆',
						type: 'pictorialBar',
						symbolSize: [barWidth, 13],
						symbolOffset: [0, 6],
						z: 12,
						color: '#1416F4',
						data: yData
					}, {
						type: 'bar',
						showBackground: true,
						backgroundStyle: {
							color: '#E3E4FF', // 设置背景图片
						},
						barWidth: barWidth,
						barGap: '10%', // Make series be overlap
						barCateGoryGap: '10%',
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
								offset: 0,
								color: "#9397FF"
							}, {
								offset: 1,
								color: "#3638F6"
							}]),
						},
						data: yData
					}, ]
				};


				// 使用刚指定的配置项和数据显示图表。
				this.myChart.setOption(option, true);
			},
			symbolPosition() {
				var this_ = this;
				var arr = [];

				this.chartData.data.forEach(function(ele, i) {
					arr[i] = {
						// value: this_.myChart.getModel().getComponent("yAxis", 0).axis.scale._extent[1],
						value: this_.dynamicMax,
						symbolOffset: [0, -6],
						symbolPosition: 'end'
					}
				});
				return arr
			}

		}

	})
</script>

<style>
</style>