<template>
	<div :style="{'width':'calc(18vh + 120px)'}">
		<div class="sidebar" :style="{'height':height-method.ratioEquality(1854,90,width-100)-100+'px'}">
			<img class="arc" :src="require('@/assets/img/dataValutaion/arc.png')" />
			<ul>
				<li v-for="(item,index) in list" :key="index" :class="['navItem', slideIndex === index?'active':'']"
					@click="switchNav(index,item.url)">
					<img :src="slideIndex === index?item.selectBackground:item.background" />

					<div>
						<img class="icon"
							:src="slideIndex === index?item.selectIcon:item.isComplete?require('@/assets/img/dataValutaion/accomplish.png'):item.icon" />
						<p>{{item.name}}</p>
					</div>
					<img v-if="slideIndex === index" class="arrows" :src="require('@/assets/img/dataValutaion/arrows.png')" />
				</li>
			</ul>

			<Modal v-model="myAlert" class-name="vertical-center-data" :mask-closable="false" @on-ok="submitDialog">
				<img class='warning' :src="require('@/assets/img/fillin/unsave.png')">
				<p>当前数据尚未保存，是否确定离开？</p>
			</Modal>

		</div>
	</div>

</template>

<script>
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';


	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		mixins: [common],
		props: {
			currentColumn: {
				type: Number
			},
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},

		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				slideIndex: this.currentColumn,
				myAlert: false,
				skip: 0,
				list: [{
					name: '基本信息',
					icon: require('@/assets/img/dataValutaion/baseIcon.png'),
					selectIcon: require('@/assets/img/dataValutaion/selectedBaseIcon.png'),
					background: require('@/assets/img/dataValutaion/base.png'),
					selectBackground: require('@/assets/img/dataValutaion/selectedBase.png'),
					url: this.type == 13 ? 'dataInfoSpecialty' : 'dataValuationInfoFast',
					isComplete: false
				}, {
					name: '软性指标',
					icon: require('@/assets/img/dataValutaion/softIcon.png'),
					selectIcon: require('@/assets/img/dataValutaion/selectedSoftIcon.png'),
					background: require('@/assets/img/dataValutaion/soft.png'),
					selectBackground: require('@/assets/img/dataValutaion/selectedSoft.png'),
					url: this.type == 13 ? 'dataSoftSpecialty' : 'dataValuationSoftFast',
					isComplete: false
				}, {
					name: '财务指标',
					icon: require('@/assets/img/dataValutaion/financeIcon.png'),
					selectIcon: require('@/assets/img/dataValutaion/selectedFinanceIcon.png'),
					background: require('@/assets/img/dataValutaion/finance.png'),
					selectBackground: require('@/assets/img/dataValutaion/selectedFinance.png'),
					url: this.type == 13 ? 'dataLiabilitiesSpecialty' : 'dataValuationLiabilitiesFast',
					isComplete: false
				}, {
					name: this.type == 13 ? '贡献程度' : '数据资源',
					icon: require('@/assets/img/dataValutaion/contributionIcon.png'),
					selectIcon: require('@/assets/img/dataValutaion/selectedContributionIcon.png'),
					background: require('@/assets/img/dataValutaion/contribution.png'),
					selectBackground: require('@/assets/img/dataValutaion/selectedContribution.png'),
					url: this.type == 13 ? 'dataIASpecialty' : 'dataResourceFast',
					isComplete: false
				}, {
					name: '估值报告',
					icon: require('@/assets/img/dataValutaion/reportIcon.png'),
					selectIcon: require('@/assets/img/dataValutaion/selectedReportIcon.png'),
					background: require('@/assets/img/dataValutaion/reportSilde.png'),
					selectBackground: require('@/assets/img/dataValutaion/selectedReportSilde.png'),
					url: this.type == 13 ? 'dataPaymentSpecialty' : 'dataValuationPaymentFast',
					isComplete: false
				}],


			}
		},
		mounted() {

			console.log(this.height)

			this.completionStatus()

		},
		computed: {
			...mapState(['currentPageModify', 'currentPageSave']),
		},
		methods: {
			...mapMutations(['pageModify', 'setCheckStatus']),
			switchNav(i, url) {
				console.log('currentPageModify--------', this.currentPageModify, this.currentPageSave)
				if (this.currentPageModify == 'true' && this.currentPageSave == false && i != this.slideIndex) {
					this.myAlert = true;
					this.skip = i;
				} else {
					this.slideIndex = i;
					if (this.$route.query.reportId) {
						this.$router.push({
							path: this.list[i].url,
							query: {
								reportId: this.$route.query.reportId
							}
						})
					} else {
						this.$router.push({
							path: this.list[i].url,
						})
					}
				}

			},
			submitDialog() {
				this.myAlert = false;
				if (this.$route.query.reportId) {
					this.$router.push({
						path: this.list[this.skip].url,
						query: {
							reportId: this.$route.query.reportId
						}
					})
				} else {
					this.$router.push({
						path: this.list[this.skip].url,
					})
				}
			},
			completionStatus() { //purpose 1：预支付 0：查看数据填写情况
				this.$post(this.domain.valuationProject + 'pay/getToPayDataReportInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						purpose: '0'
					}))
					.then((res) => {
						console.log('成功-----', res.data)
						if (res.data.content.check) {
							var check = JSON.parse(res.data.content.check)
							console.log(check)
							this.setCheckStatus(check)
							this.list[0].isComplete = check.baseInfoFlag
							this.list[1].isComplete = check.questionInfoFlag
							if ((this.type == 13 && check.liabilitiesInfoFlag && check.profitInfoFlag && check.dataResourceFlag &&
									check.incomeFlag && check.netProfitFlag) || (this.type == 15 && check.liabilitiesInfoFlag && check
									.profitInfoFlag)) {
								this.list[2].isComplete = true
							}


							if ((this.type == 13 && check.iAContribution && check.dataContribution) || (this.type == 15 && check
									.dataResourceFlag && check.dataContribution)) this.list[3].isComplete = true
							if (res.data.content.auditStatus === '0') this.list[4].isComplete = true

						} else {
							this.setCheckStatus('')
						}
					})
			}


		},



	}
</script>

<style scoped lang="less">
	.sidebar {
		width: calc(18vh + 120px);
		min-height: 496px;
		position: fixed;


		.arc {
			height: 100%;
			position: absolute;
			top: 0;
			right: 12px;
		}

		ul {
			width: 100%;
			height: 100%;


			li {
				width: 94%;
				position: relative;
				cursor: pointer;
				margin-top: 3vh;

				.arrows {
					width: 30px;
					position: absolute;
					right: -33px;
					top: 61%;
					margin-top: -30px;
				}

				>div {
					width: 100%;
					display: flex;
					align-items: center;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					padding-left: 20%;

					>p {
						color: #fff;
						font-weight: bold;
						font-size: 20px;
						display: inline-block;
					}

				}

				>img:not(.arrows) {
					width: calc(100% - 28px);
					height: 9vh;
				}

				.icon {
					width: 3vh;
					margin-right: 15px;
				}

				.circle {
					height: 18vh;
					object-fit: contain;
				}

			}

			li:first-child {
				margin-top: 8vh;

				>img:not(.arrows) {
					width: 85%;
				}

				.arrows {
					right: -20px;
				}
			}

			li:last-child>img:not(.arrows) {
				width: 83%;
			}

			li:first-child.active>img:not(.arrows) {
				width: 98%;
			}

			li:last-child.active>img:not(.arrows) {
				width: 97%;
			}

			li:nth-child(3).active>img:not(.arrows),
			li:nth-child(4).active>img:not(.arrows) {
				width: 101%;
			}


			li:last-child.active>.arrows {
				right: -20px;
				top: 66%;
			}

			li.active {
				>img:not(.arrows) {
					width: 100%;
				}

				>div {
					padding-left: 30%;
				}


				p {
					color: #FECD09;
					text-shadow: 1px 1px 0px #050594;
				}
			}

		}

	}

	@media screen and (max-height:650px) {}
</style>
