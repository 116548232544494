<template>
	<div class="header" id="app">
		<div style="position: absolute;">
			<a class="home" :href="flowUrl" target="_blank">
				<div>
					<img src="../../assets/img/home/homeIcon.png" />
					<p>首页</p>
				</div>
			</a>
			<div class="center">
				<img class="side" src="../../assets/img/home/headerCenterLeft.png" />
				<img class="headerCenter" src="../../assets/img/home/headerCenter.png" :style="{'top':top+'%'}" />
				<img class="logoDV" src="../../assets/img/home/DVlogo.svg" :style="{'top':(width > 1600?24:top+14)+'%'}" @click="goHome"/>
				<img class="side" src="../../assets/img/home/headerCenterRight.png" />
			</div>
			<a class="login">
				<div>
					<img src="../../assets/img/home/loginIcon.png" />
					<p @click="login">{{logoShow?'登录':username}}</p>
					<ul :class="logoShow?'':'account-sel'">
						<li><a :href="[flowUrl+'pages/myValuation.html?type=13']">我的报告</a></li>
						<li><router-link tag="a" to="/login">退出</router-link></li>
					</ul>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
	import '../../assets/css/myAlert.css';
	export default {
		data() {
			return {
				width: 0,
				logoShow: true,
				username: localStorage.userName,
				flowUrl: ''
			}
		},
		computed: {
			top() {
				var top = this.width > 1450 && this.width < 1600 ? 13 : this.width > 1360 && this.width <= 1450 ? 16 : this
					.width <= 1360 ? 19 : 5
				return top
			}
		},
		mounted() {
			this.flowUrl = this.domain.testUrl

			this.width = window.innerWidth
			window.addEventListener('resize', () => {
				this.width = window.innerWidth
			})

			this.isLogin()
		},
		methods: {
			isLogin() {
				if (!localStorage.userName || !localStorage.userToken) {
					this.logoShow = true
				} else {
					this.logoShow = false
				}
			},
			login() {
				if (this.logoShow) {
					this.$router.push({
						name: 'login',
						path: '/login'
					})
				}
			},
			goHome(){
				this.$router.push({
					name: 'Home',
					path: '/'
				})
			}
		}

	}
</script>

<style scoped lang="less">
	.header {
		width: 100%;
		position: absolute;
	}

	.header>div {
		width: 96%;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		position: relative;
		left: 50%;
		transform: translate(-50%,-10px);
		z-index: 2;

		.center {
			width: 76%;
			display: flex;
			justify-content: space-between;
			position: relative;

			img {
				object-fit: contain;
			}

			.headerCenter {
				width: 55%;
				position: absolute;
				left: 22.5%;
			}

			.logoDV {
				width: 12%;
				position: absolute;
				left: 43.2%;
				cursor: pointer;

			}

			.side {
				width: 40%;
				height: 60px;
				object-fit: contain;
				transform: translateY(40px);
			}

		}

		.home,
		.login {
			width: 140px;
			height: 139px;
			background: url(../../assets/img/home/homeBackground.png) no-repeat;
			background-size: cover;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 9999;
			transform: scale(0.85);
		}

		.home>div,
		.login>div {
			text-align: center;
		}

		.home p,
		.login p {
			width: 38px;
			color: #19ECFF;
			font-size: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
			text-align: center;
		}

		.login ul {
			width: 120px;
			text-align: center;
			border-top: 0;
			background: #fff;
			border-radius: 5px;
			padding: 6px 0;
			display: none;
			position: absolute;
			top: 80%;
			left: 40px;
			z-index: 999;

			li a {
				width: 100%;
				font-size: 14px;
				line-height: 30px;
				cursor: pointer;
				text-align: left;
				margin-left: 16px;
				box-sizing: content-box;
				color: #1459F4;
			}
			li a:hover{
				color:#002d93;
			}
		}

		.login:hover .account-sel {
			display: block;
		}

	}
</style>