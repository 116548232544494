import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
	post,
	get
} from './utils/http.js'
import domain from './assets/js/domain.js'
import method from './assets/js/method.js'
import iView from 'iview';
import 'iview/dist/styles/iview.css'; // 使用 CSS

import '@/assets/fonts/font.css'

Vue.prototype.method = method
Vue.prototype.domain=domain


//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;


Vue.config.productionTip = false

Vue.use(iView)

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	if (to.meta.content) {
		let head = document.getElementsByTagName('head');
		let meta = document.createElement('meta');
		document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
		document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
		meta.content = to.meta.content;
		// console.log(meta,to.meta.content)
		head[0].appendChild(meta)
	}
	next()
})

router.afterEach((to, from, next) => { //控制路由跳转时滚动条在顶部
	window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
