<template>
	<div>
		<ul class="valuationTopNav specialty" v-if="type==13">
			<li v-for="(item,index) in list" :class="[slideIndex === index?'active':'']">
				<p class="children unfold" @click="secondLevel(index,item.url)" v-if="item.name=='财务指标'">{{item.name}}</p>
				<p @click="switchNav(index,item.url)" v-else>{{item.name}}</p>


				<!-- 使用 transition 组件包裹需要过渡的元素 -->
				<transition name="slide-fade">
					<ul class="second second-menu" v-if="item.children&&visible">
						<li v-for="(child,c) in item.children" :class="[childrenIndex === c?'financeActive':'']"
							@click="selectiveFinancial(c,child.url)">{{child.name}}</li>
					</ul>
				</transition>

			<!-- 	<ul class="second" v-if="item.children">
					<li v-for="(child,c) in item.children" :class="[childrenIndex === c?'financeActive':'']"
						@click="selectiveFinancial(c,child.url)">{{child.name}}</li>
				</ul> -->

			</li>
		</ul>
		<ul class="valuationTopNav" v-else>
			<li v-for="(item,index) in list" :class="[slideIndex === index?'active':'']">
				<p @click="switchNav(index,item.url)">{{item.name}}</p>
			</li>
		</ul>
	</div>
</template>

<script>
	import '@/assets/css/animate.css'

	export default {
		props: {
			type: {
				type: [String, Number],
				required: false
			},
			financeIndex: {
				type: [String, Number],
				required: false
			}
		},
		data() {
			return {
				height: window.innerHeight,
				slideIndex: 0,
				childrenIndex: this.financeIndex,
				fast: [{
					name: '基本信息',
					url: '/dataValuationEInfoFast'
				}, {
					name: '软性指标',
					url: '/dataValuationESoftFast'
				}, {
					name: '财务指标',
					url: '/dataValuationEFinancialFast'
				}, {
					name: '数据资源',
					url: '/dataValuationEContributionFast'
				}],
				specialty: [{
					name: '基本信息',
					url: '/memberbasicTitle'
				}, {
					name: '软性指标',
					url: '/viewSoftIndicators'
				}, {
					name: '财务指标',
					url: '',
					children: [{
						name: '资产负债表',
						url: 'examineAssetLiability'
					}, {
						name: '利润表',
						url: 'examineProfit'
					}, {
						name: '数据资产',
						url: 'examineDataAsset'
					}, {
						name: '收入预测',
						url: 'examineIncomeForecast'
					}, {
						name: '净利润预测',
						url: 'examineNetProfitForecast'
					}, {
						name: ' 会计政策统计表',
						url: 'examineAccountingPolicy'
					}]
				}, {
					name: '贡献程度',
					url: '/viewContributionLevel'
				}],
				list: [],
				visible: true


			}
		},
		mounted() {

			if (this.type == 13) {
				this.list = this.specialty
			} else {
				this.list = this.fast
			}

			console.log(this.financeIndex)

			if (this.$route.query.auditStatus != '1') {
				if (this.type == 13) {
					this.list.unshift({
						name: '审核状态',
						url: '/dataAuditSpecialty'
					})
				} else {
					this.list.unshift({
						name: '审核状态',
						url: '/dataValuationAuditFast'
					})
				}
				if (this.financeIndex != undefined) {
					this.slideIndex = 3
				}
			} else {
				if (this.financeIndex != undefined) {
					this.slideIndex = 2
				}
			}

			this.getCurrentPage()

		},
		methods: {
			switchNav(i, url) {
				this.slideIndex = i
				this.$router.push({
					path: url,
					query: {
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
						auditStatus: this.$route.query.auditStatus
					}
				})
			},
			secondLevel(i) { //财务二级菜单
				// this.slideIndex = i
				this.visible = !this.visible
				// $('.second').slideToggle();


				if (this.visible) {
					$('.children').addClass('unfold')
				} else {
					$('.children').removeClass('unfold')
				}



			},
			selectiveFinancial(i, url) {
				this.childrenIndex = i
				this.$router.push({
					path: url,
					query: {
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
						auditStatus: this.$route.query.auditStatus
					}
				})
			},
			getCurrentPage() {
				for (var i = 0; i < this.list.length; i++) {
					if (this.$route.path == this.list[i].url) {
						this.slideIndex = i
						break
					}
				}
			}


		}

	}
</script>

<style scoped lang="less">
	// 二级菜单动画
	.second-menu {  
	  /* 初始时隐藏菜单 */  
	  transform-origin: top center;  
	  transition: transform 0.3s ease-in-out;  
	  /* 你可以根据需要调整 transform: scaleY(0) 的值 */  
	  transform: scaleY(1);  
	  max-width:140px;  
	  overflow: hidden;  
	}  
	  
	.slide-fade-enter-active, .slide-fade-leave-active {  
	  transition: transform 0.3s ease-in-out;  
	}  
	.slide-fade-enter, .slide-fade-leave-to {  
	  /* 进入时和离开后的状态，菜单被隐藏 */  
	  transform: scaleY(0);  
	}  
	.slide-fade-enter-to, .slide-fade-leave {  
	  /* 离开后和进入完成时的状态，菜单被显示 */  
	  transform: scaleY(1);  
	}  




	.specialty {
		>li {
			margin-bottom: 16px !important;
		}

		.second {
			padding: 10px 15px 0 0;
			margin-left: 28px;
			// display: none;

			>li {
				height: 28px;
				padding-left: 17px;
				margin-bottom: 10px;
				font-size: 14px;
				color: #fff;
				cursor: pointer;
			}

			.financeActive {
				background: url(../../../assets/img/examine/titleBG.png) center center no-repeat;
				background-size: 100% 100%;
				text-shadow: 0px 1px 9px #29FFFC;
				color: #29FFFC;
			}

		}
	}

	.specialty::-webkit-scrollbar {
		width: 6px;
		padding: 2px;
		background: #1237c6;
	}

	.specialty::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: #30ECEE;
	}


	.valuationTopNav {
		position: fixed;
		padding-bottom: 10px;

		>li:last-child {
			margin-bottom: 0;
		}

		>li {
			margin-bottom: 36px;

			p {
				width: 127px;
				height: 33px;
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				color: #fff;
				cursor: pointer;
				position: relative;
			}

			p::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -9px;
			}

			p:not(.unfold)::after {
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid #fff;
			}

			.unfold::after {
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid #fff;
			}

		}

		>li.active p {
			text-shadow: 0px 1px 9px #29FFFC;
			color: #29FFFC;
		}

		>li.active p:not(.children) {
			background: url(../../../assets/img/examine/titleBG.png) center center no-repeat;
		}

		>li.active p:not(.unfold)::after {
			border-left: 5px solid #29FFFC;
		}


		>li.active p.unfold::after {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #29FFFC;
		}

	}

	@media screen and (max-height:680px) {
		.specialty {
			height: 59%;
			overflow-y: scroll;
		}

	}
</style>