<template>
	<div class="softNav">
		<ul>
			<li v-for="(item,index) in list" :key="index" @click="softIndicator(index)">
				<div :class="[slideIndex === index?'softActive':'']">
					<img v-if="slideIndex === index" :src="item.selectedIcon" />
					<img v-else :src="item.isAccomplish?item.accomplishIcon:item.icon" />
					<p :style="{'color':slideIndex === index?'#E2B420':(item.isAccomplish?'#0FEE5F':'#fff')}">{{item.contentName}}
					</p>
				</div>
				<img v-if="index!=list.length-1" src="../../assets/img/softIndex/arrows.png" />
			</li>
		</ul>
		<img src="../../assets/img/softIndex/softPedestal.png" />


		<Modal v-model="myAlert" class-name="vertical-center-modal" :mask-closable="false" @on-ok="submitDialog">
			<img class='warning' src="../../assets/img/fillin/unsave.png">
			<p>当前数据尚未保存，是否确定离开？</p>
			<!-- <div slot="footer" class="ivu-sure" @click="submitDialog">确定</div> -->
		</Modal>
	</div>
</template>

<script>
	import dialogBar from '@/components/filled/popue.vue'
	import qs from 'qs'
	import axios from 'axios'
	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		components: {
			dialogBar
		},
		props: ['getSoftIndex'],
		data() {
			return {
				slideIndex: 0,
				skip: 0,
				myAlert: false,
				markedWords: '当前数据尚未保存，是否确定离开？',
				list: [{
					contentName: '基础指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/infoBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/infoOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/infoGreen.png')
				}, {
					contentName: '风险指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/riskBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/riskOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/riskGreen.png')
				}, {
					contentName: '质量指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/qualityBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/qualityOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/qualityGreen.png')
				}, {
					contentName: '经济指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/economicsBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/economicsOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/economicsGreen.png')
				}, {
					contentName: '流通指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/circulateBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/circulateOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/circulateGreen.png')
				}, {
					contentName: '成本指标',
					isAccomplish: false,
					icon: require('@/assets/img/softIndex/costBlue.png'),
					selectedIcon: require('@/assets/img/softIndex/costOrange.png'),
					accomplishIcon: require('@/assets/img/softIndex/costGreen.png')
				}]
			}
		},
		mounted() {
			this.getQuestion()

		},
		computed: {
			...mapState(['currentPageModify', 'currentPageSave']),
		},
		methods: {
			updateChildData(updatedData) {
				var this_ = this
				console.log('子组件接收到修改后的数据：', updatedData);

				this.list.map((el, i) => {
					if (updatedData.index == el.contentType) {
						this.$set(this.list[i], 'contentDetails', updatedData.softIndexProblem)
						if (updatedData.nextModule) {
							this.slideIndex = i + 1
							this.skip = i + 1
							this.$emit('softIndexSelected', JSON.stringify(this.list[this.skip])) // 触发自定义事件并传递数据
						} else {
							this.slideIndex = i
							this.skip = i
						}
					}
				})

				setTimeout(function() {
					this_.getQuestionFlag(1)
				}, 100)


			},
			softIndicator(i) {
				this.skip = i;
				if (this.currentPageModify == 'true' && this.currentPageSave == false) {
					this.myAlert = true;
				} else {
					this.slideIndex = i
					this.$emit('softIndexSelected', JSON.stringify(this.list[i])) // 触发自定义事件并传递数据
					$('.fillinMain').scrollTop(0)
				}

			},
			submitDialog() {
				this.myAlert = false;
				this.slideIndex = this.skip
				this.$emit('softIndexSelected', JSON.stringify(this.list[this.skip])) // 触发自定义事件并传递数据
			},
			getQuestion(isSend) {
				this.$Spin.show(); //全局加载中
				var url = this.domain.valuationProject + 'question/getQuestion';
				this.$post(url, qs.stringify({
						stage: sessionStorage.stage,
						reportType: this.$route.query.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: sessionStorage.versions
					}))
					.then((res) => {
						this.$Spin.hide();

						res.data.content.map((el, i) => {
							this.list[i]['contentDetails'] = el.contentDetails
							this.list[i]['contentType'] = el.contentType

							el.contentDetails.map((els, j) => {
								els['id'] = els.options[0].questionId
							})

						})

						console.log(this.list)
						this.getQuestionFlag(isSend)
					})
			},
			getQuestionFlag(isSend) {
				var url = this.domain.valuationProject + 'question/getQuestionFlag';
				this.$post(url, qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						reportType:this.$route.query.type
					}))
					.then((res) => {
						this.list[0].isAccomplish = res.data.content.questionBasicFlag
						this.list[1].isAccomplish = res.data.content.questionRiskDataFlag
						this.list[2].isAccomplish = res.data.content.questionQualityFlag
						this.list[3].isAccomplish = res.data.content.questionEconomyFlag
						this.list[4].isAccomplish = res.data.content.questionCirculateFlag
						this.list[5].isAccomplish = res.data.content.questionCostFlag

						if (isSend != 1) this.$emit('softIndexSelected', JSON.stringify(this.list[0])) // 触发自定义事件并传递数据

					})
			}

		}
	}
</script>

<style scoped lang="less">
	.softNav {
		width: 46%;
		// background-color: pink;
		margin: 0 auto;
		position: relative;

		>img {
			position: absolute;
			bottom: -36px;
		}

		>ul {
			width: 88%;
			margin: 20px auto 0;
			display: flex;
			justify-content: space-between;

			li {
				// width: 16.67%;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				>img {
					width: 24px;
					height: 29px;
					margin-left: 3px;
				}

				div {
					position: relative;
					z-index: 2;

					img {
						width: 100px;
						height: 91px;
					}

					p {
						width: 100%;
						text-align: center;
						font-size: 18px;
						font-weight: bold;
						color: #fff;
					}
				}

			}

		}

	}

	@media screen and (max-width:1500px) and (max-height:700px) {
		.softNav>ul li div p {
			font-size: 20px;
		}
	}
</style>